import { AppBar, Box, Chip, Container, Divider, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, makeStyles, Menu, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import * as yup from 'yup';
// import { addFactura } from '../../../services/inquilino';
import red from '@material-ui/core/colors/red';
import LoadingComponent from '../../../components/Loading';
import { DudaService } from '../../../services/Duda.service';
import ContenidoDetalle from './ContenidoDetalle';
import SidebarDetalle from './SidebarDetalle';
import { EntregaService } from '../../../services/Entrega.service';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 90
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    keyboardDatePicker: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    formulario: {
        margin: 8
    },
    input: {
        display: 'none'
    },
    chipCancelado: {
        backgroundColor: red[100]
    }
}));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */
// const validationSchema = yup.object({

//     nombre: yup.string().required("Nombre es requerido"),
//     // correo_electronico: yup.string().email().required("Correo es requerido"),
//     comercial_id: yup.number().required("Comercial es requerido"),
//     temperatura_id: yup.number().required("Temperatura es requerido"),
//     // estado_id: yup.number().required("Temperatura es requerido"),
//     como_conocido_id: yup.number().required("Como nos ha conocido es requerido"),
//     comunidad_autonoma_id: yup.number().required("Comunidad es requerida"),
//     oposicion_id: yup.number().required("Oposicion es requerida"),
//     especialidad_id: yup.number().required("Especialidad es requerida"),
    
// });


const AlumnoConversacion = () => {
//     // const classes = useStyles();
    const {alumno_id, origen_tipo, origen_id } = useParams()

    // let history = useHistory();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("Cargando ficha del alumno")
    const [origenData, setOrigenData] = useState(undefined)
    const [conversaciones, setConversaciones] = useState(undefined)
    // const [tabSeleccionada, setTabSeleccionada] = React.useState(0);


    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
      
       fetchData()
    } ,[])

    const fetchData = () => {
      if(origen_tipo === 'Duda') {
        DudaService.item({usuario_id: alumno_id, duda_id: origen_id}).then(response => {
          setOrigenData(response.duda)
          setConversaciones(response.conversaciones)
        })
      }
      if(origen_tipo === 'Entrega' || origen_tipo === 'Simulacro') {
        EntregaService.item({usuario_id: alumno_id, origen_tipo: origen_tipo, origen_id: origen_id}).then(response => {
          console.log(response)
          setOrigenData(response.data)
          setConversaciones(response.conversaciones)
        })
      }
    }
    
    return (
      <>
        {loading === false ? (
          <TemplateDashboard showBack={true}>
            <Box sx={{ marginX: 2, marginTop: 0, marginBottom: 12, p: 1 }}>
              <Typography
                variant="h4"
                component="h3"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {origen_tipo} [{origen_id}]
              </Typography>
            </Box>

            <Container maxWidth="xl" sx={{ marginTop: 2 }}>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={9} style={{ paddingTop: 0 }}>
                  {origenData && (
                    <ContenidoDetalle
                      origenTipo={origen_tipo}
                      origenData={origenData}
                      conversaciones={conversaciones}
                      fetchData={fetchData}
                    />
                  )}
                </Grid>
                <Grid item xs={3} style={{ paddingTop: 0 }}>
                  {origenData && (
                    <SidebarDetalle
                      origenTipo={origen_tipo}
                      origenData={origenData}
                      fetchData={fetchData}
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </TemplateDashboard>
        ) : (
          <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
        )}
      </>
    );
};

export default AlumnoConversacion;