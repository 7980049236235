import { Box, Chip, Grid, IconButton, Link, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { amber, blue, green, red } from '@material-ui/core/colors';
import moment from 'moment';
import Moment from 'react-moment';
import StatusTicket from '../../../components/StatusTicket';
import { BASE_URL_ZENDESK, MODO_DEV } from '../../../constants';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { delete_alumno_entrega, forzar_envio, getAlumnosEntregas, importar_alumno_entregas_plantilla, ticketToConversaion } from '../../../services/alumnos';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { DialogEntregaForm } from './DialogEntregaForm';
import { DialogEntregaCambiarFechasForm } from './DialogEntregaCambiarFechasForm';

const AlumnoFormEntregas = ( { alumno_id, tipo_id } ) => {
    const [anchorElOpciones, setAnchorElOpciones] = React.useState(null);
    const open = Boolean(anchorElOpciones);
    const [entregas, setEntregas] = useState(undefined)
    const [entregaDoubleClick, setEntregaDoubleClick] = useState()
    const [loading, setLoading] = useState(false)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalUpdateFechas, setOpenModalUpdateFechas] = useState(false)

    useEffect(() => {
        
        setLoading(true);
        cargarEntregas()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alumno_id])
    
    const cargarEntregas = () => {
        const usuario_id = usuarioSeleccionado.id

            alumno_id && getAlumnosEntregas({alumno_id, usuario_id}).then( (response) => {

                if(tipo_id === 139) {
                    response.entregas && setEntregas(response.entregas)
                }
                if(tipo_id === 177) {
                    response.entregas_extraordinarias && setEntregas(response.entregas_extraordinarias)
                }
                setLoading(false)
            })
        
    }

    const handleClickOpciones = (event) => {
        setAnchorElOpciones(event.currentTarget);
    };
    
    const handleCloseOpciones = () => {
        setAnchorElOpciones(null);
    };

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const handleDoubleClick = (row) => {

        if( row.fecha_envio !== "0000-00-00 00:00:00" ) {
            Swal.fire('No se puede modificar', 'Esta entrega ya tiene un envio realizado', 'info')
            return null
        }
        if( row.plantilla_linea_id !== "0" ) {
            Swal.fire('No se puede modificar', 'Esta entrega pertenece a una plantilla', 'info')
            return null
        }

        setEntregaDoubleClick(row)
        setOpenModalCreate(true)
        
    }

    const handleGetTicketInfo = (origen_id, ticket_id) => {
        const usuario_id = usuarioSeleccionado.id
  
        alumno_id && ticketToConversaion({alumno_id: alumno_id, usuario_id: usuario_id, tipo: 'entregas', origen_id: origen_id, ticket_id: ticket_id}).then( (response) => {
            alumno_id && getAlumnosEntregas({alumno_id, usuario_id}).then( (response) => {

                if(tipo_id === 139) {
                    response.entregas && setEntregas(response.entregas)
                }
                if(tipo_id === 177) {
                    response.entregas_extraordinarias && setEntregas(response.entregas_extraordinarias)
                }
                setLoading(false)
            })
            Swal.fire("Información del ticket actualizada")
        })
      }

    const columnas_entregas = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'abreviatura', headerName: 'Abrev', minWidth: 150 },
        { field: 'descripcion', headerName: 'Descripcion', minWidth: 150, flex: 1 },
        { field: 'tipo_nombre', headerName: 'Tipo', minWidth: 150 },
        {
          'field': 'fecha_planificacion', 
          'width':150, 
          'headerName': 'Planificado en', 
          'description': 'Fecha de planificación',
          type: 'date',
          valueFormatter: params => {
            return moment(params?.value).format("DD/MM/YYYY hh:mm A")
          },
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
        },
        { 
            field: 'fecha_prevista', 
            headerName: 'Fecha prevista entrega',
            minWidth: 170,
            renderCell: (cellValues) => {
                return (
                
                    <>
                        {
                            cellValues.row.es_retrasada === '1' ?
                                <Chip size="small" style={{backgroundColor: amber[500], color: 'white', marginRight: 10}} label={<Moment format="DD/MM/YYYY">{cellValues.row.fecha_prevista}</Moment>} />
                            :
                            
                            <>
                            {
                                <Moment format="DD/MM/YYYY">
                                    {cellValues.value}
                                </Moment>
                            }
                            </>
                        }
                    </>
                
                )
            }
        },
        {
            'field': 'fecha_envio', 
            'width':200, 
            'headerName': 'Fecha envío', 
            'description': 'Fecha de envío',
            type: 'date',
            valueFormatter: params => {
              return moment(params?.value).format("DD/MM/YYYY hh:mm A")
            },
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY HH:mm:ss">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            } 
        },
        // { 
        //     field: 'enlace', 
        //     headerName: 'Enlace', 
        //     minWidth: 50,
        //     renderCell: (cellValues) => {
        //       return cellValues.row.adjunto ? (<a href={`https://gestion.innovatiio.com/api/uploads/entregas/${cellValues.row.adjunto}`} target="_blank">Ver</a>) : <></>
        //     } 
        // },
        {
            'field': 'fecha_resuelto', 
            'width':200, 
            'headerName': 'Resuelto en', 
            'description': 'Fecha de resolución',
            type: 'date',
            valueFormatter: params => {
              return moment(params?.value).format("DD/MM/YYYY hh:mm A")
            },
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY HH:mm:ss">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            } 
        },
        { 
            field: 'ticket_status', 
            headerName: 'Estado', 
            minWidth: 300,
            renderCell: (cellValues) => <StatusTicket tipo='Entrega' esRetrasado={cellValues.row.es_retrasada} ticketStatus={cellValues.row.ticket_status} />
        },
        {
            'field': 'ticket_id', 
            'width':200, 
            'headerName': 'Conversación', 
            'description': 'Fecha de resolución',
            type: 'date',
            valueFormatter: params => {
              return moment(params?.value).format("DD/MM/YYYY hh:mm A")
            },
            renderCell: (cellValues) => {
                // if(cellValues.row.ticket_id !== '0') {
                    return <>
                        {/* { !MODO_DEV && <><a target='_blank' rel='noreferrer' href={`${BASE_URL_ZENDESK}agent/tickets/${cellValues.row.ticket_id}`}>Abrir</a>&nbsp;</>} */}
                        {/* { MODO_DEV && <a href={`/alumnos_tutor/conversacion/${alumno_id}/Entrega/${cellValues.row.id}`}>Abrir</a> } */}
                        { <a href={`/alumnos_tutor/conversacion/${alumno_id}/Entrega/${cellValues.row.id}`}>Abrir</a> }
                        </>
                // } else {
                //     return <></>
                // }
            } 
        },
        {
            'field': 'ticket2_id', 
            hide: true,
            'width':200, 
            'headerName': '...', 
            'description': '...',
            type: 'date',
            valueFormatter: params => {
              return moment(params?.value).format("DD/MM/YYYY hh:mm A")
            },
            renderCell: (cellValues) => {
                if(cellValues.row.ticket_id !== '0') {
                    return (
                      <>
                          <>
                            <Link onClick={() => handleGetTicketInfo(cellValues.row.id, cellValues.row.ticket_id)}>[Obtener Ticket] </Link>&nbsp;&nbsp;
                            <Link target="_blank" href={`${BASE_URL_ZENDESK}agent/tickets/${cellValues.row.ticket_id}`}>
                            [Zendesk]
                          </Link>
                          </>
                      </>
                    );
                } else {
                    return <></>
                }
            } 
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                // icon={<EditIcon />}
                label="Cambiar fechas"
                onClick={ () => { 
                    handleCambiarFechas(params.row) }}
                // component={Link}
                // to={`alumnos/detalle/${params.id}`}
                showInMenu
              />,
              <GridActionsCellItem
                // icon={<EditIcon />}
                label="Forzar envío"
                onClick={ () => { 
                    if(usuarioSeleccionado.perfil_id === '12' || usuarioSeleccionado.perfil_id === '1') {
                        handleForzarEnvio(params.id) 
                    } else {
                        Swal.fire("Para forzar un envío ponte en contacto con administración")
                    }
                }}
                // component={Link}
                // to={`alumnos/detalle/${params.id}`}
                showInMenu
              />,
              <GridActionsCellItem
                // icon={<EditIcon />}
                label="Delete entrega"
                onClick={ () => { 
                    handleDeleteEntrega(params.row) }}
                // component={Link}
                // to={`alumnos/detalle/${params.id}`}
                showInMenu
              />,
            ],
          }
    ];

    /* -------------------------------------------------------------------------- */
    /*                              handleForzarEnvio                             */
    /* -------------------------------------------------------------------------- */
    const handleCambiarFechas = (row) => {
        setEntregaDoubleClick(row)
        setOpenModalUpdateFechas(true)
    }
    const handleForzarEnvio = (entrega_id) => {
        Swal.fire({
            title: '¿Realmente deseas forzar el envio de la entrega?',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                forzar_envio({usuario_id: usuarioSeleccionado.id, entrega_id: entrega_id}).then( (response) => {

                    getAlumnosEntregas({alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {
        
                        response.entregas && setEntregas(response.entregas)
                        
                    })
        
                })
        
            } else if (result.isDenied) {
            // //   Swal.fire('Changes are not saved', '', 'info')
            }
          })

    }
    
    const handleDeleteEntrega = (row) => {
        // No se puede eliminar una entrega si viene de una plantilla o si ya tiene un envio realizado
        if( row.fecha_envio !== "0000-00-00 00:00:00" ) {
            Swal.fire('No se puede eliminar', 'Esta entrega ya tiene un envio realizado', 'info')
            return null
        }
        if( row.plantilla_linea_id !== "0" ) {
            Swal.fire('No se puede eliminar', 'Esta entrega pertenece a una plantilla', 'info')
            return null
        }

        Swal.fire({
            title: '¿Realmente deseas eliminar la entrega?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                delete_alumno_entrega({usuario_id: usuarioSeleccionado.id, entrega_id: row.id}).then( (response) => {

                    getAlumnosEntregas({alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {
        
                        response.entregas && setEntregas(response.entregas)
                        
                    })
        
                })
        
            } else if (result.isDenied) {
            // //   Swal.fire('Changes are not saved', '', 'info')
            }
          })

    }
    const handleImportarEntregasGrupo = () => {
        Swal.fire({
            title: '¿Realmente deseas importar las entregas del grupo?',
            showCancelButton: true,
            confirmButtonText: 'Importar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                importar_alumno_entregas_plantilla({usuario_id: usuarioSeleccionado.id, alumno_id: alumno_id}).then( (response) => {

                    getAlumnosEntregas({alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {
        
                        response.entregas && setEntregas(response.entregas)
                        
                    })
        
                })
        
            } else if (result.isDenied) {
            // //   Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }
  return (
    <>
        <Grid container spacing={2} style={{marginBottom: 10}}>
            <Grid item xs={12} md={12}>
                { tipo_id === 177 ? <Typography display="inline" variant='h6'>Entregas extraordinarias</Typography> : <Typography display="inline" variant='h6'>Entregas</Typography>}

                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClickOpciones}
                >
                    <MoreVertIcon />
                </IconButton>

                <Menu
                    id="long-menu"
                    anchorEl={anchorElOpciones}
                    keepMounted
                    open={open}
                    onClose={handleCloseOpciones}
                >

                    {/* <MenuItem key="opcion1"  onClick={() => handleCreaEntrega()}> */}
                    <MenuItem key="opcion1"  onClick={() => {
                        setEntregaDoubleClick(undefined)
                        handleCloseOpciones()
                        setOpenModalCreate(true)
                    }}>
                        Añadir entrega personalizada
                    </MenuItem>
                    {/* <MenuItem key="opcion1"  onClick={() => {
                        setEntregaDoubleClick(undefined)
                        handleCloseOpciones()
                        handleImportarEntregasGrupo()
                    }}>
                        Importar entregas del grupo
                    </MenuItem> */}
                    {/* <MenuItem key="opcion2"  onClick={() => {}}>
                        Importar plantilla entregas
                    </MenuItem> */}

                </Menu>
            </Grid>
        </Grid>  

        <Grid container  spacing={2}>

            <div style={{ height: 500, width: '100%', padding:"0 5px" }}>

                {entregas && <DataGridPro 
                    rows={entregas} 
                    columns={columnas_entregas} 
                    pagination={false}
                    onRowDoubleClick={ (row) => { handleDoubleClick(row.row) } }
                    initialState={{
                        columns: {
                          columnVisibilityModel: {
                            ticket2_id: false,
                          },
                        },
                      }}
                />}

            </div>
                                    
        </Grid>
        <Grid container  spacing={2} style={{marginTop: 20, marginLeft: 5}}>
            <Box mr={5}><Chip size="small" style={{backgroundColor: red[500], color: 'white', marginRight: 10}} label={"Abierto"} /> Pendiente de resolución por parte del tutor</Box>
            <Box mr={5}><Chip size="small" style={{backgroundColor: blue[500], color: 'white', marginRight: 10}} label={"Pendiente"} /> Espera respuesta del alumno</Box>
            <Box mr={5}><Chip size="small" style={{backgroundColor: green[500], color: 'white', marginRight: 10}} label={"Resuelto"} /> Entrega resuelta</Box>
        </Grid>

        <DialogEntregaForm 
            openModal={openModalCreate}
            handleOpenModal={ () => {
                setOpenModalCreate(false);
            }}
            alumnoID={alumno_id}
            entrega={entregaDoubleClick}
            setEntregas={setEntregas}
        />
        
        <DialogEntregaCambiarFechasForm 
            openModal={openModalUpdateFechas}
            handleOpenModal={ () => {
                setOpenModalUpdateFechas(false);
            }}
            alumnoID={alumno_id}
            entrega={entregaDoubleClick}
            setEntregas={setEntregas}
        />


    </>
  )
}

export default AlumnoFormEntregas
