import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { useSelector } from 'react-redux'
import { notificacionTutoresDestino, notificacionTutoresDestinoCreate, notificacionesUsuario } from '../../../services/alumnos'
import { DataGridPro } from '@mui/x-data-grid-pro'

const DialogNotificacionForm = ({openModal, handleCerrarDialorNotificacion}) => {
    const [destinosSeleccionados, setDestinosSeleccionados] = useState([])
    
    const [descripcion, setDescripcion] = useState('')
    const [destinatarios, setDestinatarios] = useState([])
    
    const [errorDescripcion, setErrorDescripcion] = useState('')
    const [errorDestinatarios, setErrorDestinatarios] = useState('')

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
      
        notificacionTutoresDestino({usuario_id: usuarioSeleccionado.id}).then( (response) => {
            setDestinatarios(response?.destinos)
        })
    
    }, [])
    
    const handleCreateNotificacion = () => {

        if( destinosSeleccionados.length === 0 ) {
            setErrorDestinatarios('Debe seleccionar al menos un tutor')
        } else {
            setErrorDestinatarios('')
        }
        if( descripcion === '' ) {
            setErrorDescripcion("Introduce una descripcion")
        } else {
            setErrorDescripcion('')
        }

        if( destinosSeleccionados.length > 0 && descripcion !== '' ) {
            notificacionTutoresDestinoCreate({
                usuario_id: usuarioSeleccionado.id, 
                descripcion: descripcion, 
                destinatarios: destinosSeleccionados
            }).then( (response) => {
                handleCerrarDialorNotificacion()
            })
        }

    }

    const setSelection = (params) => {
        setDestinosSeleccionados(params)
    }
    const columnas = [
      { field: 'id', headerName: 'ID', hide: true },
      { field: 'nombre', headerName: 'Nombre', flex: 1  },
    ]

    return (
        <Dialog open={openModal} onClose={() => handleCerrarDialorNotificacion() } maxWidth={'md'}>
            <DialogTitle>Nueva notificación</DialogTitle>
            <DialogContent style={{width: '700px'}}>
            
                <Grid xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        // error={errorDescripcion}
                    >
                        <TextField
                            autoFocus
                            id="descripcion"
                            type="text"
                            fullWidth
                            variant="standard"
                            label="Texto de la notificación"
                            multiline
                            minRows={5}
                            value={descripcion}
                            onChange={ (value) => { setDescripcion(value.target.value)} }
                        />

                        { errorDescripcion !== '' && <FormHelperText style={{color: 'red'}}>Es necesario escribir una descripción</FormHelperText> }

                    </FormControl>

                </Grid>
                <Grid xs={12} style={{marginTop: 20, width: '100%'}}>
                    <FormControl fullWidth>
                        <FormLabel>Destinatarios</FormLabel>
                        <div style={{ height: 300, width: '100%' }}>
                        <DataGridPro
                            rows={destinatarios}
                            columns={columnas}
                            checkboxSelection
                            hideFooter

                            pagination={false}
                            rowHeight={30}
                            disableSelectionOnClick
                            disableDensitySelector
                            onStateChange={ (item) => setSelection(item.rowSelection) }
                            // localeText={esES.components.MuiDataGrid.defaultProps.localeText} 
                            // components={{Toolbar: GridToolbar}}
                        />
                        </div>
                        { errorDestinatarios !== '' && <FormHelperText style={{color: 'red'}}>Es necesario escribir una descripción</FormHelperText> }
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCerrarDialorNotificacion()}>Cancelar</Button>
                <Button onClick={() => handleCreateNotificacion()}>Crear notificación</Button>
            </DialogActions>
        </Dialog>
  )
}

export default DialogNotificacionForm
