import axios from "axios";
import { BASE_URL_SERVICE } from "../constants";
import { escapeHtml } from "../utils/regExp";

export class ConversacionesService {
    
    static comentarioInsert = async ( {usuario_id, campos, alumno_id, status} ) => {

      try {
        let formData = new FormData();

        formData.append("alumno_id", `${alumno_id}`);
        formData.append("origen", campos.origen);
        formData.append("duda_id", campos.id);
        formData.append("comentario", campos.comentario);
        formData.append("adjunto", campos.adjunto[0]);
        formData.append("created_usuario_tipo","Tutor");
        formData.append("created_usuario_id", `${usuario_id}`);
        formData.append("status", status);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/comentario_insert",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    
    static conversacionCambiarEstado = async ( {usuario_id, origen, origen_id, estado} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("origen", `${origen}`);
        formData.append("origen_id", `${origen_id}`);
        formData.append("estado", `${estado}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/conversacion_cambiar_estado",
          data: formData,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
}