import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { IconButton, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';



const QuickSearchToolbar = (props) => {
    const useStyles = makeStyles((theme) => ({
        rootFilter: {
          padding: theme.spacing(0.5, 0.5, 0),
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        },
        textFieldFilter: {
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
          margin: theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
          },
          '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        },
      }));

      const classes = useStyles();
    
      return (
        <div className={classes.rootFilter}>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </div>
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Buscar…"
            className={classes.textFieldFilter}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </div>
      );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default QuickSearchToolbar
