import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getAlumnosTickets, getTutorAlumnoMensajesDirectos } from '../../../services/alumnos';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import moment from 'moment';
import Moment from 'react-moment';
import StatusTicket from '../../../components/StatusTicket';
import { BASE_URL_ZENDESK } from '../../../constants';
import { blue, green, red } from '@material-ui/core/colors';
import DialogMensajeDirecto from './DialogMensajeDirecto';

const AlumnoFormMensajesDirectos = ({ alumno_id }) => {
  const [loading, setLoading] = useState(false)
  const [mensajesDirectos, setMensajesDirectos] = useState(undefined)
  const [showDialogMensaje, setShowDialogMensaje] = useState(false)
  const [mensajeDirectoSelected, setMensajeDirectoSelected] = useState([])

  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
        
    setLoading(true);

    cargarMensajesDirectos()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alumno_id])

  const cargarMensajesDirectos = () => {
    const usuario_id = usuarioSeleccionado.id

    alumno_id && getTutorAlumnoMensajesDirectos({alumno_id, usuario_id}).then( (response) => {

        response.mensajes && setMensajesDirectos(response.mensajes)

    })
  }

  /* -------------------------------------------------------------------------- */
    /*                                  Columnas                                  */
    /* -------------------------------------------------------------------------- */
    
    const columnas_mensajes = [
      { field: 'id', headerName: 'ID', hide: true },
      { field: 'asunto', headerName: 'Asunto', minWidth: 150, flex: 1 },
      {
        'field': 'created', 
        'width':150, 
        'headerName': 'Fecha', 
        'description': 'Fecha',
        type: 'date',
        valueFormatter: params => {
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      
  ];

  const handleShowMensajeDirecto = (row) => {
    setMensajeDirectoSelected(row)
    setShowDialogMensaje(true)
  }

  return (
    <>
      <Grid container  spacing={2} style={{marginBottom: 10}}>
        <Grid item xs={12} md={12}>
            <Typography display="inline" variant='h6'>Mensajes directos enviados</Typography>

            {/* <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClickOpciones}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="long-menu"
                anchorEl={anchorElOpciones}
                keepMounted
                open={open}
                onClose={handleCloseOpciones}
            >

                <MenuItem key="opcion1"  onClick={() => {
                    setEntregaDoubleClick(undefined)
                    handleCloseOpciones()
                    setOpenModalCreate(true)
                }}>
                    Añadir entrega personalizada
                </MenuItem>

            </Menu> */}
         </Grid>
      </Grid>

      <Grid container  spacing={2}>

          <div style={{ height: 500, width: '100%', padding:"0 5px" }}>

              {mensajesDirectos && <DataGridPro 
                  rows={mensajesDirectos} 
                  columns={columnas_mensajes} 
                  pagination={false}
                  onRowDoubleClick={ (item) => { handleShowMensajeDirecto(item.row) } }
              />}

          </div>
          
          <DialogMensajeDirecto 
            handleCerrarDialog={setShowDialogMensaje} 
            mensajeDirecto={mensajeDirectoSelected} 
            openModal={showDialogMensaje} 
          />

      </Grid>
    </>
  )
}

export default AlumnoFormMensajesDirectos
