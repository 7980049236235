import { Alert, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { notificacionesUsuarioPendientes } from '../../services/alumnos'
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../redux/reducer/loginReducer';

const AlertNotificaciones = () => {
    const [mostrarAlert, setMostrarAlert] = useState(false)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
      notificacionesUsuarioPendientes({usuario_id: usuarioSeleccionado.id}).then( (response) => {
        console.log(response)

        response.hay_notificaciones_pendientes === true && setMostrarAlert(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
        { mostrarAlert === true && 
            <Paper style={{ marginLeft: 20, marginRight: 20, marginBottom: 20}}> 
                <Alert severity="error">Tienes notificaciones pendientes de leer <a href='/notificaciones'>Notificaciones</a></Alert>
            </Paper>
        }
        </>
    )
}

export default AlertNotificaciones
