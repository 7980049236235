import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import LiquidacionesContent from './components/LiquidacionesContent';

const Liquidaciones = () => {

    return (
        <>

            <TemplateDashboard>
                <LiquidacionesContent />
            </TemplateDashboard>

        </>
    )
};

export default Liquidaciones;