import React, { useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { Box, Button, MenuItem, Select, Stack, TextField } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../redux/reducer/loginReducer";
import { ConversacionesService } from "../services/Conversaciones.service";
import { RichTextField } from "../components/RichTextField";
import Swal from "sweetalert2";
import { blue, green, red } from "@mui/material/colors";

const schema = yup.object().shape({
  comentario: yup.string().required("Campo obligatorio"),
  adjunto: yup.mixed(),
  estado: yup.string()
});

const FormComentario = ({ origenTipo, origenData, fetchData }) => {
  const refForm = useRef()
  const [filename, setFilename] = useState('')
  const [estado, setEstado] = useState('Resuelto')
  // const [statusComentario, setStatusComentario] = useState('Resuelto')

  // const toast = useToast()
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    data.id = origenData.id
    data.origen = origenTipo

    Swal.fire({
      title: `Vas a enviar tu respuesta con el estado ${estado} ¿Estás seguro?`,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: `Cancelar`,
      confirmButtonColor: '#afcb0d',
      cancelButtonColor: '#a5a5a5',
      icon: 'warning',
      reverseButtons: true
    }).then((result) => {
      ConversacionesService.comentarioInsert({usuario_id: usuarioSeleccionado.id, campos: data, alumno_id: origenData.alumno_id, status: estado}).then( response => {
        if(response?.status === true) {
          fetchData()
        } else {
        }
      })

      setFilename('')
      reset()
    })
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} ref={refForm}>
      <div className="flex flex-col" style={{ paddingTop: 10 }}>
        <label>Origen: {origenTipo}</label>

        <RichTextField getValues={getValues} setValue={setValue} errors={errors} />

        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>

          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              onChange={ (e) => { setFilename(e.target.files[0].name)}}
            >
              Adjunto
              <VisuallyHiddenInput type="file" {...register("adjunto")} />
            </Button>
            <div>{filename}</div>
          </Stack>

          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" type="submit" style={{backgroundColor: blue[300], color: "white", border: blue[400]}} onClick={ () => {
              setEstado('Pendiente')
              handleSubmit(onSubmitHandler)
            }}>
              Enviar como pendiente
            </Button>
            <Button variant="outlined" type="submit" style={{backgroundColor: green[300], color: "white", border: green[400]}} onClick={ () => {
              setEstado('Resuelto')
              handleSubmit(onSubmitHandler)
            }}>
              Enviar como resuelto
            </Button>
            <Button variant="outlined" type="submit" style={{backgroundColor: red[300], color: "white", borderColor: red[400]}} onClick={ () => {
              setEstado('Abierto')
              handleSubmit(onSubmitHandler)
            }}>
              Enviar como abierto
            </Button>
            {/* <Button variant="outlined" type="submit">
              Enviar
            </Button> */}
          </Stack>
        </Stack>
      </div>
    </form>
  );
};

export default FormComentario;
