import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { useSelector } from 'react-redux'
import { styled } from "@mui/material/styles";
import { InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { getListaDetalle } from '../../../services/lista_detalle'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { red } from '@mui/material/colors';
import { RichTextField } from '../../../components/RichTextField';
import { NotificacionAlumnoService } from '../../../services/NotificacionesAlumnos.service';
import Swal from 'sweetalert2';
import { BASE_URL_SERVICE_UPLOADS } from '../../../constants';

  
const DialogNotificacionForm = ({openModal, handleCerrarDialogNotificacion, notificacion}) => {
  const [tipos, setTipos] = useState([])
  const [filename, setFilename] = useState( notificacion ? notificacion.adjunto_nombre_original : "")
  
  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const schema = yup.object().shape({
    tipo_id: yup.string().required("Campo obligatorio"),
    comentario: yup.string().required("Campo obligatorio"), // descripcion
    asunto: yup.string().required("Campo obligatorio"),
    adjunto: yup.mixed(),
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getListaDetalle({ usuario_id: usuarioSeleccionado.id, lista_id: 20 }).then(
      (response) => {
        setTipos(response.lista_detalle)
      }
    );
  }, []);
  useEffect(() => {
    setValue("tipo_id", notificacion?.tipo_id)
    setValue("asunto", notificacion?.asunto)
    setValue("comentario", notificacion?.cuerpo)
    setValue("adjunto", notificacion?.adjunto)
  }, [notificacion]);

  const onSubmitHandler = (data) => {
    if(notificacion) {
      NotificacionAlumnoService.update({usuario_id: usuarioSeleccionado.id, notificacion_id: notificacion.id, campos: data}).then(response => {
        if(response.status === false) {
          Swal.fire(response.message)
        }
      })
    } else {
      NotificacionAlumnoService.insert({usuario_id: usuarioSeleccionado.id, campos: data}).then(response => {
        if(response.status === false) {
          Swal.fire(response.message)
        }
      })
    }
    reset();
    handleCerrarDialogNotificacion()
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={() => handleCerrarDialogNotificacion()}
      maxWidth={"xl"}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogTitle>Nueva notificación</DialogTitle>
        <DialogContent>
          <Grid xs={12}>
            <FormControl
              fullWidth
              style={{ marginTop: 16 }}
            //   error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
            >
              <InputLabel id="tipo_id-label">Tipo de notificación</InputLabel>
              <Select
                labelId="tipo_id-label"
                id="tipo"
                name="tipo"
                label="Tipo"
                fullWidth
                variant='standard'
                value={notificacion?.tipo_id || getValues("tipo_id")}
                onChange={ e => setValue("tipo_id", e.target.value) }
                error={errors.tipo_id}
                placeholder='Seleccionar un tipo'
              >
                {tipos &&
                  tipos.map((item) => {
                    if(item.id === notificacion?.tipo_id) {
                        console.log("->", item.id, notificacion?.tipo_id)
                        return (
                          <MenuItem selected key={item.id} value={item.id}>
                            {item.nombre}
                          </MenuItem>
                        )
                      } else {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nombre}
                          </MenuItem>
                        )
                      }
                  })}
              </Select>
              {errors.tipo_id && (
                <Typography style={{ color: red[500], fontSize: 10 }}>
                  {errors.tipo_id.message}
                </Typography>
              )}
            </FormControl>

            <FormControl variant="outlined" fullWidth error={errors.asunto}>
              <TextField
                autoFocus
                type="text"
                fullWidth
                variant="standard"
                label="Asunto"
                {...register("asunto")}
              />
              {errors.asunto && (
                <Typography style={{ color: red[500], fontSize: 10 }}>
                  {errors.asunto.message}
                </Typography>
              )}
            </FormControl>
            <RichTextField
              getValues={getValues}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          {
          notificacion && 
          <Grid xs={12}>
            Adjunto: <a href={`${BASE_URL_SERVICE_UPLOADS}adjuntos_notificaciones/${notificacion.adjunto_nombre}`} target='_blank' rel="noreferrer">{notificacion.adjunto_nombre_original}</a>
          </Grid>
          }
        </DialogContent>
        <DialogActions style={{ display: "block", padding: "10px 22px" }}>
          <Stack spacing={2} direction="row" justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={ (e) => { setFilename(e.target.files[0].name)}}
              >
                Adjunto
                <VisuallyHiddenInput type="file" {...register("adjunto")} />
              </Button>
              <div>{filename}</div>
            </Stack>

            <Stack spacing={2} direction="row">
              <Button onClick={() => handleCerrarDialogNotificacion()}>
                Cancelar
              </Button>

              { !notificacion && <Button type="submit">Crear notificación</Button>}
              { notificacion && <Button type="submit">Actualizar notificación</Button>}
            </Stack>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogNotificacionForm
