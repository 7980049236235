import DateFnsUtils from '@date-io/date-fns';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"

import { useFormik } from "formik";
import * as yup from 'yup';
import { getPlantillasEntregasAsignables, getTutoriasGrupalesEnlaces, insertar_tutoria_grupal_enlace, update_tutoria_grupal_enlace, } from '../../../services/alumnos';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getTutores } from '../../../services/tutores';

export const DialogTutoriaGrupalEnlaceForm = ({tutoriaGrupalEnlace, setTutorias, openModal, handleOpenModal}) => {
    const [tutores, setTutores] = useState([])
    const [plantillasAsignables, setPlantillasAsignables] = useState([])
    const [tutorSeleccionado, setTutorSeleccionado] = useState('')
    const [plantillaSeleccionada, setPlantillaSeleccionada] = useState('')

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {

        getTutores({usuario_id: usuarioSeleccionado.id}).then( (response) => {
            response.tutores && setTutores(response.tutores)
        })

        getPlantillasEntregasAsignables({usuario_id: usuarioSeleccionado.id, tutor_usuario_id: '-1'}).then( (response) => {
            response.plantillas && setPlantillasAsignables(response.plantillas)
        })

        if(tutoriaGrupalEnlace) {

            formik.setValues({
                tutor_id: tutoriaGrupalEnlace.tutor_id,
                plantilla_id: tutoriaGrupalEnlace.plantilla_id,
                url_video: tutoriaGrupalEnlace.url_video,
                url_adjunto: tutoriaGrupalEnlace.url_adjunto,
                descripcion: tutoriaGrupalEnlace.descripcion,
            })

        } else {
            
            formik.setValues({
                tutor_id: "",
                plantilla_id: "",
                url_video: "",
                url_adjunto: "",
                descripcion: "",
            })

        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            tutor_id: "",
            plantilla_id: "",
            url_video: "",
            url_adjunto: "",
            descripcion: "",
        },
        validationSchema: yup.object({
            tutor_id: yup.string().required("Campo requerido"),
            plantilla_id: yup.string().required("Campo requerido"),
            url_video: yup.string().required("Campo requerido"),
            url_adjunto: yup.string(),
            descripcion: yup.string().required("Campo requerido"),
          }),
        onSubmit: (values) => {
            if(tutoriaGrupalEnlace) {
                update_tutoria_grupal_enlace({
                    usuario_id: usuarioSeleccionado.id, 
                    tutoria_grupal_enlace_id: tutoriaGrupalEnlace.id,
                    campos:values 
                }).then( (response) => {
                    getTutoriasGrupalesEnlaces({ usuario_id: usuarioSeleccionado.id }).then((response) => {
                        response && setTutorias(response.tutorias)
                    });
                })
            } else {
                insertar_tutoria_grupal_enlace({
                    usuario_id: usuarioSeleccionado.id, 
                    campos: values
                }).then( (response) => {
                    getTutoriasGrupalesEnlaces({ usuario_id: usuarioSeleccionado.id }).then((response) => {
                        response && setTutorias(response.tutorias)
                    });
                })
            }
            handleOpenModal(false)
        },
    });

    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
      <Dialog
        open={openModal}
        onClose={() => handleOpenModal(false)}
        maxWidth={"md"}
      >
        <form
          noValidate
          method="POST"
          enctype="multipart/form-data"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle>Nuevo Enlace</DialogTitle>
          <DialogContent style={{ width: "700px" }}>
            <Grid container>
              <Grid item sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="tutor_id-label">Tutores</InputLabel>

                  <Select
                    labelId="tutor_id-label"
                    id="tutor_id"
                    name="tutor_id"
                    label="Tutor"
                    fullWidth
                    value={formik.values.tutor_id}
                    onChange={formik.handleChange}
                    error={formik.touched.tutor_id && Boolean(formik.errors.tutor_id)}
                  >
                    {tutores &&
                      tutores.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nombre} {item.apellidos}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <br />
                </FormControl>
              </Grid>

              <Grid item sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="plantilla_id-label">
                    Grupo de alumnos
                  </InputLabel>

                  <Select
                    labelId="plantilla_id-label"
                    id="plantilla_id"
                    name="plantilla_id"
                    label="Grupo"
                    fullWidth
                    value={formik.values.plantilla_id}
                    onChange={formik.handleChange}
                    error={formik.touched.plantilla_id && Boolean(formik.errors.plantilla_id)}
                  >
                    {plantillasAsignables &&
                      plantillasAsignables.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.descripcion}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {/* <FormHelperText>{formik.touched.plantilla_id && formik.errors.plantilla_id}</FormHelperText> */}
                </FormControl>
              </Grid>

              <Grid item sm={12}>
                <TextField
                  id="url_video"
                  name="url_video"
                  label="Video"
                  placeholder="Introduce la URL del video"
                  fullWidth
                  margin="normal"
                  value={formik.values.url_video}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={
                    formik.touched.url_video && Boolean(formik.errors.url_video)
                  }
                //   helperText={
                //     formik.touched.url_video && formik.errors.url_video
                //   }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  id="url_adjunto"
                  name="url_adjunto"
                  label="Adjunto"
                  placeholder="Introduce la URL del adjunto asociado"
                  fullWidth
                  margin="normal"
                  value={formik.values.url_adjunto}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={
                    formik.touched.url_adjunto &&
                    Boolean(formik.errors.url_adjunto)
                  }
                  helperText={
                    formik.touched.url_adjunto && formik.errors.url_adjunto
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  id="descripcion"
                  name="descripcion"
                  label="Descripcion"
                  placeholder="Breve descripción del vídeo"
                  fullWidth
                  margin="normal"
                  value={formik.values.descripcion}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={
                    formik.touched.descripcion &&
                    Boolean(formik.errors.descripcion)
                  }
                  helperText={
                    formik.touched.descripcion &&
                    formik.errors.url_adjdescripcionunto
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleOpenModal(false)}>Cancelar</Button>
            <Button type="submit">
              {" "}
              {tutoriaGrupalEnlace ? "Actualizar enla" : "Crear enlace"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
}