import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { enviarDifusion, enviar_duda_liquidacion } from '../../../services/alumnos'

const DialogDudaLiquidacionForm = ({liquidacionId, liquidacionUUID, openModal, handleCerrarDialogDudaLiquidacion}) => {
    const [descripcion, setDescripcion] = useState('')
    
    const [errorDescripcion, setErrorDescripcion] = useState('')

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const handleCreateNotificacion = () => {
        if( descripcion === '' ) {
            setErrorDescripcion("Introduce una descripcion")
        } else {
            setErrorDescripcion('')
        }

        enviar_duda_liquidacion({usuario_id: usuarioSeleccionado.id, descripcion: descripcion, liquidacion_id: liquidacionId, liquidacion_uuid: liquidacionUUID}).then( (response) => {
            handleCerrarDialogDudaLiquidacion()
        })
    }

    return (
        <Dialog open={openModal} onClose={() => handleCerrarDialogDudaLiquidacion() } maxWidth={'md'}>
            <DialogTitle>Plantea tu duda</DialogTitle>
            <DialogContent style={{width: '700px'}}>
            
                <Grid xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                    >
                        <TextField
                            
                            id="descripcion"
                            type="text"
                            fullWidth
                            variant="standard"
                            label="Texto de la duda"
                            multiline
                            minRows={10}
                            value={descripcion}
                            onChange={ (value) => { setDescripcion(value.target.value)} }
                        />

                        { errorDescripcion !== '' && <FormHelperText style={{color: 'red'}}>Es necesario escribir una descripción</FormHelperText> }

                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCerrarDialogDudaLiquidacion()}>Cancelar</Button>
                <Button onClick={() => handleCreateNotificacion()}>Enviar duda</Button>
            </DialogActions>
        </Dialog>
  )
}

export default DialogDudaLiquidacionForm
