import { Box, Card, CardContent, CardHeader, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getTutores } from '../../services/tutores'
import { useSelector } from 'react-redux'
import { selectUsuarioSeleccionado } from '../../redux/reducer/loginReducer'
import ReactApexChart from 'react-apexcharts'


const CargaAlumnosTutor = () => {
    const [loading, setLoading] = useState(false)
    const [tutores, setTutores] = useState([])
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
        setLoading(true)
        
        getTutores({ usuario_id: usuarioSeleccionado.id }).then((response) => {

            response.tutores && setTutores(response.tutores)

            setLoading(false)
        });

    }, [])
    

    const state = {
          
        series: [{
          data: tutores.map( (tutor) => tutor.num_alumnos_asignados )
        }],
        options: {
          chart: {
            type: 'bar',
            height: 750
          },
          plotOptions: {
            bar: {
                borderRadius: 4,
                barHeight: '100%',
                // distributed: true,
                horizontal: true,
                // dataLabels: {
                //     position: 'bottom'
                // },
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: tutores.map( (tutor) => `${tutor.nombre} ${tutor.apellidos}` )
          }
        },
      
      
      };
    

    return (
        <>
            {/* <Grid xs={12} style={{margin: 0, padding: '0 20px'}} spacing={2}>
                <ReactApexChart options={state.options} series={state.series} type="bar" height={750} />
            </Grid> */}
            <Grid container xs={12} style={{margin: 0, padding: '0 20px'}} spacing={2}>
            
        
                {
                    tutores.map( (tutor) => {
                        if(isNaN(parseInt(tutor.num_max_alumnos))){
                            tutor.num_max_alumnos = 0
                        }
                        return (
                            <Grid item xs={2}>
                                <Card>
                                    <CardContent>
                                        <Typography component="h2" variant="h5" color="primary" style={{height: 70}}>
                                        {tutor.nombre} {tutor.apellidos}
                                        </Typography>
                                        <Typography component="h4" variant="h6" style={{color: '#bdbdbd', fontSize: 16, height:70}} gutterBottom>
                                        {tutor.grupo_tutor}
                                        </Typography>

                                        {
                                        tutor.num_alumnos_asignados === "0" ?
                                        
                                        <Typography align='right' component="p" variant="h4">
                                            {tutor.num_alumnos_asignados}
                                        </Typography>
                                        
                                        :

                                            
                                        parseInt(tutor.num_alumnos_asignados) >= parseInt(tutor.num_max_alumnos) ?

                                        <Typography align='right' component="p" variant="h4" style={{color: 'red'}}>{parseInt(tutor.num_alumnos_asignados)}/{parseInt(tutor.num_max_alumnos) }</Typography>

                                        :

                                        parseInt(tutor.num_max_alumnos) - parseInt(tutor.num_alumnos_asignados) <= 10?

                                            <Typography align='right' component="p" variant="h4" style={{color: 'orange'}}>{parseInt(tutor.num_alumnos_asignados)}/{parseInt(tutor.num_max_alumnos) }</Typography>

                                        :
                                            
                                            <Typography align='right' component="p" variant="h4">{parseInt(tutor.num_alumnos_asignados)}/{parseInt(tutor.num_max_alumnos) }</Typography>

                                        }

                                        <Typography align='right' color="text.secondary">
                                            Alumnos
                                        </Typography>
                                        
                                    </CardContent>
                                </Card>
                            </Grid>
            
                        )
                    })
                    
                }

            </Grid>
        </>
    )
}

export default CargaAlumnosTutor
