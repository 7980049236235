import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import NotificacionesAlumnosContent from './components/NotificacionesAdmonContent';

const NotificacionesAlumnos = () => {

    return (
        <>

            <TemplateDashboard>
                <NotificacionesAlumnosContent />
            </TemplateDashboard>

        </>
    )
};

export default NotificacionesAlumnos;