/* -------------------------------------------------------------------------- */
/*                                 formatDate                                 */
/* -------------------------------------------------------------------------- */
export function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export const escapeHtml = (unsafe) =>
{
    return unsafe;
    // return unsafe
        //  .replace(/&/g, "&amp;")
        // .replace(/>/g, "&gt;")
        //  .replace(/</g, "&lt;")
        //  .replace(/"/g, "&quot;")
        //  .replace(/'/g, "&#039;");
 }

export const requestSearch = (plantillas, setSearchText, setRows, searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
    const filteredRows = plantillas.filter((row) => {
        return Object.keys(row).some((field) => {
            return searchRegex.test(row[field]);
        });
    });
    setRows(filteredRows);
  };