import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StatusTicket from "../../../components/StatusTicket";
import { GRAY_400 } from "../../../constants/colores";
import { Button, Stack } from "@mui/material";
import { DudaService } from "../../../services/Duda.service";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import { ConversacionesService } from "../../../services/Conversaciones.service";
import Swal from "sweetalert2";
import { getTutores } from "../../../services/tutores";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DialogTutoresApoyo from "./DialogTutoresApoyo";
import { conversacion_add_tutor_apoyo, conversacion_delete_tutor_apoyo, conversacion_tutor_apoyo } from "../../../services/alumnos";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const SidebarDetalle = ({ origenTipo, origenData, fetchData }) => {
  

  return (
    <div>
      {origenTipo === "Duda" && (
        <SidebarDuda
          origenTipo={origenTipo}
          origenData={origenData}
          fetchData={fetchData}
        />
      )}
      {(origenTipo === "Entrega" || origenTipo === "Simulacro") && (
        <SidebarEntregaSimulacro
          origenTipo={origenTipo}
          origenData={origenData}
          fetchData={fetchData}
        />
      )}

      
    </div>
  );
};

export default SidebarDetalle;

/* ------------------------------- Componentes ------------------------------ */
const TutoresApoyo = ({origen, origen_id}) => {
  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const [openModal, setOpenModal] = useState(false);

  const [tutoresApoyo, setTutoresApoyo] = useState(false);

  const fetchTutoresApoyo = () => {
    conversacion_tutor_apoyo({origen: origen, origen_id:origen_id}).then((res) => {
      setTutoresApoyo(res.apoyos)
    })
  }

  useEffect(() => {
    fetchTutoresApoyo()
  }, []);

  const handleCerrarDialog = (tutor_id) => {
    setOpenModal(false);

    conversacion_add_tutor_apoyo({usuario_id: usuarioSeleccionado.id, origen: origen, origen_id: origen_id, tutor_id: tutor_id }).then((res) => {    // Agregamos el tutor a la conversación
      fetchTutoresApoyo();
    })
  };

  return (
    <>
    <div style={{ marginTop: 10, marginBottom: 10, color: GRAY_400 }}>
              Tutores de apoyo
              <IconButton
                aria-label="add"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </div>
            <br />
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tutor</TableCell>
                    <TableCell>Asignación</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tutoresApoyo &&
                    tutoresApoyo.map((tutor) => (
                      <TableRow key={tutor.id}>
                        <TableCell>{tutor.usuario_nombre}</TableCell>
                        <TableCell>
                          <Stack direction={"row"} spacing={2}>
                            {tutor.created}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="Delete"
                            onClick={() => {
                              conversacion_delete_tutor_apoyo({usuario_id: usuarioSeleccionado.id, origen: origen, origen_id: origen_id, tutor_id: tutor.usuario_id}).then((res) => {
                                fetchTutoresApoyo();
                              })
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>

            <DialogTutoresApoyo
        openModal={openModal}
        handleCerrarDialog={handleCerrarDialog}
      />
    </>
  )
}
const SidebarDuda = ({ origenTipo, origenData, fetchData }) => {
  console.log(origenData)
  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  const handleCambiarEstado = (nuevoEstado) => {
    Swal.fire({
      title: "Vas a cambiar el estado a Pendiente ¿Estas seguro?",
      showCancelButton: true,
      confirmButtonText: "Cambiar",
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#afcb0d",
      cancelButtonColor: "#a5a5a5",
      icon: "warning",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        ConversacionesService.conversacionCambiarEstado({
          usuario_id: usuarioSeleccionado.id,
          origen: origenTipo,
          origen_id: origenData.id,
          estado: nuevoEstado,
        }).then((res) => {
          fetchData();
        });
      }
    });
  };
  return (
    <div>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Paper style={{ padding: 20 }}>
          <div>
            <div style={{ marginBottom: 10, color: GRAY_400 }}>Creado:</div>
            <div style={{ marginBottom: 15 }}>{origenData.created}</div>
            <div style={{ marginBottom: 10, color: GRAY_400 }}>Estado:</div>
            <Stack direction={"row"} spacing={2}>
              <StatusTicket
                tipo={origenTipo}
                ticketStatus={origenData.status}
              />
            </Stack>
            <div style={{ marginBottom: 10, color: GRAY_400, marginTop: 10 }}>
              Cambiar estado a:
            </div>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={() => handleCambiarEstado("Pendiente")}
              >
                Cambiar a pendiente
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleCambiarEstado("Resuelto")}
              >
                Cambiar a Resuelto
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleCambiarEstado("Abierto")}
              >
                Cambiar a Abierto
              </Button>
            </Stack>

            <TutoresApoyo 
              origen={origenTipo}
              origen_id={origenData.id} 
            />

            <br />
          </div>
        </Paper>
      </Grid>

      
    </div>
  );
};

const SidebarEntregaSimulacro = ({ origenTipo, origenData, fetchData }) => {
  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  const handleCambiarEstado = (nuevoEstado) => {
    Swal.fire({
      title: `Vas a cambiar el estado a ${nuevoEstado} ¿Estas seguro?`,
      showCancelButton: true,
      confirmButtonText: "Cambiar",
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#afcb0d",
      cancelButtonColor: "#a5a5a5",
      icon: "warning",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        ConversacionesService.conversacionCambiarEstado({
          usuario_id: usuarioSeleccionado.id,
          origen: origenTipo,
          origen_id: origenData.id,
          estado: nuevoEstado,
        }).then((res) => {
          console.log(res);
          fetchData();
        });
      }
    });
  };

  return (
    <Grid item xs={12} style={{ paddingTop: 0 }}>
      <Paper style={{ padding: 20 }}>
        <div>
          <div style={{ marginBottom: 10, color: GRAY_400 }}>
            Fecha planificación:
          </div>
          <div style={{ marginBottom: 15 }}>
            {origenData.fecha_planificacion}
          </div>
          <div style={{ marginBottom: 10, color: GRAY_400 }}>
            Fecha resuelto:
          </div>
          <div style={{ marginBottom: 15 }}>{origenData.fecha_resuelto}</div>
          <div style={{ marginBottom: 10, color: GRAY_400 }}>Estado:</div>
          <Stack direction={"row"} spacing={2}>
            <StatusTicket
              tipo={origenTipo}
              ticketStatus={origenData.ticket_status}
            />
          </Stack>

          <div style={{ marginBottom: 10, color: GRAY_400, marginTop: 10 }}>
            Cambiar estado a:
          </div>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => handleCambiarEstado("Pendiente")}
            >
              Cambiar a pendiente
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleCambiarEstado("Resuelto")}
            >
              Cambiar a Resuelto
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleCambiarEstado("Abierto")}
            >
              Cambiar a Abierto
            </Button>
          </Stack>

          <TutoresApoyo 
              origen={origenTipo} 
              origen_id={origenData.id} 
            />

          <br />
          <br />
        </div>
      </Paper>
    </Grid>
  );
};
