import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


// import EmptyComponent from '../../../../components/EmptyContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { actualizarCalendly, getAlumnosCoordinador, getTutoriasIndividuales } from '../../../services/alumnos';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
// import { Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { Box, Chip, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import SyncIcon from '@material-ui/icons/Sync';
// import Swal from 'sweetalert2';
// import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// import PropTypes from 'prop-types';
// import TituloPagina from '../../../../components/Titulos/TituloPagina';
// import AddIcon from '@material-ui/icons/Add';
// import { getAlumnosTutor } from '../../../../services/alumnos';
// import { Stack } from '@mui/system';
// import Moment from 'react-moment';
// import { DataGridPro, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import DataGridInnovattio from '../../../components/DataGridInnovatiio';
import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
import StatusTutor from '../../../components/StatusTutor';
import StatusEntregasAlumno from '../../../components/StatusEntregasAlumno';
import StatusTicketsAlumno from '../../../components/StatusTicketsAlumno';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { blue, green, red } from '@material-ui/core/colors';
import { getTutores } from '../../../services/tutores';
import CargaAlumnosTutor from '../../../components/CargaAlumnosTutor';
// import moment from 'moment/moment';
// import { amber, blue, green, red, yellow } from '@material-ui/core/colors';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function CargaTutoresContent() {
    const classes = useStyles();

    const [tutores, setTutores] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    // // const [selectionModel, setSelectionModel] = React.useState([]);
    // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        return (
            tutores ?
                <>
                     <TituloPagina titulo={"Carga de tutores"} />

                    <Box>

                      <CargaAlumnosTutor />

                    </Box>
                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
