import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import AlertNotificaciones from '../../components/AlertNotificaciones/AlertNotificaciones';
import TutoriasIndividualesSinAlumnoContent from './components/TutoriasIndividualesSinAlumnoContent';

const TutoriasIndividualesSinAlumno = () => {

    return (
        <>

            <TemplateDashboard>
                <AlertNotificaciones />
                <TutoriasIndividualesSinAlumnoContent />
            </TemplateDashboard>

        </>
    )
};

export default TutoriasIndividualesSinAlumno;