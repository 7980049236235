import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosTutorContent from './components/AlumnosTutorContent';
import AlertNotificaciones from '../../../components/AlertNotificaciones/AlertNotificaciones';

const AlumnosTutor = () => {

    return (
        <>

            <TemplateDashboard>
                <AlertNotificaciones />
                <AlumnosTutorContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosTutor;