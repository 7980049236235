import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { useSelector } from 'react-redux'
import { getTutores } from '../../../services/tutores'
import { DataGrid } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'

const DialogTutoresApoyo = ({openModal, handleCerrarDialog }) => {
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [tutores, setTutores] = useState([])
    const [tutorIdSeleccionado, setTutorIdSeleccionado] = useState(null)

    useEffect(() => {
        getTutores({usuario_id: usuarioSeleccionado.id}).then( (response) => {
            response.tutores && setTutores(response.tutores)
        })
    }, []);

    useEffect(() => {
        setTutorIdSeleccionado(null)
    }, [openModal]);

    return (
        <Dialog open={openModal} onClose={() => handleCerrarDialog() } maxWidth={'lg'}>
            <DialogTitle>Tutores de apoyo</DialogTitle>
            <DialogContent style={{width: '700px'}}>
            
                <Grid xs={12}>
                
                    <div style={{height: '400px'}}>
                    <DataGridPro
                        rows={tutores}
                        columns={[
                          { field: 'nombre', headerName: 'Nombre', width: 200, renderCell: (cell) => {return <>{`${cell.row.nombre} ${cell.row.apellidos}`}</>}},
                        ]}
                        onRowSelectionModelChange={(rowId) => { setTutorIdSeleccionado(rowId) }}
                    />
                    </div>

                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCerrarDialog()}>Cancelar</Button>
                <Button onClick={() => handleCerrarDialog(tutorIdSeleccionado)}>Añadir</Button>
            </DialogActions>
        </Dialog>
  )
}

export default DialogTutoresApoyo
