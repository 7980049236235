import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { useSelector } from 'react-redux'
import { notificacionHilo, notificacionTutoresDestinoRespuesta } from '../../../services/alumnos'

const DialogNotificacionView = ({openModal, handleCerrarDialorNotificacion, notificacion, handleDialogResponder}) => {
    const [descripcion, setDescripcion] = useState('')
    const [errorDescripcion, setErrorDescripcion] = useState('')
    const [loading, setLoading] = useState(false)
    const [hiloRespuestas, setHiloRespuestas] = useState()

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    
    useEffect(() => {
        setLoading(true)
        notificacionHilo({usuario_id: usuarioSeleccionado.id, notificacion_usuario_id: notificacion.notificacion_usuario_id}).then( (response) => {
            response.hilo && setHiloRespuestas(response.hilo)

            response.hilo && console.log(response.hilo)
        })

        // return () => {
        //     setNotificacionPadre([]); // This worked for me
        //     setNotificacionHilo([]); // This worked for me
        //   };
    }, [notificacion])
    

    const handleSendRespuesta = () => {
        if( descripcion === '' ) {
            setErrorDescripcion("Introduce una descripción")
        } else {
            setErrorDescripcion('')
        }

        if( descripcion !== '' ) {
            notificacionTutoresDestinoRespuesta({
                origen_usuario_id: usuarioSeleccionado.id, 
                destino_usuario_id: notificacion.origen_usuario_id, 
                descripcion: descripcion,
                notificacion_usuario_id: notificacion.notificacion_usuario_id,
            }).then( (response) => {
                handleCerrarDialorNotificacion()
            })
        }
    }
    return (
        <Dialog open={openModal} onClose={() => handleCerrarDialorNotificacion() } maxWidth={'lg'}>
            <DialogTitle>Notificación</DialogTitle>
            <DialogContent style={{width: '700px'}}>
            
                { hiloRespuestas && <Grid xs={12} style={{border: '1px solid grey', padding: 20, borderRadius: 10, marginBottom: 30}}>
                    <span style={{whiteSpace: 'pre-line'}}>{hiloRespuestas.notificacion_padre.descripcion}</span>
                </Grid>
                }
                
                { hiloRespuestas && hiloRespuestas.hilo.map( (item) => {
                    return ( 
                        <Grid key={item.id} xs={12} style={{border: '1px solid grey', padding: 20, borderRadius: 10, marginBottom: 30}}>
                            <Box sx={{ justifyContent: 'flex-end', textAlign: 'right' }}>{item.origen_usuario_nombre}</Box>
                            <Divider style={{marginTop: 5, marginBottom: 5}} />
                            
                            <span style={{whiteSpace: 'pre-line'}}>{item.descripcion}</span>
                            <Divider style={{marginTop: 5, marginBottom: 5}} />
                            <Box sx={{ justifyContent: 'flex-end' }}>{item.created}</Box>
                        </Grid> 
                    )
                })}
                {/* { hilo.hilo.map( (item) => {
                    return (
                        <Grid xs={12} style={{border: '1px solid grey', padding: 20, borderRadius: 10, marginBottom: 30}}>
                            <span style={{whiteSpace: 'pre-line'}}>{item.descripcion}</span>
                        </Grid>
                    )
                })} */}
                
                <Grid xs={12} style={{border: '1px solid grey', padding: 20, borderRadius: 10,}}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        // error={errorDescripcion}
                    >
                        <TextField
                            autoFocus
                            id="descripcion"
                            type="text"
                            fullWidth
                            variant="standard"
                            label="Texto de respuesta"
                            multiline
                            minRows={5}
                            value={descripcion}
                            onChange={ (value) => { setDescripcion(value.target.value)} }
                        />

                        { errorDescripcion !== '' && <FormHelperText style={{color: 'red'}}>Es necesario escribir una descripción</FormHelperText> }

                    </FormControl>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSendRespuesta()}>Responder</Button>
                <Button onClick={() => handleCerrarDialorNotificacion()}>Cancelar</Button>
            </DialogActions>
        </Dialog>
  )
}

export default DialogNotificacionView
