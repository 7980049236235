import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { getZooms, update_exposicion_oral_entrada_manual, update_exposicion_oral_url_grabacion } from '../../../services/alumnos'
import { getTutores } from '../../../services/tutores'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { useFormik } from "formik";
import * as yup from 'yup';
import { grey } from '@material-ui/core/colors'

const DialogExposicionUrlGrabacion = ({exposicionOral, openModal, handleCerrarDialogNotificacion}) => {
    // const [tutores, setTutores] = useState([])
    // const [zooms, setZooms] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
        formik.setValues({
            url_grabacion: "",
        })
    }, [])

    useEffect(() => {
        formik.setValues({
            url_grabacion: exposicionOral.url_grabacion,
        })
    }, [exposicionOral])
    

    const formik = useFormik({
        initialValues: {
            url_grabacion: "",
        },
        validationSchema: yup.object({
            url_grabacion: yup.string().required("URL obligatoria"),
          }),
        onSubmit: (values) => {
            update_exposicion_oral_url_grabacion({
                usuario_id: usuarioSeleccionado.id, 
                exposicion_oral_id: exposicionOral.id,
                url_grabacion: values.url_grabacion,
            }).then( (response) => {
                handleCerrarDialogNotificacion(false)
            })
        },
    });

    return (
      <Dialog
        open={openModal}
        onClose={() => handleCerrarDialogNotificacion(false)}
        maxWidth={"md"}
      >
        <form
          noValidate
          method="POST"
          enctype="multipart/form-data"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle>Exposición oral - URL grabación</DialogTitle>
          <DialogContent style={{ width: "700px" }}>
            <Box
              style={{
                border: "1px solid",
                borderColor: grey[300],
                marginBottom: 15,
                padding: 15,
                borderRadius: 10,
              }}
            >
              <Typography variant="body1">
                <b>Alumno: </b>
                {exposicionOral.alumno_nombre}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Tipo: </b>
                {exposicionOral.oposicion_tipo}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Reembolsable: </b>
                {exposicionOral.producto_es_reembolsable === "1" ? "SI" : "NO"}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Sede: </b>
                {exposicionOral.sede_nombre}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Modalidad: </b>
                {exposicionOral.modalidad}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Grupo: </b>
                {exposicionOral.grupo_nombre}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Comunidad: </b>
                {exposicionOral.comunidad_autonoma_nombre}
                <br />
              </Typography>
            </Box>

            <Grid item sm={12}>
              <TextField
                id="url_grabacion"
                name="url_grabacion"
                label="URL Grabacion"
                placeholder="Introduce la URL"
                fullWidth
                margin="normal"
                value={formik.values.url_grabacion}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.url_grabacion && Boolean(formik.errors.url_grabacion)
                }
                helperText={formik.touched.url_grabacion && formik.errors.url_grabacion}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginRight: 15 }}>
            <Button onClick={() => handleCerrarDialogNotificacion(false)}>
              Cancelar
            </Button>
            {/* <Button onClick={ () => { console.log(formik.values) }} variant="outlined"> */}
            <Button type="submit" variant="outlined">
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
}

export default DialogExposicionUrlGrabacion
