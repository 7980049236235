import { Box, Chip, Grid, IconButton, Menu, MenuItem, Select, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DataGridPro, useGridApiContext } from '@mui/x-data-grid-pro';
import { amber, blue, green, red } from '@material-ui/core/colors';
import moment from 'moment';
import Moment from 'react-moment';
import StatusTicket from '../../../components/StatusTicket';
import { BASE_URL_ZENDESK } from '../../../constants';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { actualizarTutoriaEsBienvenida, actualizarTutoriaEstadoTutor, delete_alumno_entrega, forzar_envio, getAlumnoTutoriasIndividuales, getAlumnosEntregas, getTutoriasIndividuales, importar_alumno_entregas_plantilla } from '../../../services/alumnos';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { DialogEntregaForm } from './DialogEntregaForm';
import { DialogEntregaCambiarFechasForm } from './DialogEntregaCambiarFechasForm';
import { getListaDetalle } from '../../../services/lista_detalle';

const AlumnoFormTutorias = ( { alumno_id } ) => {
    const [anchorElOpciones, setAnchorElOpciones] = React.useState(null);
    const open = Boolean(anchorElOpciones);
    const [tutorias, setTutorias] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalUpdateFechas, setOpenModalUpdateFechas] = useState(false)
    const [estadosTutor, setEstadosTutor] = React.useState(undefined)

    useEffect(() => {
        
        setLoading(true);
        cargarEntregas()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alumno_id])
    
    const cargarEntregas = () => {
        const usuario_id = usuarioSeleccionado.id

        alumno_id && getAlumnoTutoriasIndividuales({alumno_id, usuario_id}).then( (response) => {

            response.tutorias && setTutorias(response.tutorias)

            setLoading(false)
        })

        getListaDetalle({usuario_id: usuario_id, lista_id: 17}).then( (response) => {
            response.lista_detalle && setEstadosTutor(response.lista_detalle)
          })
    }

    // const handleClickOpciones = (event) => {
    //     setAnchorElOpciones(event.currentTarget);
    // };
    
    // const handleCloseOpciones = () => {
    //     setAnchorElOpciones(null);
    // };

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    // const handleDoubleClick = (row) => {

    //     if( row.fecha_envio !== "0000-00-00 00:00:00" ) {
    //         Swal.fire('No se puede modificar', 'Este simulacro ya tiene un envio realizado', 'info')
    //         return null
    //     }
    //     if( row.plantilla_linea_id !== "0" ) {
    //         Swal.fire('No se puede modificar', 'Este simulacro pertenece a una plantilla', 'info')
    //         return null
    //     }

    //     // setSimulacroDoubleClick(row)
    //     setOpenModalCreate(true)
        
    // }

    function SelectEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();
      
        const handleChange = async (event) => {
          await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
          apiRef.current.stopCellEditMode({ id, field });
  
          if(field === 'estado_tutor') {
            actualizarTutoriaEstadoTutor({usuario_id: usuarioSeleccionado.id, tutoria_id: id, estado: event.target.value}).then( response => {
              
                alumno_id && getAlumnoTutoriasIndividuales({alumno_id: alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {

                    response.tutorias && setTutorias(response.tutorias)
        
                    setLoading(false)
                })
  
            })
          }

          if(field === 'es_bienvenida') {
            actualizarTutoriaEsBienvenida({usuario_id: usuarioSeleccionado.id, tutoria_id: id, es_bienvenida: event.target.value}).then( response => {
              
              alumno_id && getAlumnoTutoriasIndividuales({alumno_id: alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {

                response.tutorias && setTutorias(response.tutorias)
    
                setLoading(false)
            })
  
            })
          }

        };
      
        return (
          <>
        {field === 'estado_tutor' && 
          <Select
            sx={{ height: 1 }}
            fullWidth
            value={value}
            onChange={handleChange}
          >
            {        
                estadosTutor && estadosTutor.map( (item) => {
                    return (<MenuItem key={item.id} value={item.clave}>{item.nombre}</MenuItem>)
                })
            }

        </Select>}
        
        {field === 'es_bienvenida' && 
          <Select
            sx={{ height: 1 }}
            fullWidth
            value={value}
            onChange={handleChange}
          >
            <MenuItem key={1} value={'1'}>{'Si'}</MenuItem>
            <MenuItem key={2} value={'0'}>{'No'}</MenuItem>
        </Select>}

      </>
        )}

      const renderSelectEditInputCell = (params) => {
        return <SelectEditInputCell {...params} />;
      };

    const columnas_tutorias = 
    [
      { field: 'id', headerName: 'ID', minWidth: 10 },
      
      { field: 'start_time', headerName: 'Inicio',minWidth: 170},
      { field: 'end_time', headerName: 'Fin',minWidth: 170 },
      { 
        field: 'estado', 
        headerName: 'Estado Calendly',
        minWidth: 130, 
        renderCell: (celda) => {
          return (
            <>
              { celda.row.estado === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
              { celda.row.estado === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
              { celda.row.estado === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
              { celda.row.estado === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
            </>
          )
        }
      },
      { 
        field: 'estado_tutor', 
        headerName: 'Validación tutor', 
        maxWidth: 250, 
        flex: 1, 
        editable: true,
        type: 'singleSelect',
        
        renderEditCell: renderSelectEditInputCell,
        renderCell: (celda) => {
          return (
            <>
              { celda.row.estado_tutor === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
              { celda.row.estado_tutor === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
              { celda.row.estado_tutor === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
              { celda.row.estado_tutor === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
            </>
          )
        }
    },
    { 
      field: 'es_bienvenida', 
      headerName: '¿Sesion bienvenida?', 
      maxWidth: 250, 
      flex: 1, 
      editable: true,
      type: 'singleSelect',
      
      renderEditCell: renderSelectEditInputCell,
      renderCell: (celda) => {
        return (
          <>
            {celda.row.es_bienvenida === '1' ? 'Si' : 'No' }
          </>
        )
      }
  },
      { field: 'zoom_link', headerName: 'Enlace Zoom',minWidth: 400},
      { field: 'zoom_password', headerName: 'Password Zoom',minWidth: 220 },
      { 
        field: 'canceler_type', 
        headerName: 'Canceldo por',
        minWidth: 220,
        renderCell: (celda) => {
          if(celda.row.canceler_type === '') {
            return ''
          }

          return (
            celda.row.canceler_type === 'host' ? 'Tutor' : 'Alumno'
          )
        }
      },
      { field: 'question', headerName: 'Pregunta',minWidth: 220, flex: 1 },
    ]

    /* -------------------------------------------------------------------------- */
    /*                              handleForzarEnvio                             */
    /* -------------------------------------------------------------------------- */
    // const handleCambiarFechas = (row) => {
    //     setSimulacroDoubleClick(row)
    //     setOpenModalUpdateFechas(true)
    // }
    // const handleForzarEnvio = (entrega_id) => {
    //     Swal.fire({
    //         title: '¿Realmente deseas forzar el envio de la entrega?',
    //         showCancelButton: true,
    //         confirmButtonText: 'Enviar',
    //         cancelButtonText: `Cancelar`,
    //         confirmButtonColor: '#afcb0d',
    //         cancelButtonColor: '#a5a5a5',
    //         icon: 'warning',
    //         reverseButtons: true
    //       }).then((result) => {

    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
                
    //             forzar_envio({usuario_id: usuarioSeleccionado.id, entrega_id: entrega_id}).then( (response) => {

    //                 getAlumnosEntregas({alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {
        
    //                     response.entregas && setEntregas(response.entregas)
                        
    //                 })
        
    //             })
        
    //         } else if (result.isDenied) {
    //         // //   Swal.fire('Changes are not saved', '', 'info')
    //         }
    //       })

    // }
    
    // const handleDeleteEntrega = (row) => {
    //     // No se puede eliminar una entrega si viene de una plantilla o si ya tiene un envio realizado
    //     if( row.fecha_envio !== "0000-00-00 00:00:00" ) {
    //         Swal.fire('No se puede eliminar', 'Esta entrega ya tiene un envio realizado', 'info')
    //         return null
    //     }
    //     if( row.plantilla_linea_id !== "0" ) {
    //         Swal.fire('No se puede eliminar', 'Esta entrega pertenece a una plantilla', 'info')
    //         return null
    //     }

    //     Swal.fire({
    //         title: '¿Realmente deseas eliminar la entrega?',
    //         showCancelButton: true,
    //         confirmButtonText: 'Eliminar',
    //         cancelButtonText: `Cancelar`,
    //         confirmButtonColor: '#afcb0d',
    //         cancelButtonColor: '#a5a5a5',
    //         icon: 'warning',
    //         reverseButtons: true
    //       }).then((result) => {

    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
                
    //             delete_alumno_entrega({usuario_id: usuarioSeleccionado.id, entrega_id: row.id}).then( (response) => {

    //                 getAlumnosEntregas({alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {
        
    //                     response.entregas && setEntregas(response.entregas)
                        
    //                 })
        
    //             })
        
    //         } else if (result.isDenied) {
    //         // //   Swal.fire('Changes are not saved', '', 'info')
    //         }
    //       })

    // }
    // const handleImportarEntregasGrupo = () => {
    //     Swal.fire({
    //         title: '¿Realmente deseas importar las entregas y los simulacros del grupo?',
    //         showCancelButton: true,
    //         confirmButtonText: 'Importar',
    //         cancelButtonText: `Cancelar`,
    //         confirmButtonColor: '#afcb0d',
    //         cancelButtonColor: '#a5a5a5',
    //         icon: 'warning',
    //         reverseButtons: true
    //       }).then((result) => {

    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
                
    //             importar_alumno_entregas_plantilla({usuario_id: usuarioSeleccionado.id, alumno_id: alumno_id}).then( (response) => {

    //                 getAlumnosEntregas({alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {
        
    //                     response.simulacros && setSimulacros(response.simulacros)
                        
    //                 })
        
    //             })
        
    //         } else if (result.isDenied) {
    //         // //   Swal.fire('Changes are not saved', '', 'info')
    //         }
    //       })
    // }
  return (
    <>
        <Grid container spacing={2} style={{marginBottom: 10}}>
            <Grid item xs={12} md={12}>
                <Typography display="inline" variant='h6'>Tutorías</Typography>
            </Grid>
        </Grid>  

        <Grid container  spacing={2}>

            <div style={{ height: 500, width: '100%', padding:"0 5px" }}>

                {tutorias && <DataGridPro 
                    rows={tutorias} 
                    columns={columnas_tutorias} 
                    pagination={false}
                    // onRowDoubleClick={ (row) => { handleDoubleClick(row.row) } }
                />}

            </div>
                                    
        </Grid>
        <Grid container  spacing={2} style={{marginTop: 20, marginLeft: 5}}>
            <Box mr={5}><Chip size="small" style={{backgroundColor: red[500], color: 'white', marginRight: 10}} label={"Abierto"} /> Pendiente de resolución por parte del tutor</Box>
            <Box mr={5}><Chip size="small" style={{backgroundColor: blue[500], color: 'white', marginRight: 10}} label={"Pendiente"} /> Espera respuesta del alumno</Box>
            <Box mr={5}><Chip size="small" style={{backgroundColor: green[500], color: 'white', marginRight: 10}} label={"Resuelto"} /> Entrega resuelta</Box>
        </Grid>

        {/* <DialogEntregaForm 
            openModal={openModalCreate}
            handleOpenModal={ () => {
                setOpenModalCreate(false);
            }}
            alumnoID={alumno_id}
            entrega={simulacroDoubleClick}
            setEntregas={setSimulacros}
        /> */}
        
        {/* <DialogEntregaCambiarFechasForm 
            openModal={openModalUpdateFechas}
            handleOpenModal={ () => {
                setOpenModalUpdateFechas(false);
            }}
            alumnoID={alumno_id}
            entrega={entregaDoubleClick}
            setEntregas={setEntregas}
        /> */}


    </>
  )
}

export default AlumnoFormTutorias
