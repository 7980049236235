import { AppBar, Box, Chip, Divider, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import TemplateDashboard from "../../../components/TemplateDashboard";
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getAlumno, } from '../../../services/alumnos';
import red from '@material-ui/core/colors/red';
import LoadingComponent from '../../../components/Loading';
import AlertNotificaciones from '../../../components/AlertNotificaciones/AlertNotificaciones';
import AlumnoFormNotas from './AlumnoFormNotas';
import AlumnoFormEntregas from './AlumnoFormEntregas';
import AlumnoFormDatosAlumno from './AlumnoFormDatosAlumno';
import AlumnoFormTickets from './AlumnoFormTickets';
import AlumnoFormMensajesDirectos from './AlumnoFormMensajesDirectos';
import AlumnoFormSimulacros from './AlumnoFormSimulacros';
import AlumnoFormTutorias from './AlumnoFormTutorias';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginTop: 90
//         // display: 'flex',
//         // flexWrap: 'wrap',
//     },
//     textField: {
//         // marginLeft: theme.spacing(1),
//         // marginRight: theme.spacing(1),
//     },
//     keyboardDatePicker: {
//         // marginLeft: theme.spacing(1),
//         // marginRight: theme.spacing(1),
//     },
//     formulario: {
//         margin: 8
//     },
//     input: {
//         display: 'none'
//     },
//     chipCancelado: {
//         backgroundColor: red[100]
//     }
// }));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */
// const validationSchema = yup.object({

//     nombre: yup.string().required("Nombre es requerido"),
//     // correo_electronico: yup.string().email().required("Correo es requerido"),
//     comercial_id: yup.number().required("Comercial es requerido"),
//     temperatura_id: yup.number().required("Temperatura es requerido"),
//     // estado_id: yup.number().required("Temperatura es requerido"),
//     como_conocido_id: yup.number().required("Como nos ha conocido es requerido"),
//     comunidad_autonoma_id: yup.number().required("Comunidad es requerida"),
//     oposicion_id: yup.number().required("Oposicion es requerida"),
//     especialidad_id: yup.number().required("Especialidad es requerida"),
    
// });


const AlumnoForm = () => {
//     // const classes = useStyles();
    const {id: alumno_id} = useParams()

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("Cargando ficha del alumno")
    const [alumno, setAlumno] = useState(undefined)
    const [tabSeleccionada, setTabSeleccionada] = React.useState(0);


    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        
        setLoading(true);

        cargarAlumno()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alumno_id])
    
    const cargarAlumno = () => {
        getAlumno({alumno_id: alumno_id, usuario_id: usuarioSeleccionado.id}).then( (response) => {
            if(response.alumno) {
                if (response.alumno.id === '7765') {
                    response.alumno.es_alumno_fast = '0'
                }
            }
            response.alumno && setAlumno(response.alumno)
            setLoading(false)
        })
    }
    
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }

      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const handleChangeTab = (event, newValue) => {
        setTabSeleccionada(newValue);
      };
    
    return (
        <>

            {

                loading === false

                ?

                <TemplateDashboard showBack={true}>

                    <AlertNotificaciones />

                    <Box sx={{marginX: 2, marginTop: 0, marginBottom: 12}}>
                        <Paper style={{padding: 20}}>
                            <Box mb={2}>
                                <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                                    Ficha del alumno {alumno?.es_alumno_fast === '1' && <Chip size="small" style={{backgroundColor: red[500], color: 'white', marginRight: 10}} label={"Preparación FAST"} />}
                                </Typography>
                                <Divider />
                            </Box>
                    
                            <>
                                <AlumnoFormDatosAlumno alumno={alumno} />


                                <AppBar position="static" style={{marginTop:20}}>
                                    <Tabs value={tabSeleccionada} onChange={handleChangeTab} aria-label="simple tabs example">
                                        <Tab label="Notas" {...a11yProps(0)} />
                                        <Tab label="Dudas" {...a11yProps(1)} />
                                        { alumno?.es_alumno_fast === '0' && <Tab label="Entregas" {...a11yProps(2)} /> }
                                        { alumno?.es_alumno_fast === '0' && <Tab label="Entregas extraordinarias" {...a11yProps(3)} /> }
                                        { alumno?.es_alumno_fast === '0' && <Tab label="Simulacros" {...a11yProps(4)} /> }
                                        { alumno?.es_alumno_fast === '0' && <Tab label="Mensajes directos enviados" {...a11yProps(5)} /> }
                                        { alumno?.es_alumno_fast === '0' && <Tab label="Tutorías" {...a11yProps(6)} /> }
                                    </Tabs>
                                </AppBar>
                                
                                {/* Notas */}
                                <TabPanel value={tabSeleccionada} index={0}>
                                
                                    <AlumnoFormNotas alumnoID={alumno?.id} />
                                
                                </TabPanel>

                                {/* Dudas */}
                                <TabPanel value={tabSeleccionada} index={1}>
                                    
                                    <AlumnoFormTickets alumno_id={alumno_id} />

                                </TabPanel>

                                {/* Entregas */}
                                <TabPanel value={tabSeleccionada} index={2}>

                                    <AlumnoFormEntregas 
                                        alumno_id={alumno_id}
                                        tipo_id={139}
                                    />

                                </TabPanel>

                                {/* Entregas extraordinarias*/}
                                <TabPanel value={tabSeleccionada} index={3}>

                                    <AlumnoFormEntregas 
                                        alumno_id={alumno_id}
                                        tipo_id={177}
                                    />

                                </TabPanel>

                                {/* Simulacros */}
                                <TabPanel value={tabSeleccionada} index={4}>
                                    
                                    <AlumnoFormSimulacros 
                                        alumno_id={alumno_id}
                                        alumno={alumno}
                                    />

                                </TabPanel>

                                
                                
                                {/* Mensajes directos enviados */}
                                <TabPanel value={tabSeleccionada} index={5}>
                                
                                    <AlumnoFormMensajesDirectos alumno_id={alumno_id} />

                                </TabPanel>
                                
                                
                                
                                {/* Mensajes directos enviados */}
                                <TabPanel value={tabSeleccionada} index={6}>
                                
                                    <AlumnoFormTutorias
                                        alumno_id={alumno_id}
                                    />

                                </TabPanel>


                            </>
                                
                        </Paper>

                    </Box>
                
                    

                </TemplateDashboard>

                :

                <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
            
            }

        </>
    )
};

export default AlumnoForm;