import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { selectUsuarioSeleccionado } from '../../../../redux/reducer/loginReducer'
import { enviarDifusion } from '../../../../services/alumnos'

const DialogDifusionForm = ({plantillaId, openModal, handleCerrarDialorNotificacion}) => {
    const [destinosSeleccionados, setDestinosSeleccionados] = useState([])
    
    const [asunto, setAsunto] = useState('')
    const [descripcion, setDescripcion] = useState('')
    
    const [errorAsunto, setErrorAsunto] = useState('')
    const [errorDescripcion, setErrorDescripcion] = useState('')

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const handleCreateNotificacion = () => {
        if( asunto === '' ) {
            setErrorAsunto("Introduce un asunto")
        } else {
            setErrorAsunto('')
        }

        if( descripcion === '' ) {
            setErrorDescripcion("Introduce una descripcion")
        } else {
            setErrorDescripcion('')
        }

        if( asunto !== '' && descripcion !== '' ) {
            enviarDifusion({usuario_id: usuarioSeleccionado.id, plantilla_id: plantillaId, asunto: asunto, descripcion: descripcion}).then( (response) => {
              handleCerrarDialorNotificacion()
            })
        }

    }

    return (
        <Dialog open={openModal} onClose={() => handleCerrarDialorNotificacion() } maxWidth={'md'}>
            <DialogTitle>Nueva notificación</DialogTitle>
            <DialogContent style={{width: '700px'}}>
            
                <Grid xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        // error={errorDescripcion}
                    >
                        <TextField
                            autoFocus
                            id="asunto"
                            type="text"
                            fullWidth
                            variant="standard"
                            label="Asunto"
                            value={asunto}
                            onChange={ (value) => { setAsunto(value.target.value)} }
                        />

                        { errorAsunto !== '' && <FormHelperText style={{color: 'red'}}>Es necesario escribir un asunto</FormHelperText> }

                    </FormControl>
                </Grid>
                <Grid xs={12} style={{marginTop: 20}}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                    >
                        <TextField
                            
                            id="descripcion"
                            type="text"
                            fullWidth
                            variant="standard"
                            label="Texto de la notificación"
                            multiline
                            minRows={5}
                            value={descripcion}
                            onChange={ (value) => { setDescripcion(value.target.value)} }
                        />

                        { errorDescripcion !== '' && <FormHelperText style={{color: 'red'}}>Es necesario escribir una descripción</FormHelperText> }

                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCerrarDialorNotificacion()}>Cancelar</Button>
                <Button onClick={() => handleCreateNotificacion()}>Crear difusión</Button>
            </DialogActions>
        </Dialog>
  )
}

export default DialogDifusionForm
