import { Chip } from '@material-ui/core'
import { amber, blue, green, grey, purple, red } from '@material-ui/core/colors'
import React from 'react'

const StatusEntregasAlumno = ({num_tickets_resueltos, num_tickets_abiertos, num_tickets_pendientes, num_tickets_retrasados, num_tickets_ignorados}) => {
    return (
        <>
            { num_tickets_resueltos > 0 ? <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={`Res: ${num_tickets_resueltos}`} /> : <></> }
            { num_tickets_abiertos > 0 ? <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={`Abi: ${num_tickets_abiertos}`} /> : <></> }
            { num_tickets_pendientes > 0 ? <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={`Pen: ${num_tickets_pendientes}`} /> : <></> }
            { num_tickets_ignorados > 0 ? <Chip size="small" style={{backgroundColor: purple[500], color: 'white'}} label={`Ign: ${num_tickets_ignorados}`} /> : <></> }
            { num_tickets_retrasados > 0 ? <Chip size="small" style={{backgroundColor: amber[500], color: 'white'}} label={`Ret: ${num_tickets_retrasados}`} /> : <></> }
            { num_tickets_pendientes === 0 && num_tickets_retrasados === 0 ?<Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Al día"} /> : <></> }
        </>
    )
  
}

export default StatusEntregasAlumno
