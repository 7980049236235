import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import AlertNotificaciones from '../../components/AlertNotificaciones/AlertNotificaciones';
import TutoriasGrupalesEnlacesContent from './components/TutoriasGrupalesEnlacesContent';

const TutoriasGrupalesEnlaces = () => {

    return (
        <>

            <TemplateDashboard>
                <AlertNotificaciones />
                <TutoriasGrupalesEnlacesContent />
            </TemplateDashboard>

        </>
    )
};

export default TutoriasGrupalesEnlaces;