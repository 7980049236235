import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, Typography } from "@material-ui/core";
// import { GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { getEntregasPlantillasByTutor } from "../../../../services/alumnos";
import { Stack } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DataGridInnovattio from "../../../../components/DataGridInnovatiio";
import QuickSearchToolbar from "../../../../components/QuickSearchToolbar";
import { requestSearch } from "../../../../utils/regExp";
import { DialogPlantillaForm } from "./DialogPlantillaForm";
import SendIcon from "@mui/icons-material/Send";
import DialogDifusionForm from "./DialogDifusionForm";
import { DialogEntregaForm } from "./DialogEntregaForm";

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
  emptyImage: {
    width: "100%",
  },

  enlace: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
}));

export default function PlantillasEntregasContent() {
  const classes = useStyles();

  const [plantillas, setPlantillas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [plantillaSeleccionadaId, setPlantillaSeleccionadaId] = useState("");
  const [openDialogoDifusion, setOpenDialogoDifusion] = useState(false);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const history = useHistory();

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando plantillas");

    cargarPlantillas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                              Cargar Plantillas                             */
  /* -------------------------------------------------------------------------- */
  const cargarPlantillas = () => {
    const usuario_id = usuarioSeleccionado.id;

    if (
      usuarioSeleccionado.perfil_id === "11" ||
      usuarioSeleccionado.perfil_id === "12" ||
      usuarioSeleccionado.perfil_id === "13"
    ) {
      getEntregasPlantillasByTutor({ tutor_id: "-1" }).then((response) => {
        setLoading(false);

        response && setPlantillas(response.plantillas);
      });
    } else {
      getEntregasPlantillasByTutor({ tutor_id: usuario_id }).then(
        (response) => {
          setLoading(false);

          response && setPlantillas(response.plantillas);
        }
      );
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */

  const columnas = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "descripcion", headerName: "Descripción", flex: 1, minWidth: 370 },
    { field: "tutor_usuario_nombre", headerName: "Tutor", minWidth: 250 },
    {
      field: "comunidad_autonoma_nombre",
      headerName: "Comunidad",
      minWidth: 250,
    },
    {
      field: "es_reposicion",
      headerName: "Reposicion",
      minWidth: 250,
      renderCell: (params) => {
        return <div>{params.value === "1" ? "Si" : "No"}</div>;
      },
    },
    {
      field: "es_estabilizacion",
      headerName: "Estabilizacion",
      minWidth: 250,
      renderCell: (params) => {
        return <div>{params.value === "1" ? "Si" : "No"}</div>;
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<SendIcon />}
          label="Enviar difusión"
          onClick={() => enviarDifusion(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  /* -------------------------------------------------------------------------- */
  const enviarDifusion = (id) => {
    Swal.fire({
      title: "¿Realmente deseas enviar una difusión a este grupo?",
      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#afcb0d",
      cancelButtonColor: "#a5a5a5",
      icon: "warning",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setPlantillaSeleccionadaId(id);
        setOpenDialogoDifusion(true);
      } else if (result.isDenied) {
        //         //   Swal.fire('Changes are not saved', '', 'info')
      }
    });
  };
  const handleCerrarModalDifusion = () => {
    setOpenDialogoDifusion(false);
  };
  const handleEnviarDifusion = (id) => {
    setPlantillaSeleccionadaId(id);
    setOpenDialogoDifusion(true);
  };

  /* -------------------------------------------------------------------------- */
  /*                         handleAlumnoEntregaDetalle                         */
  /* -------------------------------------------------------------------------- */
  const handleAlumnoEntregaDetalle = (id) => {
    history.push("/plantillas_entregas/detalle/" + id);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */

  const Contenido = () => {
    const [rows, setRows] = React.useState(plantillas);
    const [searchText, setSearchText] = React.useState("");
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [openModalCreateEntrega, setOpenModalCreateEntrega] = useState(false);

    const handleOpenModalCreate = (valor) => {
      setOpenModalCreate(valor);
    };

    const handleOpenModalCreateEntrega = (valor) => {
      setOpenModalCreateEntrega(valor);
    };

    return (
      <>
        <Stack direction={"row"} spacing={2}>
          <Box mt={0} mb={0} mx={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Grupos de alumnos
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpenModalCreate(true)}
          >
            Añadir grupo
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpenModalCreateEntrega(true)}
          >
            Añadir entrega
          </Button>
          <a
            href="https://gestion.innovatiio.com/api/procesos/tutor_enviar_entregas"
            target="_blank"
            rel="noreferrer"
          >
            Forzar envío de entregas
          </a>
        </Stack>

        <Box>
          <div style={{ height: 700, width: "100%", padding: "0 15px" }}>
            <DataGridInnovattio
              rows={rows}
              columns={columnas}
              rowHeight={30}
              components={{ Toolbar: QuickSearchToolbar }}
              onDoubleClick={(evento) => handleAlumnoEntregaDetalle(evento.id)}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) =>
                    requestSearch(
                      plantillas,
                      setSearchText,
                      setRows,
                      event.target.value
                    ),
                  clearSearch: () =>
                    requestSearch(plantillas, setSearchText, setRows, ""),
                },
              }}
            />

            <DialogPlantillaForm
              openModal={openModalCreate}
              setOpenModal={setOpenModalCreate}
              cargarPlantillas={cargarPlantillas}
            />

            <DialogDifusionForm
              plantillaId={plantillaSeleccionadaId}
              openModal={openDialogoDifusion}
              handleCerrarDialorNotificacion={handleCerrarModalDifusion}
            />

            <DialogEntregaForm
              openModal={openModalCreateEntrega}
              handleOpenModal={setOpenModalCreateEntrega}
            />
          </div>
        </Box>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
