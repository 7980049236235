import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import CoordinacionContent from './components/CoordinacionContent';
import AlertNotificaciones from '../../components/AlertNotificaciones/AlertNotificaciones';

const Coordinacion = () => {

    return (
        <>

            <TemplateDashboard>
                <AlertNotificaciones />
                <CoordinacionContent />
            </TemplateDashboard>

        </>
    )
};

export default Coordinacion;