import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { getZooms, update_exposicion_oral_entrada_manual } from '../../../services/alumnos'
import { getTutores } from '../../../services/tutores'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { useFormik } from "formik";
import * as yup from 'yup';
import { grey } from '@material-ui/core/colors'

const DialogExposicionOralEntradaManual = ({exposicionOral, openModal, handleCerrarDialogNotificacion}) => {
    const [tutores, setTutores] = useState([])
    const [zooms, setZooms] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
        getTutores({usuario_id: usuarioSeleccionado.id}).then( (response) => {
            response.tutores && setTutores(response.tutores)
        })

        getZooms({usuario_id: usuarioSeleccionado.id}).then( (response) => {
            setZooms(response.zooms)
        })

        formik.setValues({
            tutor_id: "",
            fecha: null,
            zoom_mail: "",
            zoom_link: "",
            zoom_password: "",
            observaciones: "",
        })
    }, [])

    useEffect(() => {
        console.log(exposicionOral)
        formik.setValues({
            tutor_id: exposicionOral.exposicion_tutor_id,
            fecha: exposicionOral.fecha_exposicion,
            zoom_mail: exposicionOral.zoom_mail,
            zoom_link: exposicionOral.zoom_link,
            zoom_password: exposicionOral.zoom_password,
            observaciones: exposicionOral.observaciones_exposicion,
        })
    }, [exposicionOral])
    

    const formik = useFormik({
        initialValues: {
            tutor_id: null,
            fecha: null,
            zoom_mail: null,
            zoom_link: "",
            zoom_password: "",
            observaciones: "",
        },
        validationSchema: yup.object({
            // zoom_mail: yup.string().required("Sala obligatoria"),
            tutor_id: yup.string().required("Tutor obligatorio"),
            // fecha: yup.string().nullable().required("Fecha obligatoria"),
          }),
        onSubmit: (values) => {
            update_exposicion_oral_entrada_manual({
                usuario_id: usuarioSeleccionado.id, 
                exposicion_oral_id: exposicionOral.id,
                tutor_id: values.tutor_id,
                fecha: values.fecha,
                zoom_mail: values.zoom_mail,
                zoom_link: values.zoom_link,
                zoom_password: values.zoom_password,
                observaciones: values.observaciones,
            }).then( (response) => {
                handleCerrarDialogNotificacion(false)
            })
        },
    });

    return (
      <Dialog
        open={openModal}
        onClose={() => handleCerrarDialogNotificacion(false)}
        maxWidth={"md"}
      >
        <form
          noValidate
          method="POST"
          enctype="multipart/form-data"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle>Exposición oral (Asignación manual)</DialogTitle>
          <DialogContent style={{ width: "700px" }}>
            <Box
              style={{
                border: "1px solid",
                borderColor: grey[300],
                marginBottom: 15,
                padding: 15,
                borderRadius: 10,
              }}
            >
              <Typography variant="body1">
                <b>Alumno: </b>
                {exposicionOral.alumno_nombre}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Tipo: </b>
                {exposicionOral.oposicion_tipo}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Reembolsable: </b>
                {exposicionOral.producto_es_reembolsable === "1" ? "SI" : "NO"}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Sede: </b>
                {exposicionOral.sede_nombre}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Modalidad: </b>
                {exposicionOral.modalidad}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Grupo: </b>
                {exposicionOral.grupo_nombre}
                <br />
              </Typography>
              <Typography variant="body1">
                <b>Comunidad: </b>
                {exposicionOral.comunidad_autonoma_nombre}
                <br />
              </Typography>
            </Box>

            <Grid item sm={12}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: 16 }}
                error={
                  formik.touched.zoom_mail && Boolean(formik.errors.zoom_mail)
                }
              >
                <InputLabel id="zoom_mail-label">Sala</InputLabel>
                <Select
                  labelId="zoom_mail-label"
                  id="zoom_mail"
                  name="zoom_mail"
                  label="Sala"
                  fullWidth
                  value={formik.values.zoom_mail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.zoom_mail && Boolean(formik.errors.zoom_mail)
                  }
                >
                  {zooms &&
                    zooms.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.mail}>
                          {item.nombre} - {item.mail}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.zoom_mail && formik.errors.zoom_mail}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{}}>
              <FormControl style={{ marginTop: 16 }} variant="outlined" fullWidth>
                <InputLabel id="tutor_id-label">Tutor</InputLabel>

                <Select
                  labelId="tutor_id-label"
                  id="tutor_id"
                  name="tutor_id"
                  label="Tutor"
                  fullWidth
                  value={formik.values.tutor_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.tutor_id && Boolean(formik.errors.tutor_id)
                  }
                >
                  {tutores &&
                    tutores.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre} {item.apellidos}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.tutor_id && formik.errors.tutor_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={12} style={{ marginTop: 16 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDateTimePicker
                  fullWidth
                  autoOk={true}
                  id="fecha"
                  label="Fecha"
                  placeholder="Introduce la fecha"
                  inputVariant="outlined"
                  format="dd/MM/yyyy HH:mm"
                  value={formik.values.fecha}
                  onChange={(value) => formik.setFieldValue("fecha", value)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={formik.touched.fecha && Boolean(formik.errors.fecha)}
                  helperText={formik.touched.fecha && formik.errors.fecha}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="zoom_link"
                name="zoom_link"
                label="Enlace de zomm"
                placeholder="Introduce el enlace de zoom"
                fullWidth
                margin="normal"
                value={formik.values.zoom_link}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.zoom_link && Boolean(formik.errors.zoom_link)
                }
                helperText={formik.touched.zoom_link && formik.errors.zoom_link}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="zoom_password"
                name="zoom_password"
                label="Zoom password"
                placeholder="Introduce el password de zoom"
                fullWidth
                margin="normal"
                value={formik.values.zoom_password}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.zoom_password &&
                  Boolean(formik.errors.zoom_password)
                }
                helperText={
                  formik.touched.zoom_password && formik.errors.zoom_password
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="observaciones"
                name="observaciones"
                label="Observaciones"
                placeholder="observaciones"
                fullWidth
                margin="normal"
                value={formik.values.observaciones}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.observaciones &&
                  Boolean(formik.errors.observaciones)
                }
                helperText={
                  formik.touched.observaciones && formik.errors.observaciones
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginRight: 15 }}>
            <Button onClick={() => handleCerrarDialogNotificacion(false)}>
              Cancelar
            </Button>
            {/* <Button onClick={ () => { console.log(formik.values) }} variant="outlined"> */}
            <Button type="submit" variant="outlined">
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
}

export default DialogExposicionOralEntradaManual
