import {
    Avatar,
    Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
  } from "@mui/material";
  import { yellow } from "@mui/material/colors";
  import React from "react";
  import AttachFileIcon from "@mui/icons-material/AttachFile";
import { RichTextView } from "../../../components/RichTextField";
import { Box } from "@material-ui/core";
  
  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  
  
  export const ListaConversaciones = ({ conversaciones }) => {
    console.log(conversaciones);
  
    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {conversaciones?.map((item) => {
          return (
            <>
              <ListItem
                alignItems="flex-start"
                sx={{ backgroundColor: item.created_usuario_tipo === 'Alumno' ? yellow[50] : "white" }}
              >
                <ListItemAvatar>
                  <Avatar {...stringAvatar(item.created_usuario_nombre)} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Box>{item.created_usuario_nombre}</Box>
                      <Box>{item.created}</Box>
                    </Stack>}
                  secondary={
                    <React.Fragment>
                      <RichTextView data={item.comentario} />
                      <div className="mt-3">
                        {item.adjunto_nombre !== "" && item.adjunto_nombre !== null ? (
                          <div>
                            {/* <a
                              rel="noreferrer"
                              href={` ${item.adjunto_nombre}`}
                              target="_blank"
                            >
                              <Button variant="text" startIcon={<AttachFileIcon />}>
                                Adjunto
                              </Button>
                            </a> */}
                            <a
                              rel="noreferrer"
                              href={`https://gestion.innovatiio.com/api/v1/alumno/download?origen=${item.adjunto_nombre}&destino=${item.adjunto_nombre_original}`}
                              target="_self"
                            >
                              <Button variant="text" startIcon={<AttachFileIcon />}>
                                Adjunto
                              </Button>
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          );
        })}
      </List>
    );
  };
  