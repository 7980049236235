import React, { useEffect } from "react";
import { useState } from "react";
import LoadingComponent from "../../../components/Loading";
import { Box, Button, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import DialogNotificacionForm from "./DialogNotificacionForm";
import { NotificacionAlumnoService } from "../../../services/NotificacionesAlumnos.service";
import moment from "moment";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import DialogDestinosForm from "./DialogDestinosForm";
import SeleccionarDestino from "./SeleccionarDestino";
// import { useSelector } from 'react-redux';
// import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';

export default function NotificacionesAlumnosContent() {
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [notificaciones, setNotificaciones] = useState([]);
  const [notificacionSelected, setNotificacionSelected] = useState();
  const [openNotificacionModal, setOpenNotificacionModal] = useState(false);
  const [showSelectDestinos, setShowSelectDestinos] = useState(false);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);

    NotificacionAlumnoService.list({ usuario_id: usuarioSeleccionado.id }).then(
      (response) => {
        setLoading(false);
        setNotificaciones(response?.notificaciones);
      }
    );
  }, []);
  useEffect(() => {
    if(openNotificacionModal === false) {
      setLoading(true);

      NotificacionAlumnoService.list({ usuario_id: usuarioSeleccionado.id }).then(
        (response) => {
          setLoading(false);
          setNotificaciones(response?.notificaciones);
        }
      );
    }
  }, [openNotificacionModal]);

  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */

  const columnasNotificaciones = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "tipo_nombre", headerName: "Tipo", minWidth: 150 },
    { field: "asunto", headerName: "Asunto", flex: 1 },
    {
      field: "created",
      width: 200,
      headerName: "Creada",
      description: "Creada",
      type: "date",
      valueFormatter: (params) => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A");
      },
      renderCell: (cellValues) => {
        return cellValues.value !== null &&
          cellValues.value !== "0000-00-00 00:00:00" ? (
          <Moment format="DD/MM/YYYY HH:mm:ss">{cellValues.value}</Moment>
        ) : (
          <></>
        );
      },
    },
    { field: "num_destinos", headerName: "Destinatarios" },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<EditIcon />}
          label="Añadir destinatarios"
          onClick={() => {
            setNotificacionSelected(params.row);
            // setOpenDestinosModal(true);
            setShowSelectDestinos(true);
          }}
          // component={Link}
          // to={`alumnos/detalle/${params.id}`}
          showInMenu
        />,
      ],
    },
  ];

  
  const handleDoubleClidk = (row) => {
    setNotificacionSelected(row);
    setOpenNotificacionModal(true);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */
  
  const Contenido = () => {
    const handleCerrarDialogNotificacion = () => {
      NotificacionAlumnoService.list({ usuario_id: usuarioSeleccionado.id }).then(
        (response) => {
          console.log("Actualiza", response)
          setNotificaciones(response?.notificaciones);
        }
      );
  
      setOpenNotificacionModal(false);
      setNotificacionSelected(undefined)
    };
    
    return (
      <>
        <Stack direction={"row"} spacing={2} sx={{ marginBottom: 2 }}>
          <Box mt={0} mb={0} mx={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Notificaciones de alumnos
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenNotificacionModal(true)}
          >
            Nueva notificación
          </Button>
        </Stack>

        <Box>
          <div
            style={{
              height: 500,
              width: "100%",
              padding: "0 15px",
            }}
          >
            {notificaciones && (
              <DataGridPro
                rows={notificaciones}
                columns={columnasNotificaciones}
                rowHeight={30}
                // getRowHeight={() => 'auto'}
                onRowDoubleClick={(evento) => handleDoubleClidk(evento.row)}
              />
            )}
          </div>
        </Box>

        <DialogNotificacionForm
          openModal={openNotificacionModal}
          handleCerrarDialogNotificacion={handleCerrarDialogNotificacion}
          notificacion={notificacionSelected}
        />
        {/* <DialogDestinosForm
          notificacionId={notificacionSelected?.id}
          openModal={openDestinosModal}
          handleCerrarDialogDestinos={handleCerrarDialogDestinos}
        /> */}
      </>
    );
  };

  const handleCerrarDialogDestinos = () => {
    NotificacionAlumnoService.list({ usuario_id: usuarioSeleccionado.id }).then(
      (response) => {
        setNotificaciones(response?.notificaciones);
      }
    );

    setShowSelectDestinos(false);
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div style={{ marginTop: "80px" }}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <>
          { showSelectDestinos && <SeleccionarDestino notificacionId={notificacionSelected?.id} handleCerrarDialogDestinos={handleCerrarDialogDestinos} />}
          { !showSelectDestinos && <Contenido />}
        </>
      )}
    </div>
  );
}
