import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import AlertNotificaciones from '../../components/AlertNotificaciones/AlertNotificaciones';
import TutoresTutoriasGrupalesContent from './components/TutoresTutoriasGrupalesContent';

const TutoresTutoriasGrupales = () => {

    return (
        <>

            <TemplateDashboard>
                <AlertNotificaciones />
                <TutoresTutoriasGrupalesContent />
            </TemplateDashboard>

        </>
    )
};

export default TutoresTutoriasGrupales;