import axios from "axios";
import { BASE_URL_SERVICE } from "../constants";

export class NotificacionAlumnoService {
    
  static insert = async ( {usuario_id, campos} ) => {

    try {
      let formData = new FormData();

      formData.append("usuario_id", usuario_id);
      formData.append("tipo_id", campos.tipo_id);
      formData.append("descripcion", campos.comentario);
      formData.append("asunto", campos.asunto);
      formData.append("adjunto", campos.adjunto[0]);

      const response = await axios({
        method: "post",
        url: BASE_URL_SERVICE + "/alumno/notificacion_alumno_insert",
        data: formData,
        headers: {
          //  'Content-Type': 'multipart/form-data',
          //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
        },
      });

      return response.data;
    } catch (error) {
        return undefined;
    }

  }
  static update = async ( {usuario_id, notificacion_id, campos} ) => {

    try {
      let formData = new FormData();

      formData.append("usuario_id", usuario_id);
      formData.append("notificacion_id", notificacion_id);
      formData.append("tipo_id", campos.tipo_id);
      formData.append("descripcion", campos.comentario);
      formData.append("asunto", campos.asunto);
      formData.append("adjunto", campos.adjunto[0]);

      const response = await axios({
        method: "post",
        url: BASE_URL_SERVICE + "/alumno/notificacion_alumno_update",
        data: formData,
        headers: {
          //  'Content-Type': 'multipart/form-data',
          //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
        },
      });

      return response.data;
    } catch (error) {
        return undefined;
    }

  }

  static list = async ( {usuario_id} ) => {

    try {
      let formData = new FormData();

      formData.append("usuario_id", usuario_id);

      const response = await axios({
        method: "post",
        url: BASE_URL_SERVICE + "/alumno/notificacion_alumno_list",
        data: formData,
        headers: {
          //  'Content-Type': 'multipart/form-data',
          //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
        },
      });

      return response.data;
    } catch (error) {
        return undefined;
    }

  }
  static list_destinos = async ( {usuario_id, notificacion_id} ) => {

    try {
      let formData = new FormData();

      formData.append("usuario_id", usuario_id);
      formData.append("notificacion_id", notificacion_id);

      const response = await axios({
        method: "post",
        url: BASE_URL_SERVICE + "/alumno/notificacion_alumno_list_destinos",
        data: formData,
        headers: {
          //  'Content-Type': 'multipart/form-data',
          //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
        },
      });

      return response.data;
    } catch (error) {
        return undefined;
    }

  }
    
  static insertDestinos = async ( {usuario_id, notificacion_id, destinos} ) => {

    try {
      let formData = new FormData();

      formData.append("usuario_id", usuario_id);
      formData.append("notificacion_id", notificacion_id);
      formData.append("destinos", destinos);

      const response = await axios({
        method: "post",
        url: BASE_URL_SERVICE + "/alumno/notificacion_alumno_insert_destinos",
        data: formData,
        headers: {
          //  'Content-Type': 'multipart/form-data',
          //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
        },
      });

      return response.data;
    } catch (error) {
        return undefined;
    }

  }
}