import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


// import EmptyComponent from '../../../../components/EmptyContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { actualizarCalendly, actualizarCalendlyTutoriasGrupales, getAlumnosCoordinador, getTutorTutoriasGRupales, getTutorTutoriasGrupales, getTutoriasGrupales, getTutoriasIndividuales, tutoriaGrupalInformarSolicitante } from '../../../services/alumnos';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
// import { Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { Box, Chip, Container, Divider, Grid, IconButton, Link, Paper, TextField, Typography } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import SyncIcon from '@material-ui/icons/Sync';
// import Swal from 'sweetalert2';
// import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// import PropTypes from 'prop-types';
// import TituloPagina from '../../../../components/Titulos/TituloPagina';
// import AddIcon from '@material-ui/icons/Add';
// import { getAlumnosTutor } from '../../../../services/alumnos';
// import { Stack } from '@mui/system';
// import Moment from 'react-moment';
// import { DataGridPro, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import DataGridInnovattio from '../../../components/DataGridInnovatiio';
import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
import StatusTutor from '../../../components/StatusTutor';
import StatusEntregasAlumno from '../../../components/StatusEntregasAlumno';
import StatusTicketsAlumno from '../../../components/StatusTicketsAlumno';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { blue, blueGrey, green, grey, red } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
// import moment from 'moment/moment';
// import { amber, blue, green, red, yellow } from '@material-ui/core/colors';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function TutoresTutoriasGrupalesContent() {
    const classes = useStyles();

    const [tutor, setTutor] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    // // const [selectionModel, setSelectionModel] = React.useState([]);
    // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando info tutor")

        const usuario_id = usuarioSeleccionado.id

        getTutorTutoriasGrupales({ usuario_id: usuario_id, tutor_id: usuario_id }).then((response) => {
          // getTutoriasGrupales({ usuario_id: usuario_id }).then((response) => {
            setLoading(false)

            response && setTutor(response.tutor)
          // });
        });

    }, [])

    
    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    // const columnas = 
    // [
    //   { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
    //   { 
    //     field: 'mail', 
    //     headerName: 'Sala', 
    //     minWidth: 350, 
    //     renderCell: (celda) => {
    //         return <>{celda.row.mail }</>

    //     }
    //   },
    //   { 
    //     field: 'invitado', 
    //     headerName: 'Solicitante', 
    //     minWidth: 350, 
    //     renderCell: (celda) => {
    //         return <>{celda.row.invitado }</>

    //     }
    //   },

    //   { field: 'start_time', headerName: 'Inicio',minWidth: 170},
    //   { field: 'end_time', headerName: 'Fin',minWidth: 170 },
    //   { 
    //     field: 'estado', 
    //     headerName: 'Estado',
    //     minWidth: 130, 
    //     renderCell: (celda) => {
    //       return (
    //         <>
    //           { celda.row.estado === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
    //           { celda.row.estado === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
    //           { celda.row.estado === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
    //           { celda.row.estado === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
    //         </>
    //       )
    //     }
    //   },
    //   { field: 'zoom_link', headerName: 'Enlace Zoom',minWidth: 400},
    //   { 
    //     field: 'canceler_type', 
    //     headerName: 'Canceldo por',
    //     minWidth: 220,
    //     renderCell: (celda) => {
    //       if(celda.row.canceler_type === '') {
    //         return ''
    //       }

    //       return (
    //         celda.row.canceler_type === 'host' ? 'Sala' : 'Invitado'
    //       )
    //     }
    //   },
    //   { field: 'question', headerName: 'Pregunta',minWidth: 220, flex: 1 },
    //   {
    //     field: 'actions',
    //     type: 'actions',
    //     width: 80,
    //     getActions: (params) => [
    //       <GridActionsCellItem
    //         // icon={<EditIcon />}
    //         label="Informar a solicitante"
    //         onClick={ () => { 
    //             handleInformarSolicitante(params.row) 
    //         }}
    //         // component={Link}
    //         // to={`alumnos/detalle/${params.id}`}
    //         showInMenu
    //       />,
    //     ],
    //   }
    // ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const ContenidoTutoriaGrupal = (tutoria_grupal) => {
        return (

          <Grid container spacing={2}>

            <Grid item xs={3}>
              <Paper elevation={3} style={{padding: "15px 15px", margin: "10px 15px", backgroundColor: blueGrey[100]}}>
            
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 500}} >
                    TUTORÍA GRUPAL
                </Typography>
                
                <Divider />
              
                <Typography variant="subtitle1" gutterBottom  >
                    { tutoria_grupal.start_time }
                </Typography>

              </Paper>

            </Grid>
            <Grid item xs={3}>
              <Paper elevation={3} style={{padding: "15px 15px", margin: "10px 15px", backgroundColor: blueGrey[100]}}>
            
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 500}} >
                    GRUPO
                </Typography>
                
                <Divider />
              
                <Typography variant="subtitle1" gutterBottom  >
                    {tutoria_grupal.entregas_plantilla_descripcion}
                </Typography>

              </Paper>

            </Grid>
            
            <Grid item xs={3}>
              
              <Paper elevation={3} style={{padding: "15px 15px", margin: "10px 15px", backgroundColor: blueGrey[100]}}>
            
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 500}} >
                    PREGUNTAS ABIERTAS HASTA
                </Typography>
            
                <Divider />
              
                <Typography variant="subtitle1" gutterBottom>
                    {tutoria_grupal.fecha_fin_duda_grupal_abierta}
                </Typography>

                </Paper>

            </Grid>
            <Grid item xs={3}>
              
              <Paper elevation={3} style={{padding: "15px 15px", margin: "10px 15px", backgroundColor: blueGrey[100]}}>
            
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 500}} >
                    UBICACION
                </Typography>
            
                <Divider />
              
                <Typography variant="subtitle1" gutterBottom>
                    Usuario: {tutoria_grupal.mail}<br />
                    Contraseña: {tutoria_grupal.sala_zoom_pass}<br />
                </Typography>

                </Paper>

            </Grid>

            <Grid item xs={12}>

              <Paper elevation={3} style={{padding: "15px 15px", margin: "10px 15px"}}>
            
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 500}} >
                    DUDAS PARA LA TUTORÍA
                </Typography>
            
                <Divider />

                {
                  tutoria_grupal.dudas && tutoria_grupal.dudas.map( (item) => {
                    return ( 
                      <Paper elevation={1} style={{padding: "15px 15px", margin: "20px 15px", whiteSpace: "pre-line"}}>
                        <b>Duda</b>
                        <Divider />
                        <br />
                        {item.duda}<br /><br />
                        <Divider />
                        <br /><b>Alumno:</b> <Link href={`alumnos_tutor/detalle/${item.alumno_id}`}>{item.alumno_nombre}</Link>
                      </Paper>
                    )
                  })
                }

              </Paper>

            </Grid>

          </Grid>

        )
    }
    const Contenido = () => {

        return (
            tutor ?
                <>
                  <TituloPagina titulo={"Tutorías grupales"} />

                    { 
                      tutor.tutorias_grupales && tutor.tutorias_grupales.map( (item) => { 
                        return (
                          <>
                            { ContenidoTutoriaGrupal(item) }
                            <br />
                            <br />
                            <br />
                          </>
                        )
                      }
                      )
                    }
                     
                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          {/* <DataGridPro
                              rows={tutorias} 
                              columns={columnas} 
                              rowHeight={30}
                              // onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                              // componentsProps={{
                              //     toolbar: {
                              //       value: searchText,
                              //       onChange: (event) => requestSearch(event.target.value),
                              //       clearSearch: () => requestSearch(''),
                              //     },
                              // }}
                          /> */}
                      </div>
                    </Box>
                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
