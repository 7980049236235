import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import PlantillasEntregasContent from './components/PlantillasEntregasContent';

const PlantillasEntregas = () => {

    return (
        <>

            <TemplateDashboard>
                <PlantillasEntregasContent />
            </TemplateDashboard>

        </>
    )
};

export default PlantillasEntregas;