import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { Box, Button, Chip, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { blue, green, red } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
import { Stack } from '@mui/material';
import { DialogTutoriaGrupalEnlaceForm, DialogTutoriaGrupalForm } from './DialogTutoriaGrupalEnlaceForm';
import { delete_tutoria_grupal_enlace, getTutoriasGrupalesEnlaces } from '../../../services/alumnos';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function TutoriasGrupalesEnlacesContent() {
    const classes = useStyles();

    const [tutorias, setTutorias] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [openDialogoTutoriaGrupal, setOpenDialogoTutoriaGrupal] = useState(false)
    const [tutoriaGrupalSeleccionada, setTutoriaGrupalSeleccionada] = useState(false)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    // // const [selectionModel, setSelectionModel] = React.useState([]);
    // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        // setLoading(true)
        // setLoadingMensaje("Cargando tutorias")

        const usuario_id = usuarioSeleccionado.id

        // // actualizarCalendlyTutoriasGrupales({ usuario_id: usuario_id }).then((response) => {
          getTutoriasGrupalesEnlaces({ usuario_id: usuario_id }).then((response) => {
            setLoading(false)

            response && setTutorias(response.tutorias)
          });
        // // });

    }, [])

    const handleEliminarTutoriaGrupalEnlace = (fila) => {
      Swal.fire({
        title: '¿Realmente deseas eliminar el enlace?',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: `Cancelar`,
        confirmButtonColor: '#afcb0d',
        cancelButtonColor: '#a5a5a5',
        icon: 'warning',
        reverseButtons: true
      }).then((result) => {

        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            
          delete_tutoria_grupal_enlace({usuario_id: usuarioSeleccionado.id, tutoria_grupal_enlace_id: fila.id}).then( (response) => {
            getTutoriasGrupalesEnlaces({ usuario_id: usuarioSeleccionado.id }).then((response) => {
              setLoading(false)
    
              response && setTutorias(response.tutorias)
            });
          })

        } else if (result.isDenied) {
//         //   Swal.fire('Changes are not saved', '', 'info')
        }
      })


      
    }

//     const handleInformarSolicitante = (fila) => {

//       Swal.fire({
//         title: '¿Realmente deseas informar?',
//         showCancelButton: true,
//         confirmButtonText: 'Enviar',
//         cancelButtonText: `Cancelar`,
//         confirmButtonColor: '#afcb0d',
//         cancelButtonColor: '#a5a5a5',
//         icon: 'warning',
//         reverseButtons: true
//       }).then((result) => {

//         /* Read more about isConfirmed, isDenied below */
//         if (result.isConfirmed) {

//           tutoriaGrupalInformarSolicitante({usuario_id: usuarioSeleccionado.id, tutoria_grupal_id: fila.id }).then( (response) => {
//             Swal.fire("Solicitante informado")
//           })

//         } else if (result.isDenied) {
// //         //   Swal.fire('Changes are not saved', '', 'info')
//         }
//       })

      
//     }
//     const handleInformarAlumnos = (fila) => {

//       Swal.fire({
//         title: '¿Realmente deseas informar?',
//         showCancelButton: true,
//         confirmButtonText: 'Enviar',
//         cancelButtonText: `Cancelar`,
//         confirmButtonColor: '#afcb0d',
//         cancelButtonColor: '#a5a5a5',
//         icon: 'warning',
//         reverseButtons: true
//       }).then((result) => {

//         /* Read more about isConfirmed, isDenied below */
//         if (result.isConfirmed) {

//           tutoriaGrupalInformarAlumnos({usuario_id: usuarioSeleccionado.id, tutoria_grupal_id: fila.id }).then( (response) => {
//             Swal.fire("Alumnos informados")
//           })

//         } else if (result.isDenied) {
// //         //   Swal.fire('Changes are not saved', '', 'info')
//         }
//       })

      
//     }
    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { 
        field: 'descripcion', 
        headerName: 'Descripción', 
      },
      { 
        field: 'url_video', 
        headerName: 'Video', 
        flex: 1,

        renderCell: (celda) => {
            return <>{celda.row.url_video }</>

        }
      },
      { 
        field: 'url_adjunto', 
        headerName: 'Adjunto', 
        flex: 1,

        renderCell: (celda) => {
            return <>{celda.row.url_adjunto }</>

        }
      },
      { 
        field: 'tutor_nombre', 
        headerName: 'Tutor', 
        flex: 1,

        renderCell: (celda) => {
            return <>{celda.row.tutor_nombre }</>

        }
      },
      { 
        field: 'plantilla_descripcion', 
        headerName: 'Grupo', 
        flex: 1,

        renderCell: (celda) => {
            return <>{celda.row.plantilla_descripcion }</>

        }
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            // icon={<EditIcon />}
            label="Eliminar enlace"
            onClick={ () => { 
                handleEliminarTutoriaGrupalEnlace(params.row) 
            }}
            // component={Link}
            // to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
          // <GridActionsCellItem
          //   // icon={<EditIcon />}
          //   label="Informar a solicitante"
          //   onClick={ () => { 
          //       handleInformarSolicitante(params.row) 
          //   }}
          //   // component={Link}
          //   // to={`alumnos/detalle/${params.id}`}
          //   showInMenu
          // />,
          // <GridActionsCellItem
          //   // icon={<EditIcon />}
          //   label="Informar alumnos"
          //   onClick={ () => { 
          //       handleInformarAlumnos(params.row) 
          //   }}
          //   // component={Link}
          //   // to={`alumnos/detalle/${params.id}`}
          //   showInMenu
          // />,
        ],
      }
    ]

    const handleOpenDialogTutoriaGrupalEnlace = (item) => {

        item && setTutoriaGrupalSeleccionada(item.row)
        !item && setTutoriaGrupalSeleccionada(undefined)
        setOpenDialogoTutoriaGrupal(true)

    }

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        return (
            tutorias ?
                <>
                    
                    <Stack direction={'row'} spacing={2}>
        
                      <Box mt={0} mb={0} mx={2}>
                        <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                            Tutorías grupales enlaces
                        </Typography>
                      </Box>            
                      <Button variant="outlined" color="primary" onClick={() => handleOpenDialogTutoriaGrupalEnlace()}>Nuevo enlace</Button>
                    </Stack>
                    
                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridPro
                              rows={tutorias} 
                              columns={columnas} 
                              rowHeight={30}
                              onRowDoubleClick={ ( evento ) => handleOpenDialogTutoriaGrupalEnlace(evento) }
                              // componentsProps={{
                              //     toolbar: {
                              //       value: searchText,
                              //       onChange: (event) => requestSearch(event.target.value),
                              //       clearSearch: () => requestSearch(''),
                              //     },
                              // }}
                          />
                      </div>
                    </Box>

                    <DialogTutoriaGrupalEnlaceForm
                      tutoriaGrupalEnlace={tutoriaGrupalSeleccionada}
                      openModal={openDialogoTutoriaGrupal}
                      handleOpenModal={setOpenDialogoTutoriaGrupal}
                      setTutorias={setTutorias}
                    />

                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
