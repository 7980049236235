import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { getAlumnosCoordinador } from '../../../services/alumnos';
import { GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { Box, Chip, IconButton, Link, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import DataGridInnovattio from '../../../components/DataGridInnovatiio';
import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
import StatusEntregasAlumno from '../../../components/StatusEntregasAlumno';
import StatusTicketsAlumno from '../../../components/StatusTicketsAlumno';
import { grey } from '@material-ui/core/colors';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function CoordinacionContent() {
    const classes = useStyles();

    const [alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    // const [selectionModel, setSelectionModel] = React.useState([]);
    // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        console.log(usuarioSeleccionado)

        
        getAlumnosCoordinador({ usuario_id: usuario_id, coordinador_usuario_id:usuario_id }).then((response) => {
          setLoading(false)

          response && setAlumnos(response.alumnos)
        });

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { 
        field: 'nombre', 
        headerName: 'Nombre', 
        minWidth: 150, 
        flex: 1, 
        renderCell: (celda) => {
            console.log(celda)
            return <Link href={`/alumnos_tutor/detalle/${celda.row.id}`}>{celda.row.nombre }</Link>

        }
      },
      { field: 'tutor_nombre', headerName: 'Tutor',minWidth: 10, flex: 1 },
      { field: 'entregas_plantilla_descripcion', headerName: 'Grupo',minWidth: 10, flex: 1 },
      { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
      { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 90 },
      { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 220 },
      // {
      //   'field': 'estado_tutor', 
      //   'width':160, 
      //   'headerName': 'Estado tutor', 
      //   'description': 'Estado tutor',
      //   // type: 'date',
      //   // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
      //   renderCell: (cellValues) => {
      //     return (
              
      //       <StatusTutor num_tickets_entregas_abiertos={cellValues.row.num_tickets_entregas_abiertos} />
            
      //     );
      //   } 
      // },
      {
        'field': 'estado_alumno', 
        'width':230, 
        'headerName': 'Entregas', 
        'description': 'Estado entregas alumno',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
            <StatusEntregasAlumno 
              num_tickets_resueltos={cellValues.row.num_tickets_entregas_resueltos} 
              num_tickets_abiertos={cellValues.row.num_tickets_entregas_abiertos} 
              num_tickets_pendientes={cellValues.row.num_tickets_entregas_pendientes} 
              num_tickets_retrasados={cellValues.row.num_tickets_entregas_retrasados} />
            </>
            
          );
        } 
      },
      {
        'field': 'estado_alumno_simulacros', 
        'width':230, 
        'headerName': 'Simulacros', 
        'description': 'Simulacros',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
            <StatusEntregasAlumno 
              num_tickets_resueltos={cellValues.row.num_tickets_simulacros_resueltos} 
              num_tickets_abiertos={cellValues.row.num_tickets_simulacros_abiertos} 
              num_tickets_pendientes={cellValues.row.num_tickets_simulacros_pendientes} 
              num_tickets_retrasados={cellValues.row.num_tickets_simulacros_retrasados} />
            </>
            
          );
        } 
      },
      
      {
        'field': 'dudas', 
        'width':200, 
        'headerName': 'Dudas', 
        'description': 'Dudas',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
              <StatusTicketsAlumno 
                num_tickets_resueltos={cellValues.row.num_tickets_resueltos} 
                num_tickets_abiertos={cellValues.row.num_tickets_abiertos} 
                num_tickets_pendientes={cellValues.row.num_tickets_pendientes} 
                num_tickets_retrasados={cellValues.row.num_tickets_retrasados} 
              />
            </>
            
          );
        } 
      },
      {
        'field': 'num_tutorias', 
        'width':200, 
        'headerName': 'Tutorías realizadas/pendientes', 
        'description': 'Tutorías realizadas y tutorias planificadas pendientes de realizar',
        'align': 'center',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
              { <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`B: ${cellValues.row.num_tutorias_bienvenida}`} /> }
              { <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`R: ${cellValues.row.num_tutorias_realizadas}`} /> }
              { <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`P: ${cellValues.row.num_tutorias_pendientes}`} /> }
            </>
            
          );
        } 
      },
    ]

    // const linkDelete = (id) => {
    //     Swal.fire({
    //         title: '¿Realmente deseas eliminar el inquilino?',
    //         showCancelButton: true,
    //         confirmButtonText: 'Eliminar',
    //         cancelButtonText: `Cancelar`,
    //         confirmButtonColor: '#afcb0d',
    //         cancelButtonColor: '#a5a5a5',
    //         icon: 'warning',
    //         reverseButtons: true
    //       }).then((result) => {

    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
                
    //         //     console.log(id)
    //             const usuario_id = usuarioSeleccionado.id

    //             inquilinoDelete( usuario_id, id ).then((response) => {
                    
    //                 response && setAlumnos(response.data.Alumnos)
                    
    //             })

    //         } else if (result.isDenied) {
    //         //   Swal.fire('Changes are not saved', '', 'info')
    //         }
    //       })
    // }
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
    }
      
    // QuickSearchToolbar.propTypes = {
    //   clearSearch: PropTypes.func.isRequired,
    //   onChange: PropTypes.func.isRequired,
    //   value: PropTypes.string.isRequired,
    // };

    const Contenido = () => {
        const [rows, setRows] = React.useState(alumnos);
        // const [searchText, setSearchText] = React.useState('');
        // const requestSearch = (searchValue) => {
        //   // console.log(searchValue)
        //   setSearchText(searchValue);
        //   const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        //   // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
        //   const filteredRows = Alumnos.filter((row) => {
        //       return Object.keys(row).some((field) => {
        //           // console.log(field)
        //           // console.log(row)
        //           return searchRegex.test(row[field]);
        //       });
        //   });
        //   setRows(filteredRows);
        // };

        // const handleAlumnoDetalle = (id) => {
        //   history.push('/alumnos_tutor/detalle/' + id )
        // }

        return (
            rows ?
                <>
                     <TituloPagina titulo={"Alumnos de tus tutores"} />

                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridInnovattio
                              rows={rows} 
                              columns={columnas} 
                              rowHeight={30}
                              components={{ Toolbar: QuickSearchToolbar }}
                              // onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                              // componentsProps={{
                              //     toolbar: {
                              //       value: searchText,
                              //       onChange: (event) => requestSearch(event.target.value),
                              //       clearSearch: () => requestSearch(''),
                              //     },
                              // }}
                          />
                      </div>
                    </Box>
                    <LeyendaEstadosTutor />
                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
