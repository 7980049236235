import { Divider, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { GRAY_400 } from '../../../constants/colores'
import { ListaConversaciones } from './ListaConversaciones'
import FormComentario from '../../../forms/FormComentario'

const ContenidoDetalle = ({origenTipo, origenData, conversaciones, fetchData}) => {
  return (
    <>
    <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Paper style={{ padding: 20 }}>
          <div style={{color:GRAY_400}}>Asunto</div>
          <Divider />
          {origenTipo === 'Duda' && <div style={{marginTop: 10, fontWeight: "bold"}} className="mt-4 font-bold">{origenData.asunto}</div>}
          {origenTipo !== 'Duda' && <div style={{marginTop: 10, fontWeight: "bold"}} className="mt-4 font-bold">{origenData.descripcion}</div>}
          <Divider />
          <div style={{marginTop: 10}} className="mt-4">{origenData.cuerpo_mensaje}</div>
          </Paper>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <ListaConversaciones conversaciones={conversaciones} />
          </Paper>

        <Paper style={{ padding: 20, marginTop: 20 }}>
        
          <FormComentario origenTipo={origenTipo} origenData={origenData} fetchData={fetchData} />

        </Paper>

      </Grid>

      </>
  )
}

export default ContenidoDetalle
