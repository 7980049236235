import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


// import EmptyComponent from '../../../../components/EmptyContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { actualizarTutoriaInvitado, getAlumnosCoordinador, getTutoriasIndividuales, getTutoriasIndividualesSinAlumno } from '../../../services/alumnos';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
// import { Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { Box, Chip, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import SyncIcon from '@material-ui/icons/Sync';
// import Swal from 'sweetalert2';
// import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// import PropTypes from 'prop-types';
// import TituloPagina from '../../../../components/Titulos/TituloPagina';
// import AddIcon from '@material-ui/icons/Add';
// import { getAlumnosTutor } from '../../../../services/alumnos';
// import { Stack } from '@mui/system';
// import Moment from 'react-moment';
// import { DataGridPro, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import DataGridInnovattio from '../../../components/DataGridInnovatiio';
import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
import StatusTutor from '../../../components/StatusTutor';
import StatusEntregasAlumno from '../../../components/StatusEntregasAlumno';
import StatusTicketsAlumno from '../../../components/StatusTicketsAlumno';
import { DataGridPro, useGridApiContext } from '@mui/x-data-grid-pro';
import { green, red } from '@material-ui/core/colors';
// import moment from 'moment/moment';
// import { amber, blue, green, red, yellow } from '@material-ui/core/colors';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function TutoriasIndividualesSinAlumnoContent() {
    const classes = useStyles();

    const [tutorias, setTutorias] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    // // const [selectionModel, setSelectionModel] = React.useState([]);
    // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando tutorias")

        const usuario_id = usuarioSeleccionado.id

        getTutoriasIndividualesSinAlumno({ usuario_id: usuario_id }).then((response) => {
          setLoading(false)

          response && setTutorias(response.tutorias)
        });

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    function TextFieldEditInputCell(props) {
      const { id, value, field } = props;
      const apiRef = useGridApiContext();
    
      const handleChange = async (event) => {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        apiRef.current.stopCellEditMode({ id, field });

        if(field === 'invitado') {

          actualizarTutoriaInvitado({usuario_id: usuarioSeleccionado.id, tutoria_id: id, invitado: event.target.value}).then( response => {
            response && setTutorias(response.tutorias)
            
          })
        }
      };

      const handleBlur = async (event) => {
        console.log(event)
      }

      return (
        <TextField
            fullWidth
            margin="normal"
            value={value}
            onBlur={handleChange}
            // onChange={handleChange}
            variant="outlined"
        />
        // <Select
        //   value={value}
        //   onChange={handleChange}
        //   size="small"
        //   sx={{ height: 1 }}
        //   native
        //   autoFocus
        // >
        //   <option>Back-end Developer</option>
        //   <option>Front-end Developer</option>
        //   <option>UX Designer</option>
        // </Select>
      );
    }
    const renderTextFieldEditInputCell = (params) => {
      return <TextFieldEditInputCell {...params} />;
    };

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { 
        field: 'tutor_nombre', 
        headerName: 'Tutor', 
        minWidth: 150, 
        flex: 1, 
      },
      { 
        field: 'invitado', 
        headerName: 'Alumno', 
        minWidth: 150, 
        flex: 1, 
        editable: true,
        // renderEditCell: renderTextFieldEditInputCell,

      },
      { field: 'start_time', headerName: 'Inicio',minWidth: 10, flex: 1 },
      { field: 'end_time', headerName: 'Fin',minWidth: 10, flex: 1 },
      { 
        field: 'estado', 
        headerName: 'Estado',
        minWidth: 100, 
        renderCell: (celda) => {
          return (
            <>
              { celda.row.estado === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
              { celda.row.estado === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
            </>
          )
        }
      },
      { field: 'zoom_link', headerName: 'Enlace Zoom',minWidth: 90, flex: 1 },
      { field: 'zoom_password', headerName: 'Password Zoom',minWidth: 220 },
      { 
        field: 'canceler_type', 
        headerName: 'Canceldo por',
        minWidth: 220,
        renderCell: (celda) => {
          if(celda.row.canceler_type === '') {
            return ''
          }

          return (
            celda.row.canceler_type === 'host' ? 'Tutor' : 'Alumno'
          )
        }
      },
    ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    

    const Contenido = () => {

      const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log(updatedRow);
        
        actualizarTutoriaInvitado({usuario_id: usuarioSeleccionado.id, tutoria_id: updatedRow.id, invitado: updatedRow.invitado}).then( response => {
          response && setTutorias(response.tutorias)
        })

        return updatedRow;
      };

        return (
            tutorias ?
                <>
                     <TituloPagina titulo={"Tutorias sin alumno asignado"} />

                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridPro
                              rows={tutorias} 
                              columns={columnas} 
                              rowHeight={30}
                              // onCellEditStop={ (p) => console.log(p) }
                              // onRowEditCommit = { (p) => console.log(p) }
                              processRowUpdate={ processRowUpdate }
                              // -- processRowUpdate={ p => console.log(p) }
                              // -- onProcessRowUpdateError= { () => {}}
                              // onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                              // componentsProps={{
                              //     toolbar: {
                              //       value: searchText,
                              //       onChange: (event) => requestSearch(event.target.value),
                              //       clearSearch: () => requestSearch(''),
                              //     },
                              // }}
                          />
                      <br /><b>NOTA: </b> El correo del alumno se puede editar
                      </div>

                    </Box>
                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
