import DateFnsUtils from '@date-io/date-fns';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from 'date-fns/locale/es';

import { useFormik } from "formik";
import * as yup from 'yup';
import { getAlumnosEntregas, insertar_alumno_entrega, update_alumno_entrega, update_alumno_entrega_cambiar_fechas } from '../../../services/alumnos';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import AdjuntoEntrega from '../../../components/AdjuntoEntrega/AdjuntoEntrega';
import { getListaDetalle } from '../../../services/lista_detalle';

export const DialogEntregaCambiarFechasForm = ({openModal, handleOpenModal, alumnoID, entrega, setEntregas}) => {
    const [tiposEntrega, setTiposEntrega] = useState([]);

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {

        getListaDetalle({usuario_id: usuarioSeleccionado.id, lista_id: '14'}).then( (response) => {
            setTiposEntrega(response.lista_detalle)
        })

        formik.setValues({
            fecha_planificacion: null,
            fecha_prevista: null,
        })

        if(entrega) {
            formik.setValues({
                fecha_planificacion: entrega.fecha_planificacion,
                fecha_prevista: entrega.fecha_prevista,
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entrega])
    
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            fecha_planificacion: null,
            adjunto_nombre_original: "",
        },
        validationSchema: yup.object({
            fecha_planificacion: yup.string().nullable().required("Fecha es requerida"),
            fecha_prevista: yup.string().nullable().required("Fecha es requerida"),
          }),
        onSubmit: (values) => {

            if(entrega) {

                update_alumno_entrega_cambiar_fechas({
                    usuario_id: usuarioSeleccionado.id, 
                    entrega_id: entrega.id,
                    alumno_id: alumnoID,
                    fecha_planificacion: values.fecha_planificacion, 
                    fecha_prevista: values.fecha_prevista, 
                }).then( (response) => {

                    getAlumnosEntregas({ alumno_id: alumnoID, usuario_id: usuarioSeleccionado.id}).then( (response) => {

                        response.entregas && setEntregas(response.entregas)

                    })

                    handleOpenModal(false)
                })

            }

            

            
        },
    });

    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <Dialog open={openModal} onClose={() => handleOpenModal(false)} maxWidth={'md'}>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

                <DialogTitle>Cambiar fechas de entrega</DialogTitle>
                <DialogContent style={{width: '700px'}}>


                    <Grid container>

                        <Grid item sm={6} style={{marginTop: 16, paddingRight: 10}}>
                            
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                
                                    fullWidth
                                    id="fecha_planificacion"
                                    label="Fecha Planificacion"
                                    placeholder='Introduce la fecha de planificacion'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_planificacion}
                                    onChange={value => formik.setFieldValue("fecha_planificacion", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_planificacion && Boolean(formik.errors.fecha_planificacion)}
                                    helperText={formik.touched.fecha_planificacion && formik.errors.fecha_planificacion}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid item sm={6} style={{marginTop: 16}}>
                            
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                
                                    fullWidth
                                    id="fecha_prevista"
                                    label="Fecha Prevista"
                                    placeholder='Introduce la fecha prevista de entrega'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_prevista}
                                    onChange={value => formik.setFieldValue("fecha_prevista", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_prevista && Boolean(formik.errors.fecha_prevista)}
                                    helperText={formik.touched.fecha_prevista && formik.errors.fecha_prevista}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>

                        
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleOpenModal(false)}>Cancelar</Button>
                    <Button type='submit'> { entrega ? 'Actualizar entrega' : 'Crear entrega' }</Button>
                </DialogActions>
            </form>

        </Dialog>
    )
}