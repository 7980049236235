import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer"
import { entregaPlantillaCreate } from "../../../../services/alumnos"
import { getTutores } from "../../../../services/tutores"

export const DialogPlantillaForm = ({ openModal, setOpenModal, cargarPlantillas }) => {
    const [tutores, setTutores] = useState(undefined)

    const [tutorSeleccionado, setTutorSeleccionado] = useState('')
    const [errorTutorSeleccionado, setErrorTutorSeleccionado] = useState(false)
    const [errorDescripcion, setErrorDescripcion] = useState(false)
    const [descripcion, setDescripcion] = useState('')

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });


    useEffect(() => {
        const usuario_id = usuarioSeleccionado.id
  
        getTutores({usuario_id: usuario_id}).then( (response) => {
        response.tutores && setTutores(response.tutores)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleCreatePlantilla = () => {
          
        setErrorTutorSeleccionado(false)
        if ( usuarioSeleccionado.perfil_id === '11' ) {
          if( tutorSeleccionado === '' ) {
            setErrorTutorSeleccionado(true)
            return null;
          }
        }

        setErrorDescripcion(false)
        if( descripcion === '' ) {
          setErrorDescripcion(true)
          return null;
        }

        let tutor_id = usuarioSeleccionado.id
        if( usuarioSeleccionado.perfil_id === '11') {
          tutor_id = tutorSeleccionado
        }

        entregaPlantillaCreate({ 
          usuario_id: usuarioSeleccionado.id, 
          tutor_id:tutor_id, 
          descripcion: descripcion 
        }).then((response) => {

          cargarPlantillas()

          setDescripcion( '' )

        });

        setOpenModal(false)

      }  

    return (
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth={'md'}>
            <DialogTitle>Crear plantilla</DialogTitle>
            <DialogContent style={{width: '700px'}}>
            
                    { 
                    usuarioSeleccionado.perfil_id === '11'
                    ?
                    
                    <Grid xs={12} style={{marginBottom: 20}}>
                        <FormControl
                        variant="outlined"
                        fullWidth
                        error={errorTutorSeleccionado}
                        >
                        <InputLabel style={{paddingLeft: 20}} id="tutor_id-label">Tutor</InputLabel>
                        <Select
                            labelId="tutor_id-label"
                            id="tutor_id"
                            name="tutor_id"
                            label="Tutor"
                            fullWidth
                            value={tutorSeleccionado}
                            onChange={ (value) => { setTutorSeleccionado(value.target.value)} }
                        >
                            <MenuItem key={-1} value={-1}>Para todos los tutores</MenuItem>
                            {        

                                tutores && tutores.map( (item) => {
                                    return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                })
                            }

                        </Select>
                        { errorTutorSeleccionado && <FormHelperText>Es necesario seleccionar un tutor</FormHelperText> }
                        </FormControl>
                    </Grid>
                    :
                    <></>
                    }

                    <Grid xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        error={errorDescripcion}
                    >
                        <TextField
                        autoFocus
                        id="descripcion"
                        label="Descripción de la plantilla"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={descripcion}
                        onChange={ (value) => { setDescripcion(value.target.value)} }
                        />

                        { errorDescripcion && <FormHelperText>Es necesario escribir una descripción de la plantilla</FormHelperText> }

                    </FormControl>
                    </Grid>
            
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
                <Button onClick={() => handleCreatePlantilla()}>Crear plantilla</Button>
            </DialogActions>
        </Dialog>
    )
}