import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import MatrizSimulacrosContent from './components/MatrizSimulacrosContent';

const MatrizSimulacros = () => {

    return (
        <>

            <TemplateDashboard>
                <MatrizSimulacrosContent />
            </TemplateDashboard>

        </>
    )
};

export default MatrizSimulacros;