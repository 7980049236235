import React from 'react';

import TemplateDashboard from '../../components/TemplateDashboard';
import ConversacionesApoyoContent from './components/ApoyoTutoresContent';

const ApoyoTutores = () => {

    return (
        <>

            <TemplateDashboard>
                <ConversacionesApoyoContent />
            </TemplateDashboard>

        </>
    )
};

export default ApoyoTutores;