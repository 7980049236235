import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";

const DialogMensajeDirecto = ({
  openModal,
  handleCerrarDialog,
  mensajeDirecto,
}) => {
  // const usuarioSeleccionado = useSelector(state => {
  //     return selectUsuarioSeleccionado(state);
  // });

  return (
    <Dialog
      open={openModal}
      onClose={() => handleCerrarDialog()}
      maxWidth={"md"}
    >
      <DialogTitle>
        {mensajeDirecto.asunto}
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "700px" }}>
        <Grid xs={12}>
          <Box>{mensajeDirecto.descripcion}</Box>

          <Box style={{ marginTop: 20 }}>
            <Divider />
            {mensajeDirecto.created}
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCerrarDialog()}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMensajeDirecto;
