import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

export async function getTutores({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'tutores',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

    // try {
    //     var config = {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'content-type': 'text/json',
    //             'Authorization': "04871209347109238740"
    //         }
    //     };

    //     const response = await axios.get(BASE_URL_SERVICE + 'alumno', {
    //         params: {
    //             usuario_id,
    //         }
    //     }, config);

    //     return response.data;
    // } catch (error) {
    //     return undefined;
    //     // throw error;
    // }

}
