import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { useSelector } from 'react-redux'

const DialogNotaView = ({openModal, handleCerrarDialogoNota, nota}) => {
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    
    return (
        <Dialog open={openModal} onClose={() => handleCerrarDialogoNota() } maxWidth={'lg'}>
            <DialogTitle>Nota</DialogTitle>
            <DialogContent style={{width: '700px'}}>
            
                <Grid xs={12}>
                    <span style={{whiteSpace: 'pre-line'}}>{nota.nota}</span>
                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCerrarDialogoNota()}>Cancelar</Button>
            </DialogActions>
        </Dialog>
  )
}

export default DialogNotaView
