import React, { useEffect } from 'react'
import { useState } from "react";
import LoadingComponent from '../../../components/Loading';
import { Box, Button, Chip, Link, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getBandejaEntrada } from '../../../services/alumnos';
import moment from 'moment';
import Moment from 'react-moment';


export default function BandejaDeEntradaContent() {
    // const classes = useStyles();

    // const [alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [bandejaEntrada, setBandejaEntrada] = useState([])
    
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {

        getBandejaEntrada({usuario_id: usuarioSeleccionado.id}).then( ( response )=> {
            response && setBandejaEntrada(response.bandeja_entrada)

            console.log(response)
        })

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'origen',headerName: 'Tipo', width: 100 },
      { field: 'asunto',headerName: 'Asunto', flex: 1, },
      { field: 'alumno_nombre',headerName: 'Alumno', flex: 1, },
      {
        'field': 'created', 
        'width':200, 
        'headerName': 'Fecha', 
        'description': 'Fecha',
        type: 'date',
        valueFormatter: params => {
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY HH:mm:ss">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
    },
    {
        'field': 'enlace', 
        'width':200, 
        'headerName': '', 
        'description': '',
        valueFormatter: params => {
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
            return (
                <>
                    <Link href={`/alumnos_tutor/conversacion/${cellValues.row.alumno_id}/${cellValues.row.origen}/${cellValues.row.origen_id}`}>Abrir</Link>
                </>
            )
        } 
    },
    ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        
                return (
          <>
            <Stack direction={'row'} spacing={2}>
            
                <Box mt={0} mb={0} mx={2}>
                    <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                        Bandeja de entrada
                    </Typography>
                </Box>            
            
            </Stack>
        
            <Box>
                <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                
                    {bandejaEntrada && <DataGridPro
                        rows={bandejaEntrada} 
                        columns={columnas} 
                        rowHeight={30}
                        // getRowHeight={() => 'auto'}
                        // onRowDoubleClick={ (evento ) => handleNotificacionView(evento.row) }
                    />}
                </div>
            </Box>
              
          </>
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div style={{marginTop: '80px'}}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
