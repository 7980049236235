import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import { Box, Button, Divider, FormControl, FormHelperText, Link, Paper, TextField, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { entregaPlantillaLineaDelete, entregaPlantillaUpdate, getEntregasPlantillasByTutor } from '../../../../services/alumnos';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { DataGrid } from '@mui/x-data-grid';
import { DialogEntregaForm } from './DialogEntregaForm';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import Moment from 'react-moment';



// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },

    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function PlantillasEntregasDetalleContent() {
    const {id: plantilla_id} = useParams();

    const classes = useStyles();

    const [plantilla, setPlantilla] = useState(undefined);
    // const [tutores, setTutores] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    // // const [selectionModel, setSelectionModel] = React.useState([]);
    // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

  /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
      const usuario_id = usuarioSeleccionado.id

        setLoading(true)
        setLoadingMensaje("Cargando plantillas")

        cargarPlantilla()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                              Cargar Plantillas                             */
    /* -------------------------------------------------------------------------- */
    const cargarPlantilla = () => {
      const usuario_id = usuarioSeleccionado.id

      getEntregasPlantillasByTutor({ tutor_id:'-1', plantilla_id: plantilla_id }).then((response) => {
        
        setLoading(false)
        response && setPlantilla(response.plantilla)

        
      });

      

    }
    
    
    /* -------------------------------------------------------------------------- */
    /*                               handleOnSubmit                               */
    /* -------------------------------------------------------------------------- */
    

    const FormPlantilla = () => {
      const [errorDescripcion, setErrorDescripcion] = useState(false)
      const [descripcion, setDescripcion] = useState(plantilla?.descripcion)

      // const handleOnSubmit = (e) => {
      //   e.preventDefault()

      //   console.log("OnSubmit")
      // }

      useEffect(() => {
        formik.setValues({
          descripcion: plantilla?.descripcion
        })
      
        
      }, [plantilla])
      
      const formik = useFormik({
        initialValues: {
            descripcion: "",
        },
        validationSchema: yup.object({
            descripcion: yup.string().required("Descripcion es requerida"),
          }),
        onSubmit: (values) => {
  
          entregaPlantillaUpdate( { usuario_id: usuarioSeleccionado.id, plantilla_id: plantilla_id, tutor_id: plantilla.usuario_id, descripcion: values.descripcion }).then( (response) => {
          
            // console.log(response)

              // getEntregasPlantillasByTutor({ tutor_id:'-1', plantilla_id: plantilla.id }).then((response) => {
          
              //     response && setPlantilla(response.plantilla)
          
              // });

          })
  
        },
    });

      return (
        <Box sx={{marginX: 2, marginTop: 12, marginBottom: 10}}>
          <Paper style={{padding: 20}}>
            <Box mb={2}>
              <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                  Grupo
              </Typography>
              <Divider />
            </Box>

            <form className={classes.formulario} noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

              <Box mt={5}>
                
                <FormControl FormControl
                  variant="outlined"
                  fullWidth
                  error={errorDescripcion}
                >

                  <TextField
                      id="descripcion"
                      name="descripcion"
                      label="Descripcion"
                      placeholder="Introduce la descripcion"
                      fullWidth
                      margin="normal"
                      value={formik.values.descripcion}
                      onChange={formik.handleChange}
                      variant="outlined"
                      error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                      helperText={formik.touched.descripcion && formik.errors.descripcion}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />  

                  { errorDescripcion && <FormHelperText>Es necesario escribir una descripción de la plantilla</FormHelperText> }

                </FormControl>

              </Box>

              {/* Botones de guardado del formulario*/}
              <Box mt={5}>
                <Divider />
                  <Box mt={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  
                    <Button color="primary" style={{ marginRight: '30px' }} onClick={() => history.goBack()}>Cancelar</Button>

                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit"
                    >
                        Guardar cambios
                    </Button>

                  </Box>
              </Box> 

            </form>

          </Paper>
        </Box>
      )
    }

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'abreviatura', headerName: 'Abrev.', minWidth: 50 },
      { field: 'descripcion', headerName: 'Descripción', flex: 1, minWidth: 250 },
      { field: 'tipo_nombre', headerName: 'Tipo', minWidth: 250 },
      // { 
      //   field: 'enlace', 
      //   headerName: 'Enlace', 
      //   minWidth: 50,
      //   renderCell: (cellValues) => {
      //     return cellValues.row.adjunto ? (<a href={`https://gestion.innovatiio.com/api/uploads/entregas/${cellValues.row.adjunto}`} target="_blank">Ver</a>) : <></>
      //   } 
      // },
      {
        'field': 'fecha_planificacion', 
        'width':200, 
        'headerName': 'Fecha Planificación', 
        'description': 'Fecha Planificación',
        type: 'date',
        valueFormatter: params => {
          // console.log(params)
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {
        'field': 'fecha_prevista', 
        'width':200, 
        'headerName': 'Fecha Prevista', 
        'description': 'Fecha Prevista',
        type: 'date',
        valueFormatter: params => {
          // console.log(params)
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {     
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Eliminar"
            onClick={(e) => {
              // console.log(params)
              linkDelete(params.id)
            }}
            showInMenu
          />,
        ],
      }
    ]

    const linkDelete = (id) => {
        Swal.fire({
            title: '¿Realmente deseas eliminar la entrega de la plantilla?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                entregaPlantillaLineaDelete( { usuario_id: usuario_id, entrega_id: id} ).then((response) => {
                    
                  cargarPlantilla()                    

                })

            } else if (result.isDenied) {
            // //   Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }

    const ListadoEntregas = () => {
      const [openDialogEntregaForm, setOpenDialogEntregaForm] = useState(false)
      const [entregaDoubleClick, setEntregaDoubleClick] = useState()
      return (
        <Box sx={{marginX: 2, marginY: 0, marginBottom: 20}}>
          <Paper style={{padding: 20}}>
            <Box mb={2}>
              <Box mb={2}>
                <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                    Listado de entregas
                    <Button 
                      variant="outlined" 
                      color="primary" 
                      onClick={() => {
                        setEntregaDoubleClick(null)
                        setOpenDialogEntregaForm(true)
                      }}
                      style={{marginLeft: 10}}
                    >
                      Añadir entrega
                    </Button>
                </Typography>

                <Divider />
              </Box>

              {

                plantilla?.entregas && 
                
                <div style={{ display: 'flex', height: 700 }}>

                  <DataGridPro
                    pagination={false}
                    rows={plantilla?.entregas} 
                    columns={columnas} 
                    rowHeight={30}
                    onRowDoubleClick={ (row) => {
                      setEntregaDoubleClick(row.row)
                      setOpenDialogEntregaForm(true)
                    }}  
                  />

                </div>
              }

              <DialogEntregaForm 
                openModal={openDialogEntregaForm}
                handleOpenModal={ () => {
                  setOpenDialogEntregaForm(false);
                }}
                plantillaId={plantilla_id}
                setPlantilla={setPlantilla}
                entrega={entregaDoubleClick}
              />

            </Box>
          </Paper>
        </Box>
      )
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

      return (
          <>
            
            <FormPlantilla />

            <ListadoEntregas />

          </>
      )

    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
          {
            (
              
              loading 
              
              ?
                    
              <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
              :
                    
              <Contenido />
        
            )
          }
        </div>
    )
}
