import React, { useState } from "react";
// import dynamic from "next/dynamic";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from 'react-quill';
import { Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
// const QuillEditor = dynamic(() => import("react-quill"), { ssr: false });

/* ------------------------------ quillModules ------------------------------ */
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    //    ['link', 'image'],
    ["link"],
    [{ align: [] }],
    [{ color: [] }],
    //    ['code-block'],
    ["clean"],
  ],
};

/* ------------------------------ quillFormats ------------------------------ */
const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  //   'image',
  "align",
  "color",
  "code-block",
];

/* ---------------------------- cssAppliedContent --------------------------- */
const cssAppliedContent = (body) => `
    <div>
      <style>
        a { text-decoration: underline}
        ul { list-style: disc; margin-left: 20px; margin-top: 10px }
        ol { list-style: decimal; margin-left: 20px; margin-top: 10px}
      </style>
      ${body}
    <div>
    `;

/* ------------------------------ RichTextView ------------------------------ */
export const RichTextView = ({data}) => {
  return (
  <div
    dangerouslySetInnerHTML={{ __html: cssAppliedContent(data) }}
  />
  )
}

/* ------------------------------ RichTextField ----------------------------- */
export function RichTextField({
  getValues,
  setValue,
  errors,
}) {
  return (
    <div className="">
      <ReactQuill
        value={getValues("comentario")}
        onChange={(value) => setValue("comentario", value)}
        modules={quillModules}
        formats={quillFormats}
        style={{ height: "330px", marginBottom: 50, marginTop: 10 }}
        className=" bg-white"
      />
      {!!errors?.comentario && (
        <Typography style={{color: red[500], fontSize: 10}}  >
          {errors?.comentario?.message}
        </Typography>
      )}
    </div>
  );
}