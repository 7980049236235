import React, { useEffect } from 'react'
import { useState } from "react";
import LoadingComponent from '../../../components/Loading';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getLiquidaciones, marcarNotificacionLeida, notificacionesUsuario } from '../../../services/alumnos';
import { green, red } from '@material-ui/core/colors';
import moment from 'moment';
import Moment from 'react-moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DialogDudaLiquidacionForm from './DialogDudaLiquidacionForm';

export default function LiquidacionesContent() {
    // const classes = useStyles();

    const [liquidaciones, setLiquidaciones] = useState(false)
    const [facturas, setFacturas] = useState(false)
    const [loadingLiquidaciones, setLoadingLiquidaciones] = useState("")
    const [liquidacionSeleccionadaId, setLiquidacionSeleccionadaId] = useState(undefined)
    const [liquidacionSeleccionadaUUID, setLiquidacionSeleccionadaUUID] = useState(undefined)
    const [showDialogDuda, setShowDialogDuda] = useState(false)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()


    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {

        getLiquidaciones({usuario_id: usuarioSeleccionado.id, tutor_id: usuarioSeleccionado.id}).then( ( respose )=> {

            setLiquidaciones(respose?.liquidaciones)
            setFacturas(respose?.facturas)

        })

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnasLiquidaciones = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'periodos',headerName: 'Periodos', flex: 1, },
      {
        'field': 'duda_liquidacion', 
        'width':200, 
        'headerName': '', 
        'description': '',
        renderCell: (cellValues) => {
          return (
            <>
            <Button 
              variant='outlined' 
              color='primary'
              onClick={() => {
                setLiquidacionSeleccionadaId(cellValues.id)
                setLiquidacionSeleccionadaUUID(cellValues.row.uuid)
                setShowDialogDuda(true)
              }}
            >
              Pregunta tus dudas
            </Button>
            </>
          )
        }
      },
      {
        'field': 'imprimir_liquidacion', 
        'width':50, 
        'headerName': '', 
        'description': '',
        renderCell: (cellValues) => {
          return (
            <>
            <a href={`https://gestion.innovatiio.com/api/v1/alumno/tutor_liquidacion_pdf?id=${cellValues.row.id}`} target="_blank" rel="noreferrer">
              <PictureAsPdfIcon/>
            </a>
            </>
          )
        }
      },
    ]
    const columnasFacturas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'cantidad',headerName: 'Cantidad',  },
      { field: 'concepto',headerName: 'Concepto', flex: 1, },
      { field: 'precio',headerName: 'Precio',  },
      {
        'field': 'imprimir_liquidacion', 
        'width':50, 
        'headerName': '', 
        'description': '',
        renderCell: (cellValues) => {
          return (
            <>
            <a href={`https://gestion.innovatiio.com/api/v1/alumno/usuario_factura_pdf?id=${cellValues.row.id}`} target="_blank" rel="noreferrer">
              <PictureAsPdfIcon/>
            </a>
            </>
          )
        }
      },
    ]
    
    const handleCerrarDialogDudaLiquidacion = () => {
        setShowDialogDuda(false)
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        
        return (
          <>
            { liquidaciones.length > 0 && <Box>
                <TituloPagina titulo={"Liquidaciones de alumnos"} />

                <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                
                    <DataGridPro
                        rows={liquidaciones} 
                        columns={columnasLiquidaciones} 
                        rowHeight={45}
                    />

                </div>
            </Box>}
            
            { facturas.length > 0 && <Box>
                <TituloPagina titulo={"Otras liquidaciones"} />

                <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                
                    <DataGridPro
                        rows={facturas} 
                        columns={columnasFacturas} 
                        rowHeight={30}
                    />

                </div>
            </Box>}
              
            <DialogDudaLiquidacionForm 
              liquidacionId={liquidacionSeleccionadaId}
              liquidacionUUID={liquidacionSeleccionadaUUID}
              openModal={showDialogDuda}
              handleCerrarDialogDudaLiquidacion={handleCerrarDialogDudaLiquidacion}
            />

          </>
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div style={{marginTop: '80px'}}>
            {
                (loadingLiquidaciones ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingLiquidaciones} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
