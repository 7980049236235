import React, { useEffect, useState } from 'react'

import { Button, Grid, TextField, Tooltip, Typography } from '@material-ui/core'
import { alumnoTutorNotaInsert, getAlumnoTutorNotas } from '../../../services/alumnos'
import { useSelector } from 'react-redux'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { DataGrid } from '@mui/x-data-grid'
import { useFormik } from 'formik'
import * as yup from 'yup';
import moment from 'moment'
import Moment from 'react-moment'
import DialogNotaView from './DialogNotaView'

const AlumnoFormNotas = ({ alumnoID }) => {
    const [notasAlumno, setNotasAlumno] = useState("")
    const [notaSeleccionada, setNotaSeleccionada] = useState("")
    const [openModalNotaView, setOpenModalNotaView] = useState(false)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    /* -------------------------------------------------------------------------- */
    /*                                 Use Effect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {

        getAlumnoTutorNotas({usuario_id: usuarioSeleccionado.id, alumno_id: alumnoID}).then( (response) => {
            setNotasAlumno(response?.notas)
        })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alumnoID])
    
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    const [initialValues, setInitialValues] = useState({
        nota: '',
        // fecha_aviso:  null
    })
    const validationSchema = yup.object({
        
        // nombre: yup.string().required('Nombre es requerido'),
        // correo: yup.string().email().required('Correo es requerido'),
        
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            

            alumnoTutorNotaInsert({
                usuario_id: usuarioSeleccionado.id,
                alumno_id: alumnoID,
                nota: values.nota,
            }).then( (response) => {
                response && setNotasAlumno(response.notas)
                formik.setFieldValue('nota', "");
                formik.values.nota = ""
            })

        }
    })   
    /* -------------------------------------------------------------------------- */
    /*                                  columnas                                  */
    /* -------------------------------------------------------------------------- */
    const columnas_notas = [
        {
            'field': 'nota', 
            flex: 1,
            'headerName': 'Nota', 
            'description': 'Nota',
            renderCell: (cellValues) => {
                return (
                    <>
                    <Tooltip title={cellValues.value} >
                        <span className="table-cell-trucate">{cellValues.value}</span>
                    </Tooltip>
                    </>
                );
            } 
        },
        {
            'field': 'created', 
            'width':200, 
            'headerName': 'Fecha', 
            'description': 'Fecha',
            type: 'date',
            valueFormatter: params => {
              return moment(params?.value).format("DD/MM/YYYY hh:mm A")
            },
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY HH:mm:ss">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            } 
        },
    ]

    const handleRowDoubleClick = (fila) => {
        setNotaSeleccionada(fila)
        setOpenModalNotaView(true)
    }
    const handleCerrarDialogoNota = () => {
        setOpenModalNotaView(false)
    }
    /* -------------------------------------------------------------------------- */
    /*                                   return                                   */
    /* -------------------------------------------------------------------------- */
    return (

        <>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={ formik.handleSubmit }>
                <Grid container  spacing={2}>
                    <Grid item xs={12} md={12} style={{paddingBottom: 0}}>
                        <Typography variant='h6' >Notas internas (no se envían a los alumnos). Objetivo: Mantener un historico de las conversaciones con el alumno</Typography>
                    </Grid>

                    <Grid item xs={12} md={10}>

                        <TextField
                            id="nota"
                            name="nota"
                            label="Nota"
                            placeholder="Añade una nota"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={formik.values.nota}
                            onChange={formik.handleChange}
                            variant="outlined"
                            error={formik.touched.nota && Boolean(formik.errors.nota)}
                            helperText={formik.touched.nota && formik.errors.nota}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                    </Grid>

                    <Grid item xs={12} md={2}>

                            <Button 
                                fullWidth
                                variant="contained" 
                                color="primary" 
                                type="submit"
                                size='large'
                                style={{marginTop: '16px'}}
                            >
                                Guardar
                            </Button>

                    </Grid>

                </Grid>
            </form>

            <div style={{ height: 500, width: '100%', padding:"" }}>
                <DataGrid 
                    getRowHeight={() => 'auto'}
                    rows={notasAlumno} 
                    columns={columnas_notas} 
                    onRowDoubleClick={ (event) => handleRowDoubleClick(event.row)}
                />
            </div>

            <DialogNotaView 
                openModal={openModalNotaView} 
                handleCerrarDialogoNota={handleCerrarDialogoNota} 
                nota={notaSeleccionada} 
            />
            </>   
    )
}

export default AlumnoFormNotas
