import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { actualizarTutoriaEsBienvenida, actualizarTutoriaEstadoTutor, getTutoriasIndividuales } from '../../../services/alumnos';
import { Box, Chip,  Link, MenuItem, Select, Typography } from '@material-ui/core';
import { DataGridPro, useGridApiContext } from '@mui/x-data-grid-pro';
import { blue, green, grey, red } from '@material-ui/core/colors';
import { getListaDetalle } from '../../../services/lista_detalle';

// function escapeRegExp(value) {
//     return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
//   }

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function TutoriasIndividualesContent() {
    const classes = useStyles();

    const [tutorias, setTutorias] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [estadosTutor, setEstadosTutor] = React.useState(undefined)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    // // const [selectionModel, setSelectionModel] = React.useState([]);
    // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando tutorias")

        const usuario_id = usuarioSeleccionado.id

        // actualizarCalendly({ usuario_id: usuario_id }).then((response) => {
          getTutoriasIndividuales({ usuario_id: usuario_id }).then((response) => {
            setLoading(false)

            response && setTutorias(response.tutorias)
          });

          getListaDetalle({usuario_id: usuario_id, lista_id: 17}).then( (response) => {
            response.lista_detalle && setEstadosTutor(response.lista_detalle)
          })
        // });

    }, [])
    

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    function SelectEditInputCell(props) {
      const { id, value, field } = props;
      const apiRef = useGridApiContext();
    
      const handleChange = async (event) => {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        apiRef.current.stopCellEditMode({ id, field });

        if(field === 'estado_tutor') {
          actualizarTutoriaEstadoTutor({usuario_id: usuarioSeleccionado.id, tutoria_id: id, estado: event.target.value}).then( response => {
            
            getTutoriasIndividuales({ usuario_id: usuarioSeleccionado.id }).then((response) => {
              setLoading(false)
  
              response && setTutorias(response.tutorias)
            });

          })
        }
        if(field === 'es_bienvenida') {
          actualizarTutoriaEsBienvenida({usuario_id: usuarioSeleccionado.id, tutoria_id: id, es_bienvenida: event.target.value}).then( response => {
            
            getTutoriasIndividuales({ usuario_id: usuarioSeleccionado.id }).then((response) => {
              setLoading(false)
  
              response && setTutorias(response.tutorias)
            });

          })
        }
      };
    
      return (
        <>
        {field === 'estado_tutor' && 
          <Select
            sx={{ height: 1 }}
            fullWidth
            value={value}
            onChange={handleChange}
          >
            {        
                estadosTutor && estadosTutor.map( (item) => {
                    return (<MenuItem key={item.id} value={item.clave}>{item.nombre}</MenuItem>)
                })
            }

        </Select>}
        
        {field === 'es_bienvenida' && 
          <Select
            sx={{ height: 1 }}
            fullWidth
            value={value}
            onChange={handleChange}
          >
            <MenuItem key={1} value={'1'}>{'Si'}</MenuItem>
            <MenuItem key={2} value={'0'}>{'No'}</MenuItem>
        </Select>}

      </>
        // <Select
        //   value={value}
        //   onChange={handleChange}
        //   size="small"
        //   sx={{ height: 1 }}
        //   native
        //   autoFocus
        // >
        //   <option>Back-end Developer</option>
        //   <option>Front-end Developer</option>
        //   <option>UX Designer</option>
        // </Select>
      );
    }
    const renderSelectEditInputCell = (params) => {
      return <SelectEditInputCell {...params} />;
    };

    const columnas = 
    [
      { field: 'id', headerName: 'ID', minWidth: 10 },
      { 
        field: 'alumno_nombre', 
        headerName: 'Alumno', 
        minWidth: 350, 
        renderCell: (celda) => {
          if(celda.row.alumno_id) {
            return <><Link href={`/alumnos_tutor/detalle/${celda.row.alumno_id}`}>{celda.row.alumno_nombre } </Link>{celda.row.es_alumno_fast === '1' ? <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} /> : ''}</>
          } else {
            return <Box style={{color:'red'}}>[Sin alumno asignado. Avisar a Administración]</Box>
          }

        }
      },
      { 
        field: 'start_time', 
        headerName: 'Inicio',
        minWidth: 170,
        renderCell: (celda) => {
          return (
            <>
                {
                celda.row.tutorias_individuales_disponibles === "0" 
                ? 
                <Typography variant='body2' style={{ color: red[500]}}>{celda.row.start_time}</Typography> 
                : 
                <Typography variant='body2'>{celda.row.start_time}</Typography>
                }
            </>
          )
        }
    },
      { 
        field: 'end_time', 
        headerName: 'Fin',
        minWidth: 170,
        renderCell: (celda) => {
          return (
            <>
                {
                celda.row.tutorias_individuales_disponibles === "0" 
                ? 
                <Typography variant='body2' style={{ color: red[500]}}>{celda.row.end_time}</Typography> 
                : 
                <Typography variant='body2'>{celda.row.end_time}</Typography>
                }
            </>
          )
        } 
      },
      { 
        field: 'contador_tutorias', 
        headerName: 'Tutorias disponibles',
        minWidth: 180, 
        renderCell: (celda) => {
          return (
            <>
              { 
              celda.row.es_alumno_fast === '0' 
              ?
              <>
                {
                celda.row.tutorias_individuales_disponibles === "0" 
                ? 
                <Typography variant='body2' style={{ color: red[500]}}>{celda.row.tutorias_individuales_disponibles}</Typography> 
                : 
                <Typography variant='body2'>{celda.row.tutorias_individuales_disponibles}</Typography>
                }
              </>
               :
               <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
              }
            </>
          )
        }
      },
      { 
        field: 'estado', 
        headerName: 'Estado Calendly',
        minWidth: 200, 
        renderCell: (celda) => {
          return (
            <>
              { 
              celda.row.es_alumno_fast === '0' 
              ?
              <>
                {celda.row.estado === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
                {celda.row.estado === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
                {celda.row.estado === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
                {celda.row.estado === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
               </>
               :
               <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
              }
            </>
          )
        }
      },
      { 
        field: 'estado_tutor', 
        headerName: 'Validación tutor', 
        maxWidth: 350, 
        editable: true,
        type: 'singleSelect',
        renderEditCell: renderSelectEditInputCell,
        renderCell: (celda) => {
          return (
            <>
            { 
              celda.row.es_alumno_fast === '0' 
              ?
              <>
              { celda.row.estado_tutor === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
              { celda.row.estado_tutor === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
              { celda.row.estado_tutor === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
              { celda.row.estado_tutor === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
              </>
              :
               <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
              }
            </>
          )
        }
      },
      { 
        field: 'es_bienvenida', 
        headerName: '¿Sesion bienvenida?', 
        maxWidth: 300, 
        editable: true,
        type: 'singleSelect',
        
        renderEditCell: renderSelectEditInputCell,
        renderCell: (celda) => {
          return (
            <>
            { 
              celda.row.es_alumno_fast === '0' 
              ?
              celda.row.es_bienvenida === '1' ? 'Si' : 'No' 
              :
               <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
              }
            </>
          )
        }
    },
      { field: 'zoom_link', headerName: 'Enlace Zoom',minWidth: 400,
      renderCell: (celda) => {
        return (
          <>
            { 
              celda.row.es_alumno_fast === '0' 
              ?
              <>{celda.row.zoom_link}</>
              :
              <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
            }
          </>
        )
    }},
      { field: 'zoom_password', headerName: 'Password Zoom',minWidth: 220,renderCell: (celda) => {
        return (
          <>
            { 
              celda.row.es_alumno_fast === '0' 
              ?
              <>{celda.row.zoom_password}</>
              :
              <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
            }
          </>
        )
    }},
      { 
        field: 'canceler_type', 
        headerName: 'Canceldo por',
        minWidth: 220,
        renderCell: (celda) => {
          if(celda.row.canceler_type === '') {
            return ''
          }
          return (
            <>
              { 
                celda.row.es_alumno_fast === '0' 
                ?
                <>{celda.row.canceler_type === 'host' ? 'Tutor' : 'Alumno'}</>
                :
                <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
              }
            </>
          )
          
        }
      },
      { field: 'question', headerName: 'Pregunta',minWidth: 220, flex: 1,
      renderCell: (celda) => {
        if(celda.row.canceler_type === '') {
          return ''
        }
        return (
          <>
            { 
              celda.row.es_alumno_fast === '0' 
              ?
              <>{celda.row.question}</>
              :
              <Chip size="small" variant='outlined' style={{marginLeft: 5, color: grey[700]}}  label={"Preparación FAST"} />
            }
          </>
        )
        
      }
    },
    ]

    
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {
        return (
            tutorias ?
                <>
                     <TituloPagina titulo={"Tutorias"} />

                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridPro
                              rows={tutorias} 
                              columns={columnas} 
                              rowHeight={30}

                              onProcessRowUpdateError={ () => {} }
                              processRowUpdate={ (param) => {
                                console.log(param)
                              //   if(param.field === 'estado_tutor'){
                              //     console.log(param)
                              //     actualizarTutoriaEstadoTutor({usuario_id: usuarioSeleccionado.id, tutoria_id: param.id, estado: param.value}).then( response => {
                              //       console.log(response)
                              //     })
                              //   }
                              }}
                          />
                      </div>
                    </Box>
                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
