import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import NotificacionesContent from './components/NotificacionesContent';

const Notificaciones = () => {

    return (
        <>

            <TemplateDashboard>
                <NotificacionesContent />
            </TemplateDashboard>

        </>
    )
};

export default Notificaciones;