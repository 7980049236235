import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from '../../components/TemplateDashboard';
import BandejaDeEntradaContent from './components/BandejaDeEntradaContent';

const BandejaDeEntrada = () => {

    return (
        <>

            <TemplateDashboard>
                <BandejaDeEntradaContent />
            </TemplateDashboard>

        </>
    )
};

export default BandejaDeEntrada;