import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from "react-redux";


// import EmptyComponent from '../../../../components/EmptyContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { getAlumnosCoordinador, getDifusionDestinosAdministracion, getEntregasPlantillasByTutor } from '../../../services/alumnos';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
// import { Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton, esES} from '@mui/x-data-grid';
import { AppBar, Box, Button, Chip, Grid, IconButton, Link, Tab, Tabs, TextField, Typography } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import SyncIcon from '@material-ui/icons/Sync';
// import Swal from 'sweetalert2';
// import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// import PropTypes from 'prop-types';
// import TituloPagina from '../../../../components/Titulos/TituloPagina';
// import AddIcon from '@material-ui/icons/Add';
// import { getAlumnosTutor } from '../../../../services/alumnos';
// import { Stack } from '@mui/system';
// import Moment from 'react-moment';
// import { DataGridPro, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import DataGridInnovattio from '../../../components/DataGridInnovatiio';
import { Stack } from '@mui/material';
import DialogDifusionForm from './DialogDifusionForm';
// import moment from 'moment/moment';
// import { amber, blue, green, red, yellow } from '@material-ui/core/colors';


// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
}));

export default function AdminDifusionContent() {
    const classes = useStyles();

    const [grupos, setGrupos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [difusionesHistorico, setDifusionesHistorico] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        getDifusionDestinosAdministracion({ usuario_id: usuarioSeleccionado.id}).then( (response) => {
          response && setDifusionesHistorico(response.difusiones)
          console.log(response.difusiones)
        })

        getEntregasPlantillasByTutor({ tutor_id:'-1' }).then((response) => {
          setLoading(false)

          response && setGrupos(response.plantillas)

        });


    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'descripcion', headerName: 'Grupo',minWidth: 10, flex: 1 },
    ]
    const columnasHistorico = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'asunto', headerName: 'Asunto',minWidth: 10, flex: 1 },
    ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
    }
      
    const Contenido = () => {
        const [rows, setRows] = React.useState(grupos);
        const [selection, setSelection] = React.useState([]);
        const [openDifusionModal, setOpenDifusionModal] = React.useState(false);

        const handleCerrarDialogoDifusion = () => {
            setOpenDifusionModal(false)
        }
      
        return (
            rows ?
                <>
                  <Stack direction={'row'} spacing={2}>
              
                      <Box mt={0} mb={0} mx={2}>
                          <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                              Difusiones a grupos
                          </Typography>
                      </Box>            
                      { selection.length > 0 && <Button variant="outlined" color="primary" onClick={() => setOpenDifusionModal(true)}>Nueva Difusión</Button>}
                  
                  </Stack>

                    { selection.length > 0 && <Button value={'Boton'} />}
                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridInnovattio
                              rows={rows} 
                              columns={columnas} 
                              rowHeight={30}
                              components={{ Toolbar: QuickSearchToolbar }}
                              checkboxSelection={true}
                              pagination={false}
                              disableSelectionOnClick
                              disableDensitySelector
                              onStateChange={ (item) => setSelection(item.rowSelection) }
                              localeText={esES.components.MuiDataGrid.defaultProps.localeText} 
                          />
                      </div>
                    </Box>
                    

                    <Box mt={10} mb={0} mx={2}>
                        <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                            Difusiones enviadas
                        </Typography>
                    </Box>  
                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridInnovattio
                              rows={difusionesHistorico} 
                              columns={columnasHistorico} 
                              rowHeight={30}
                              disableSelectionOnClick
                              disableDensitySelector
                          />
                      </div>
                    </Box>



                    <DialogDifusionForm
                        openModal={openDifusionModal}
                        handleCerrarDialorNotificacion={handleCerrarDialogoDifusion}
                        gruposSeleccionados={selection}
                    />
                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
