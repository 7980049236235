import React from 'react';

// import { useSelector, useDispatch } from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Link,
} from 'react-router-dom';


import { SECONDARY_COLOR, SECONDARY_COLOR_HOVER } from '../../constants/colores'

import NavbarTop from "./../NavbarTop";

import logos_camara from '../../assets/img/logos_camara.png'
import { Alert } from '@mui/material';
import { Box } from '@material-ui/core';

// import ContentTitle from "./../ContentTitle";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fab2: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(17),
    },
    fabColor: {
        color: theme.palette.common.white,
        backgroundColor: SECONDARY_COLOR,
        '&:hover': {
            backgroundColor: SECONDARY_COLOR_HOVER,
        },
    },
}));

const TemplateDashboard = (props) => {
    const { title, showAdd = false, showBack = false, propiedadId } = props;
    const classes = useStyles();

    return (

        <>

            {/* <!-- navbar navigation component --> */}
            <NavbarTop
                title={title}
                showBack={showBack}
            />
            {/* <!-- end of navbar navigation --> */}

            <div className="wrapper" style={{ marginTop: 80 }}>

                <div id="body" >

                    <div className="content">
                        <div className="container">

                            {/* <ContentTitle pretitle={pretitle} title={title} /> */}

                            {props.children}

                        </div>
                    </div>

                </div>
            </div>

            {showAdd &&
                <>
                    <Fab aria-label="add" variant="extended"
                    size="small" className={clsx(classes.fab, classes.fabColor)} component={Link} to={`/gastos/add_factura`}>
                        <AddIcon /> Factura
                    </Fab>
                    <Fab aria-label="add" variant="extended"
                    size="small" className={clsx(classes.fab2, classes.fabColor)} component={Link} to={`/gastos/add_manual`}>
                        <AddIcon /> Manual
                    </Fab>
                </>
            }
        </>
    );
}

export default TemplateDashboard;