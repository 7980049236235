import React, { useEffect } from 'react'
import { useState } from "react";
import LoadingComponent from '../../../components/Loading';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DialogNotificacionForm from './DialogNotificacionForm';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { marcarNotificacionLeida, notificacionesUsuario } from '../../../services/alumnos';
import { green, red } from '@material-ui/core/colors';
import moment from 'moment';
import Moment from 'react-moment';
import DialogNotificacionView from './DialogNotificacionView';

// import { useSelector } from "react-redux";


// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import EmptyComponent from '../../../components/EmptyContent';
// import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
// import { GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
// import { Box, Chip, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
// import ClearIcon from '@material-ui/icons/Clear';
// import SearchIcon from '@material-ui/icons/Search';
// import DataGridInnovattio from '../../../components/DataGridInnovatiio';
// import { amber, blue, green, red } from '@material-ui/core/colors';
// import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
// import StatusTutor from '../../../components/StatusTutor';
// import StatusAlumno from '../../../components/StatusAlumno';

// function escapeRegExp(value) {
//     return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
// }

export default function NotificacionesContent() {
    // const classes = useStyles();

    // const [alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [notificacionesRecibidas, setNotificacionesRecibidas] = useState([])
    const [notificacionesEnviadas, setNotificacionesEnviadas] = useState([])
    // const [conversaciones, setConversaciones] = useState([])
    const [openNotificacionModal, setOpenNotificacionModal] = useState(false)
    const [openNotificacionViewModal, setOpenNotificacionViewModal] = useState(false)
    const [notificacionDoubleClick, setNotificacionDoubleClick] = useState(false)
    
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()


    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {

        notificacionesUsuario({usuario_id: usuarioSeleccionado.id}).then( ( respose )=> {

            setNotificacionesEnviadas(respose?.notificaciones_enviadas)
            setNotificacionesRecibidas(respose?.notificaciones_recibidas)

        })

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnasEnviadas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'descripcion',headerName: 'Descripcion', flex: 1, },
      {
        'field': 'created', 
        'width':200, 
        'headerName': 'Enviada', 
        'description': 'Enviada',
        type: 'date',
        valueFormatter: params => {
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY HH:mm:ss">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
    },
    ]
    const columnasRecibidas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'descripcion',headerName: 'Descripcion', flex: 1, },
      { field: 'origen_usuario_nombre',headerName: 'De', flex: 1, },
      {
        'field': 'created', 
        'width':200, 
        'headerName': 'Recibida', 
        'description': 'Recibida',
        type: 'date',
        valueFormatter: params => {
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY HH:mm:ss">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      { field: 'leido',headerName: 'Leido', 
      renderCell: (cellValues) => {
        return (

            <>
                {
                cellValues.value === '1' 
                ? 
                <Button onClick={ () => handleMarcarLeido(cellValues.row.id)}><Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label='Leido' /></Button>
                :
                <Button onClick={ () => handleMarcarLeido(cellValues.row.id)}><Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label='Pendiente' /></Button>
                }
            </>
            
        );
      } 
    },
      
    ]

    const handleMarcarLeido = (id) => {
        marcarNotificacionLeida({usuario_id: usuarioSeleccionado.id, id: id}).then( (response) => {
            setNotificacionesRecibidas(response?.notificaciones_recibidas)
        })
    }
    const handleCerrarDialorNotificacion = () => {
        setOpenNotificacionModal(false)

        notificacionesUsuario({usuario_id: usuarioSeleccionado.id}).then( ( respose )=> {
            setNotificacionesEnviadas(respose?.notificaciones_enviadas)
            setNotificacionesRecibidas(respose?.notificaciones_recibidas)
        })
    }
    const handleCerrarDialogoNotificacionView = () => {
        setOpenNotificacionViewModal(false)
    }

    const handleDialogResponder = () => {
        console.log(notificacionDoubleClick)
        setOpenNotificacionViewModal(false)

    }
    const handleNotificacionView = (fila) => {
        setNotificacionDoubleClick(fila)
        setOpenNotificacionViewModal(true)
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        
                return (
          <>
            <Stack direction={'row'} spacing={2}>
            
                <Box mt={0} mb={0} mx={2}>
                    <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                        Notificaciones
                    </Typography>
                </Box>            
                <Button variant="outlined" color="primary" onClick={() => setOpenNotificacionModal(true)}>Nueva notificación</Button>
            
            </Stack>
        
            <Box mt={0} mb={0} mx={2}>
                <Typography variant="h6" component="h4" gutterBottom style={{fontWeight: 500}} >
                    Recibidas
                </Typography>
            </Box> 
            <Box>
                <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                
                    <DataGridPro
                        rows={notificacionesRecibidas} 
                        columns={columnasRecibidas} 
                        rowHeight={30}
                        // getRowHeight={() => 'auto'}
                        onRowDoubleClick={ (evento ) => handleNotificacionView(evento.row) }
                    />

                </div>
            </Box>
              
            <Box mt={2} mb={0} mx={2}>
                <Typography variant="h6" component="h4" gutterBottom style={{fontWeight: 500}} >
                    Enviadas
                </Typography>
            </Box> 
            <Box>
                <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                
                    <DataGridPro
                        rows={notificacionesEnviadas} 
                        columns={columnasEnviadas} 
                        rowHeight={30}
                        onRowDoubleClick={ (evento ) => handleNotificacionView(evento.row) }
                        // onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                        // componentsProps={{
                        //     toolbar: {
                        //       value: searchText,
                        //       onChange: (event) => requestSearch(event.target.value),
                        //       clearSearch: () => requestSearch(''),
                        //     },
                        // }}
                    />
                </div>
            </Box>
            
            <DialogNotificacionForm 
                openModal={openNotificacionModal} 
                handleCerrarDialorNotificacion={handleCerrarDialorNotificacion} 
            />
            <DialogNotificacionView 
                openModal={openNotificacionViewModal} 
                handleCerrarDialorNotificacion={handleCerrarDialogoNotificacionView} 
                notificacion={notificacionDoubleClick} 
                handleDialogResponder={handleDialogResponder} 
            />

          </>
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div style={{marginTop: '80px'}}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
