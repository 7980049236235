import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getAlumnosTickets, ticketToConversaion } from '../../../services/alumnos';
import { Box, Chip, Grid, Link, Typography } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import moment from 'moment';
import Moment from 'react-moment';
import StatusTicket from '../../../components/StatusTicket';
import { BASE_URL_ZENDESK, MODO_DEV } from '../../../constants';
import { blue, green, red } from '@material-ui/core/colors';
import Swal from 'sweetalert2';

const AlumnoFormTickets = ({ alumno_id }) => {
  const [loading, setLoading] = useState(false)
  const [tickets, setTickets] = useState(undefined)

  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
        
    setLoading(true);

    // cargarEntregas()
    cargarTickets()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alumno_id])

  const cargarTickets = () => {
    const usuario_id = usuarioSeleccionado.id

    alumno_id && getAlumnosTickets({alumno_id, usuario_id}).then( (response) => {

        response.tickets && setTickets(response.tickets)

    })
  }

  /* -------------------------------------------------------------------------- */
    /*                                  Columnas                                  */
    /* -------------------------------------------------------------------------- */
    const handleGetTicketInfo = (origen_id, ticket_id) => {
      const usuario_id = usuarioSeleccionado.id

      alumno_id && ticketToConversaion({alumno_id: alumno_id, usuario_id: usuario_id, tipo: 'dudas', origen_id: origen_id, ticket_id: ticket_id}).then( (response) => {
        alumno_id && getAlumnosTickets({alumno_id, usuario_id}).then( (response) => {

          response.tickets && setTickets(response.tickets)
          Swal.fire("Información del ticket actualizada")
  
        })
      })
    }

    const columnas_tickets = [
      { field: 'id', headerName: 'ID', hide: true },
      { field: 'asunto', headerName: 'Asunto', minWidth: 150, flex: 1 },
      {
        'field': 'created', 
        'width':150, 
        'headerName': 'Fecha', 
        'description': 'Fecha',
        type: 'date',
        valueFormatter: params => {
          return moment(params?.value).format("DD/MM/YYYY hh:mm A")
        },
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {
          'field': 'fecha_resuelto', 
          'width':200, 
          'headerName': 'Resuelto en', 
          'description': 'Fecha de resolución',
          type: 'date',
          valueFormatter: params => {
            return moment(params?.value).format("DD/MM/YYYY hh:mm A")
          },
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY HH:mm:ss">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
      },
      { 
          field: 'status', 
          headerName: 'Estado', 
          minWidth: 300,
          renderCell: (cellValues) => {
              return <StatusTicket tipo='Duda' esRetrasado={cellValues.row.es_retrasada} ticketStatus={cellValues.value} />
          }
          // renderCell: (cellValues) => <StatusTicket ticketStatus={cellValues.row.status} />
      },
      {
          'field': 'ticket_id', 
          'width':200, 
          'headerName': 'Conversación', 
          'description': 'Fecha de resolución',
          type: 'date',
          valueFormatter: params => {
            return moment(params?.value).format("DD/MM/YYYY hh:mm A")
          },
          renderCell: (cellValues) => {
              if(cellValues.row.ticket_id !== '0') {
                  return (
                    <>
                      {/* {!MODO_DEV ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${BASE_URL_ZENDESK}agent/tickets/${cellValues.row.ticket_id}`}
                        >
                          Abrir
                        </a>
                      ) : (
                        <>
                        <a href={`/alumnos_tutor/conversacion/${alumno_id}/Duda/${cellValues.row.id}`}>Abrir</a>
                        </>
                      )} */}
                      <a href={`/alumnos_tutor/conversacion/${alumno_id}/Duda/${cellValues.row.id}`}>Abrir</a>
                    </>
                  );
              } else {
                  return <></>
              }
          } 
      },
      {
          'field': 'ticket2_id', 
          hide: true,
          'width':200, 
          'headerName': '...', 
          'description': '...',
          type: 'date',
          valueFormatter: params => {
            return moment(params?.value).format("DD/MM/YYYY hh:mm A")
          },
          renderCell: (cellValues) => {
              if(cellValues.row.ticket_id !== '0') {
                  return (
                    <>
                        <>
                          <Link onClick={() => handleGetTicketInfo(cellValues.row.id, cellValues.row.ticket_id)}>[Obtener Ticket] </Link>
                          <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${BASE_URL_ZENDESK}agent/tickets/${cellValues.row.ticket_id}`}
                        >
                          Zendesk
                        </a>
                        </>
                    </>
                  );
              } else {
                  return <></>
              }
          } 
      },
      
  ];

  return (
    <>
      <Grid container  spacing={2} style={{marginBottom: 10}}>
        <Grid item xs={12} md={12}>
            <Typography display="inline" variant='h6'>Dudas</Typography>

            {/* <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClickOpciones}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="long-menu"
                anchorEl={anchorElOpciones}
                keepMounted
                open={open}
                onClose={handleCloseOpciones}
            >

                <MenuItem key="opcion1"  onClick={() => {
                    setEntregaDoubleClick(undefined)
                    handleCloseOpciones()
                    setOpenModalCreate(true)
                }}>
                    Añadir entrega personalizada
                </MenuItem>

            </Menu> */}
         </Grid>
      </Grid>

      <Grid container  spacing={2}>

          <div style={{ height: 500, width: '100%', padding:"0 5px" }}>

              {tickets && <DataGridPro 
                  rows={tickets} 
                  columns={columnas_tickets} 
                  pagination={false}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        ticket2_id: false,
                      },
                    },
                  }}
                  // onRowDoubleClick={ (row) => { handleDoubleClick(row.row) } }
              />}

          </div>
          
      </Grid>
      <Grid container  spacing={2} style={{marginTop: 20, marginLeft: 5}}>
          <Box mr={5}><Chip size="small" style={{backgroundColor: red[500], color: 'white', marginRight: 10}} label={"Abierto"} /> Pendiente de resolución por parte del tutor</Box>
          <Box mr={5}><Chip size="small" style={{backgroundColor: blue[500], color: 'white', marginRight: 10}} label={"Pendiente"} /> Espera respuesta del alumno</Box>
          <Box mr={5}><Chip size="small" style={{backgroundColor: green[500], color: 'white', marginRight: 10}} label={"Resuelto"} /> Ticket resuelto</Box>
      </Grid>
    </>
  )
}

export default AlumnoFormTickets
