import { Box, Chip, Grid, Typography } from '@material-ui/core'
import { amber, blue, green, red } from '@material-ui/core/colors'
import React from 'react'

const LeyendaEstadosTutor = () => {
  return (
    <>
        <Grid container  spacing={2} style={{marginTop: 60, marginLeft: 15}}>
      <table>
        {/* <tr>
          <td style={{paddingRight: 20}}><Typography variant='body1'  style={{margintLeft: 15, marginRight: 10, fontWeight: 600}}>Estados tutor</Typography></td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: green[500], color: 'white', marginRight: 10}} label={"Al día"} /> Al día con las entregas</td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: red[500], color: 'white', marginRight: 10}} label={"Abierto"} /> Tiene entregas abiertas</td>
          <td style={{paddingRight: 20}}></td>
          <td style={{paddingRight: 20}}></td>
        </tr> */}
        <tr>
          <td style={{paddingRight: 20}}><Typography variant='body1'  style={{margintLeft: 15, marginRight: 10, fontWeight: 600}}>Estados entregas alumno</Typography></td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: green[500], color: 'white', marginRight: 10}} label={"Res"} /> Resuelto</td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: red[500], color: 'white', marginRight: 10}} label={"Abi"} /> Abierto (El tutor tiene que hacer algo)</td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: blue[500], color: 'white', marginRight: 10}} label={"Pen"} /> Pendiente (El alumno tiene que hacer algo)</td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: amber[500], color: 'white', marginRight: 10}} label={"Ret"} /> Retrasado</td>
        </tr>
        {/* <tr>
          <td style={{paddingRight: 20}}><Typography variant='body1'  style={{margintLeft: 15, marginRight: 10, fontWeight: 600}}>Estados entregas alumno</Typography></td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: green[500], color: 'white', marginRight: 10}} label={"Al día"} /> Al día con las entregas</td>
          <td style={{paddingRight: 20}}></td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: blue[500], color: 'white', marginRight: 10}} label={"Pendientes"} /> Entregas pendientes (Espera respuesta del alumno)</td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: amber[500], color: 'white', marginRight: 10}} label={"Retrasadas"} />Pendientes retrasadas</td>
        </tr>
        <tr>
          <td style={{paddingRight: 20}}><Typography variant='body1'  style={{margintLeft: 15, marginRight: 10, fontWeight: 600}}>Estados dudas</Typography></td>
          <td style={{paddingRight: 20}}></td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: red[500], color: 'white', marginRight: 10}} label={"Abierto"} /> Dudas abiertas abiertas (El alumno ha preguntado algo)</td>
          <td style={{paddingRight: 20}}><Chip size="small" style={{backgroundColor: blue[500], color: 'white', marginRight: 10}} label={"Pendientes"} /> Dudas pendientes (Espera respuesta del alumno)</td>
          <td style={{paddingRight: 20}}></td>
        </tr> */}
      </table>
            
        </Grid>
    </>
  )
}

export default LeyendaEstadosTutor
