import React, { useEffect, useState } from 'react'
import LoadingComponent from '../../../../components/Loading'
import { getMatrizEstados } from '../../../../services/alumnos'
import { useSelector } from 'react-redux'
import { selectUsuarioSeleccionado } from '../../../../redux/reducer/loginReducer'
import { Box, Button, Chip, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition'
import TituloPagina from '../../../../components/Titulos/TituloPagina'
import StatusTicket from '../../../../components/StatusTicket'
import { Stack } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import DialogDifusionForm from './DialogDifusionForm'

const MatrizSimulacrosContent = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false)
  const [loadingMensaje, setLoadingMensaje] = useState("")
  const [plantillas, setPlantillas] = useState([])
  const [openDialogoDifusion, setOpenDialogoDifusion] = useState(false)
  const [plantillaSeleccionadaId, setPlantillaSeleccionadaId] = useState()

  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
    setLoading(true)
    setLoadingMensaje("Cargando plantillas")

    getMatrizEstados({ usuario_id: usuarioSeleccionado.id, tutor_id: usuarioSeleccionado.id }).then((response) => {
      setPlantillas( Object.values(response?.plantillas) )

      setLoading(false)

  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleCerrarModalDifusion = () => {
    setOpenDialogoDifusion(false)
  }
  const handleEnviarDifusion = (id) => {
    setPlantillaSeleccionadaId(id)
    setOpenDialogoDifusion(true)      
  }

  const Contenido = () => {
  
    return (
      <>
        <TituloPagina titulo={'Matriz de simulacros'} />

        <Box mx={2}>
        
          {plantillas && plantillas.map( ( plantilla ) => {

            return (
              <>
              <Stack direction='row' spacing={2} mt={5} mb={2}>
                  <Typography variant="h6" component="h6" gutterBottom style={{fontWeight: 500, marginRight: 10}} >Grupo:</Typography>
                  <Typography variant="h6" component="h6" gutterBottom style={{fontWeight: 300}} >{plantilla.entregas_plantilla_id !== '0' ? plantilla.entregas_plantilla_descripcion : 'Sin grupo asignado'}</Typography>
                  <Button size='small' variant="outlined" endIcon={<SendIcon />} onClick={ () => handleEnviarDifusion(plantilla.entregas_plantilla_id) }>
                    Enviar difusión
                  </Button>
                </Stack>

                <TableContainer component={Paper} >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Alumno</TableCell>
                        <TableCell colSpan={plantilla?.alumnos[0].simulacros.length} align="center">Simulacros</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {plantilla?.alumnos?.map((alumno) => {
                        return (
                          <TableRow key={alumno.nombre}>
                            <TableCell component="th" scope="row" width="300">
                              <Link href={`/alumnos_tutor/detalle/${alumno.id}`}>{alumno.nombre }</Link>
                            </TableCell>

                                { alumno.simulacros.map( (entrega) => { return (
                                    <>
                                      <TableCell align="left">
                                        {entrega.abreviatura}: <StatusTicket tipo='Simulacro' esRetrasado={entrega?.es_retrasada} ticketStatus={entrega?.ticket_status} />
                                      </TableCell>
                                    </>
                                  )} 
                                )}
                                
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <DialogDifusionForm
                  plantillaId={plantillaSeleccionadaId}
                  openModal={openDialogoDifusion}
                  handleCerrarDialorNotificacion={handleCerrarModalDifusion}
                />
              </>
            )
          } 
        )}
        </Box>
      </>
    )
  }

  return (
          loading ?
                    
          <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
          :
                    
          <Contenido />
  )
}

export default MatrizSimulacrosContent
