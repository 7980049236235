import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import EmptyComponent from '../../../components/EmptyContent';
import LoadingComponent from '../../../components/Loading';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { getExposicionesOralesAlumnos, getExposicionesOralesCalendly, } from '../../../services/alumnos';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import { Box, Button, Chip, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
import StatusTutor from '../../../components/StatusTutor';
import StatusEntregasAlumno from '../../../components/StatusEntregasAlumno';
import StatusTicketsAlumno from '../../../components/StatusTicketsAlumno';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { blue, green, red } from '@material-ui/core/colors';
import Swal from 'sweetalert2';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import DialogExposicionOralEntradaManual from './DialogExposicionOralEntradaManual';
import DialogExposicionUrlGrabacion from './DialogExposicionOralUrlGrabacion';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function ExposicionesOralesContent() {
    const classes = useStyles();

    const [exposicionesOralesReservaSala, setExposicionesOralesReservaSala] = useState([]);
    const [exposicionesOralesComprasAlumnos, setExposicionesOralesComprasAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [openDialogEntradaManual, setOpenDialogEntradaManual] = useState(false)
    const [openDialogUrlGrabacion, setOpenDialogUrlGrabacion] = useState(false)
    const [filaSeleccionada, setFilaSeleccionada] = useState([])
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
//     // const history = useHistory()

//     // // const [selectionModel, setSelectionModel] = React.useState([]);
//     // // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

//     // // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando tutorias")

        const usuario_id = usuarioSeleccionado.id

        getExposicionesOralesCalendly({ usuario_id: usuario_id }).then((response) => {
          setLoading(false)

          response && setExposicionesOralesReservaSala(response.exposiciones_orales)
        });
        getExposicionesOralesAlumnos({ usuario_id: usuario_id }).then((response) => {
          setLoading(false)

          response && setExposicionesOralesComprasAlumnos(response.exposiciones_orales)
        });

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                                   Handler                                  */
    /* -------------------------------------------------------------------------- */
    const handleOpenDialogEntradaManual = (value) => {
        setOpenDialogEntradaManual(value)

        if(value === false) {
          console.log("Actualizo")
          const usuario_id = usuarioSeleccionado.id

          getExposicionesOralesAlumnos({ usuario_id: usuario_id }).then((response) => {
            response && setExposicionesOralesComprasAlumnos(response.exposiciones_orales)
          });
        }
    }
    const handleOpenDialogUrlGrabacion = (value) => {
        setOpenDialogUrlGrabacion(value)

        if(value === false) {
          const usuario_id = usuarioSeleccionado.id

          getExposicionesOralesAlumnos({ usuario_id: usuario_id }).then((response) => {
            response && setExposicionesOralesComprasAlumnos(response.exposiciones_orales)
          });
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnasReservaSala = 
    usuarioSeleccionado.perfil_id === "12" ? // Admin
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { 
        field: 'calendly_user_name', 
        headerName: 'Sala', 
        minWidth: 250, 
        renderCell: (celda) => {
            return <>[{celda.row.calendly_user_name }][{celda.row.calendly_user_email}]</>

        }
      },
      { 
        field: 'invitado', 
        headerName: 'Tutor', 
        minWidth: 350, 
        renderCell: (celda) => {
            return <>{celda.row.invitado }</>
        }
      },
      { field: 'start_time', headerName: 'Inicio',minWidth: 170},
      { 
        field: 'estado', 
        headerName: 'Estado',
        minWidth: 130, 
        renderCell: (celda) => {
          return (
            <>
              { celda.row.estado === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
              { celda.row.estado === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
              { celda.row.estado === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
              { celda.row.estado === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
            </>
          )
        }
      },
      { 
        field: 'zoom_link', 
        headerName: 'Enlace Zoom',
        minWidth: 400, 
        flex: 1,
        renderCell: (cell) => {
          return <a href={cell.value} target='_blank' rel="noreferrer">{cell.value}</a>
        }
      },
      { 
        field: 'alumno_nombre', 
        headerName: 'Alumno',
        minWidth: 400, 
        flex: 1,
        renderCell: (cell) => {
          return cell.value
        }
      }
    ]
    :
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { 
        field: 'calendly_user_name', 
        headerName: 'Sala', 
        minWidth: 250, 
        renderCell: (celda) => {
            return <>[{celda.row.calendly_user_name }][{celda.row.calendly_user_email}]</>

        }
      },
      { field: 'start_time', headerName: 'Inicio',minWidth: 170},
      { 
        field: 'estado', 
        headerName: 'Estado',
        minWidth: 130, 
        renderCell: (celda) => {
          return (
            <>
              { celda.row.estado === 'active' && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Activa"} /> }
              { celda.row.estado === 'canceled' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"Cancelada"} /> }
              { celda.row.estado === 'no_show' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={"No presentad@"} /> }
              { celda.row.estado === 'done' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={"Realizada"} /> }
            </>
          )
        }
      },
      { 
        field: 'zoom_link', 
        headerName: 'Enlace Zoom',
        minWidth: 400, 
        flex: 1,
        renderCell: (cell) => {
          return <a href={cell.value} target='_blank' rel="noreferrer">{cell.value}</a>
        }
      },
      { 
        field: 'alumno_nombre', 
        headerName: 'Alumno',
        minWidth: 400, 
        flex: 1,
        renderCell: (cell) => {
          return cell.value
        }
      }
    ]

    const columnasCompras =
      usuarioSeleccionado.perfil_id === "12" // Admin
        ? [
            { field: "id", headerName: "Exposición ID", maxWidth: 100 },
            {
              field: "alumno_id",
              headerName: "Alumno ID",
              minWidth: 50,
            },
            {
              field: "alumno_nombre",
              headerName: "Alumno",
              minWidth: 250,
              flex: 1,
            },
            { field: "oposicion_tipo", headerName: "Tipo", minWidth: 120 },
            {
              field: "producto_es_reembolsable",
              headerName: "Reembolsable",
              minWidth: 120,
              renderCell: (fila) => {
                return fila.value === "1" ? "SI" : "NO";
              },
            },
            { field: "sede_nombre", headerName: "Sede", minWidth: 150 },
            { field: "modalidad", headerName: "Modalidad", minWidth: 150 },
            {
              field: "grupo_nombre",
              headerName: "Grupo",
              minWidth: 150,
              flex: 1,
            },
            {
              field: "comunidad_autonoma_nombre",
              headerName: "Comunidad",
              minWidth: 200,
            },
            {
              field: "exposicion_tutor_nombre",
              headerName: "Tutor exposición",
              minWidth: 250,
            },
            { field: "fecha_exposicion", headerName: "Fecha", minWidth: 250 },
            {
              field: "calendly_uuid",
              headerName: "Calendly",
              minWidth: 120,
              renderCell: (fila) => {
                return fila.value !== null ? "SI" : "NO";
              },
            },
            { field: "zoom_link", headerName: "Zoom", minWidth: 350 },
            { field: "zoom_mail", headerName: "Sala", minWidth: 350 }, 
            { field: "url_grabacion", headerName: "URL Grabacion", minWidth: 350 }, 
            {
              field: 'actions',
              type: 'actions',
              width: 80,
              getActions: (params) => [
                <GridActionsCellItem
                  // icon={<EditIcon />}
                  label="Asignar URL Grabacion"
                  onClick={ () => { 
                    setFilaSeleccionada(params.row)
                    handleOpenDialogUrlGrabacion(true)
                      // handleCambiarFechas(params.row) }
                  }}
                  // component={Link}
                  // to={`alumnos/detalle/${params.id}`}
                  showInMenu
                />,
              ],
            }
          ]
        : [
          { field: "id", headerName: "ID" },
          {
            field: "alumno_nombre",
            headerName: "Alumno",
            minWidth: 250,
            flex: 1,
          },
          { field: "oposicion_tipo", headerName: "Tipo", minWidth: 120 },
          {
            field: "producto_es_reembolsable",
            headerName: "Reembolsable",
            minWidth: 120,
            renderCell: (fila) => {
              return fila.value === "1" ? "SI" : "NO";
            },
          },
          { field: "sede_nombre", headerName: "Sede", minWidth: 150 },
          { field: "modalidad", headerName: "Modalidad", minWidth: 150 },
          {
            field: "grupo_nombre",
            headerName: "Grupo",
            minWidth: 150,
            flex: 1,
          },
          {
            field: "comunidad_autonoma_nombre",
            headerName: "Comunidad",
            minWidth: 200,
          },
          {
            field: "exposicion_tutor_nombre",
            headerName: "Tutor exposición",
            minWidth: 250,
          },
          { field: "fecha_exposicion", headerName: "Fecha", minWidth: 250 },
          {
            field: "calendly_uuid",
            headerName: "Calendly",
            minWidth: 120,
            renderCell: (fila) => {
              return fila.value !== null ? "SI" : "NO";
            },
          },
          // { field: "zoom_link", headerName: "Zoom", minWidth: 350 },
          { field: "zoom_mail", headerName: "Sala", minWidth: 350 },
          
          ];
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        return (
          <>
            <Box mt={0} mb={0} mx={2}>
              <Typography
                variant="h5"
                component="h3"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                Exposiciones orales - Reserva de sala
              </Typography>
            </Box>
            {exposicionesOralesReservaSala ? (
              <>
                <Box>
                  <div
                    style={{ height: 350, width: "100%", padding: "0 15px" }}
                  >
                    <DataGridPro
                      rows={exposicionesOralesReservaSala}
                      columns={columnasReservaSala}
                      rowHeight={30}
                    />
                  </div>
                </Box>
              </>
            ) : (
              <EmptyComponent mensaje="No hay datos para mostrar" />
            )}

            <Box mt={0} mb={0} mx={2} style={{border: "1px solid #e0e0e0", marginBottom: 20, marginTop: 20, padding: 20, borderRadius: 15}}>
              <b>Contraseña sala1:</b> KzE1FJyPd6 <br />
              <b>Contraseña sala2:</b> A8t4OKOY <br />
              <b>Contraseña sala3:</b> 3MVg1CaXiQ
            </Box>
            <Box mt={0} mb={0} mx={2}>
              <Typography
                variant="h5"
                component="h3"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                Exposiciones orales - Compras alumnos
              </Typography>
            </Box>
            {exposicionesOralesReservaSala ? (
              <>
                <Box>
                  <div
                    style={{ height: 500, width: "100%", padding: "0 15px" }}
                  >
                    <DataGridPro
                      rows={exposicionesOralesComprasAlumnos}
                      columns={columnasCompras}
                      rowHeight={30}
                      onRowDoubleClick={(params) => {
                        if(usuarioSeleccionado.perfil_id !== "12" ) {
                          Swal.fire({
                            title: "Error",
                            text: "No puedes modificar las exposiciones orales. Ponte en contacto con administración",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                          });
                          return
                        }
                        if(params.row.calendly_uuid !== null ) {
                          Swal.fire({
                            title: "Error",
                            text: "No se puede modificar una exposición oral que ha sido reservada en Calendly",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                          });
                          return
                        }

                        setFilaSeleccionada(params.row)
                        setOpenDialogEntradaManual(true)
                      }}
                    />
                  </div>
                </Box>
              </>
            ) : (
              <EmptyComponent mensaje="No hay datos para mostrar" />
            )}

            <DialogExposicionOralEntradaManual
              exposicionOral={filaSeleccionada}
              openModal={openDialogEntradaManual}
              handleCerrarDialogNotificacion={handleOpenDialogEntradaManual}
            />
            <DialogExposicionUrlGrabacion
              exposicionOral={filaSeleccionada}
              openModal={openDialogUrlGrabacion}
              handleCerrarDialogNotificacion={handleOpenDialogUrlGrabacion}
            />
          </>
        );
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
