import axios from "axios";
import { BASE_URL_SERVICE } from "../constants";

export class EntregaService {
    
    static item = async ( {usuario_id, origen_tipo, origen_id} ) => {
        try {
          let formData = new FormData();

          formData.append("usuario_id", usuario_id);
          formData.append("origen_tipo", origen_tipo);
          formData.append("origen_id", origen_id);

          const response = await axios({
            method: "post",
            url: BASE_URL_SERVICE + "/alumno/entrega_item",
            data: formData,
            headers: {
              //  'Content-Type': 'multipart/form-data',
              //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA",
            },
          });

          return response.data;
        } catch (error) {
            return undefined;
        }
    }
}