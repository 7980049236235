import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
import { Box, Chip, IconButton, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import { getAlumnosTutor } from '../../../../services/alumnos';
import { GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';
import { grey, red} from '@material-ui/core/colors';
import LeyendaEstadosTutor from '../../../../components/LeyendaEstadosTutor/LeyendaEstadosTutor';
import StatusEntregasAlumno from '../../../../components/StatusEntregasAlumno';
import StatusTicketsAlumno from '../../../../components/StatusTicketsAlumno';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosTutorContent() {
    const classes = useStyles();

    const [Alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        getAlumnosTutor({ usuario_id: usuario_id, tutor_id:usuario_id }).then((response) => {
          setLoading(false)

          response && setAlumnos(response.alumnos)
        });

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'nombre', headerName: 'Nombre', minWidth: 250, flex: 1 },
      { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100 },
      // { field: 'oposicion_nombre', headerName: 'Oposición', width: 90 },
      // { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 220 },
      {
        'field': 'estado_alumno', 
        'width':290, 
        'headerName': 'Entregas', 
        'description': 'Estado entregas alumno',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
            {
            cellValues.row.es_alumno_fast === '0' ?   
            
            <StatusEntregasAlumno 
              num_tickets_resueltos={cellValues.row.num_tickets_entregas_resueltos} 
              num_tickets_abiertos={cellValues.row.num_tickets_entregas_abiertos} 
              num_tickets_pendientes={cellValues.row.num_tickets_entregas_pendientes} 
              num_tickets_retrasados={cellValues.row.num_tickets_entregas_retrasados} 
              num_tickets_ignorados={cellValues.row.num_tickets_entregas_ignorados} 
            />
            
            :

            <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Preparación FAST`} />

            }
            </>
            
          );
        } 
      },
      {
        'field': 'estado_alumno_simulacros', 
        'width':290, 
        'headerName': 'Simulacros', 
        'description': 'Simulacros',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          console.log(cellValues.row.nombre + '-' + cellValues.row.num_tickets_simulacros_ignorados)
          return (
            
            <>
            {
            cellValues.row.es_alumno_fast === '0' ?   

            <StatusEntregasAlumno 
              num_tickets_resueltos={cellValues.row.num_tickets_simulacros_resueltos} 
              num_tickets_abiertos={cellValues.row.num_tickets_simulacros_abiertos} 
              num_tickets_pendientes={cellValues.row.num_tickets_simulacros_pendientes} 
              num_tickets_retrasados={cellValues.row.num_tickets_simulacros_retrasados} 
              num_tickets_ignorados={cellValues.row.num_tickets_simulacros_ignorados} 
            />

            :

            <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Preparación FAST`} />

            }
            </>
            
          );
        } 
      },
      {
        'field': 'dudas', 
        'width':290, 
        'headerName': 'Dudas', 
        'description': 'Dudas',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
              <StatusTicketsAlumno 
                num_tickets_resueltos={cellValues.row.num_tickets_resueltos} 
                num_tickets_abiertos={cellValues.row.num_tickets_abiertos} 
                num_tickets_pendientes={cellValues.row.num_tickets_pendientes} 
                num_tickets_ignorados={cellValues.row.num_tickets_ignorados} 
                num_tickets_retrasados={cellValues.row.num_tickets_retrasados} 
              />
            </>
            
          );
        } 
      },
      {
        'field': 'entregas_extraordinarias', 
        'width':290, 
        'headerName': 'E. Extraordinarias', 
        'description': 'Extraordinarias',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          console.log(cellValues.row.nombre + '-' + cellValues.row.num_tickets_simulacros_pendientes)
          return (
            
            <>
            {
            cellValues.row.es_alumno_fast === '0' ?   

            <StatusEntregasAlumno 
              num_tickets_resueltos={cellValues.row.num_tickets_entregas_extraordinarias_resueltas} 
              num_tickets_abiertos={cellValues.row.num_tickets_entregas_extraordinarias_abiertas} 
              num_tickets_pendientes={cellValues.row.num_tickets_entregas_extraordinarias_pendientes} 
              num_tickets_retrasados={cellValues.row.num_tickets_entregas_extraordinarias_retrasados} 
              num_tickets_ignorados={cellValues.row.num_tickets_entregas_extraordinarias_ignoradas} 
            />

            :

            <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Preparación FAST`} />

            }
            </>
            
          );
        } 
      },
      
      {
        'field': 'num_tutorias', 
        'width':200, 
        'headerName': 'Tutorías', 
        'description': 'Tutorías realizadas y tutorias planificadas pendientes de realizar',
        'align': 'center',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
            {
            cellValues.row.es_alumno_fast === '0' ?   

              <>
                {
                cellValues.row.tutorias_individuales_disponibles === '0' 
                ? 
                <Chip size="small" variant="outlined" style={{color: red[700]}} label={`Dispo: ${cellValues.row.tutorias_individuales_disponibles}`} /> 
                : 
                <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Dispo: ${cellValues.row.tutorias_individuales_disponibles}`} /> 
                }
               <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Comsu: ${cellValues.row.tutorias_individuales_consumidas}`} /> 
              </>
            :

            <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Preparación FAST`} />

            }
            </>
            
          );
        } 
      },
      {
        'field': 'num_simulacros', 
        'width':200, 
        'headerName': 'Simulacros', 
        'description': 'Simulacros',
        'align': 'center',
        // type: 'date',
        // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
            <>
            {
            cellValues.row.es_alumno_fast === '0' ?   

              <>
                {
                cellValues.row.simulacros_disponibles === '0' 
                ? 
                <Chip size="small" variant="outlined" style={{color: red[700]}} label={`Dispo: ${cellValues.row.simulacros_disponibles}`} /> 
                : 
                <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Dispo: ${cellValues.row.simulacros_disponibles}`} /> 
                }
               <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Entr: ${cellValues.row.simulacros_resueltos}`} /> 
              </>
            :

            <Chip size="small" variant="outlined" style={{color: grey[700]}} label={`Preparación FAST`} />

            }
            </>
            
          );
        } 
      },

    ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
    }
      
    QuickSearchToolbar.propTypes = {
      clearSearch: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    };

    const Contenido = () => {
        const [rows, setRows] = React.useState(Alumnos);
        const [searchText, setSearchText] = React.useState('');
        const requestSearch = (searchValue) => {
          // console.log(searchValue)
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
          const filteredRows = Alumnos.filter((row) => {
              return Object.keys(row).some((field) => {
                  // console.log(field)
                  // console.log(row)
                  return searchRegex.test(row[field]);
              });
          });
          setRows(filteredRows);
        };

        const handleAlumnoDetalle = (id) => {
          history.push('/alumnos_tutor/detalle/' + id )
        }

        return (
            rows ?
                <>
                    <TituloPagina titulo={"Alumnos"} />

                    <Box>
                      <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                      
                          <DataGridInnovattio
                              rows={rows} 
                              columns={columnas} 
                              rowHeight={30}
                              components={{ Toolbar: QuickSearchToolbar }}
                              onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                              componentsProps={{
                                  toolbar: {
                                    value: searchText,
                                    onChange: (event) => requestSearch(event.target.value),
                                    clearSearch: () => requestSearch(''),
                                  },
                              }}
                          />
                      </div>
                    </Box>
                    <LeyendaEstadosTutor />
                </>
                
                :
                
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
